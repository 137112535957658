import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import useConfirmationModal from "../../../../../hooks/useConfirmationModal ";
import noimage from "../../../../../static/img/clubpre.png";
import {
  useBorrarClubByIdMutation,
  useModificarClubMutation,
} from "../../../../../redux/services/club";

const ModalClub = ({ club, Origen = "readonly", onClose }) => {
  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "https://localhost:5000";
  const [isEditable, setIsEditable] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [newData, setNewData] = React.useState(club);
  const formRef = React.useRef();

  const [validationErrors, setValidationErrors] = React.useState({
    nombre: false,
    sigla: false,
    descripcion: false,
  });
  const { openConfirm, ModalConfirmacion } = useConfirmationModal();

  const [modificarClub, error] = useModificarClubMutation();

  const handleRequired = () => {
    const errors = {
      nombre: !newData.nombre || newData.nombre?.trim() === "",
      sigla: !newData.sigla || newData.sigla?.trim() === "",
      descripcion:
        newData.descripcion?.length === 0 || !newData.descripcion?.trim(),
    };

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      nombre: !newData.nombre || newData.nombre?.trim() === "",
      sigla: !newData.sigla || newData.sigla?.trim() === "",
      descripcion:
        newData.descripcion?.length === 0 || !newData.descripcion?.trim(),
    }));

    if (Object.values(errors).some((error) => error)) {
      console.log("No se puede guardar debido a errores de validación");
      return true;
    }

    return false;
  };
  const handleEdit = async () => {
    if (handleRequired()) {
      toast.error("Todos los datos son obligatorios!");
      return;
    }
    try {
      const club = {
        id: newData.id,
        nombre: newData.nombre,
        sigla: newData.sigla,
        descripcion: newData.descripcion,
        logo: newData.logo,
      };
      const result = await modificarClub(club);
      if (result.error) throw new Error(error);
      handleCancelar();
      toast.success("Club actualizado con éxito!", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success1",
      });
    } catch (error) {
      toast.error("Error Actualizando el CLub!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.log(error);
    }
  };

  const [borrarClubById] = useBorrarClubByIdMutation();

  const handleDelete = async () => {
    openConfirm(
      "Confirmación de Borrado",
      "¿Eliminar este club?",
      "Eliminar",
      async () => {
        try {
          const result = await borrarClubById(club.id);
          if (result.error) throw new Error(result.error);
          handleCancelar();
          toast.success("Club eliminado con éxito!", {
            position: toast.POSITION.TOP_CENTER,
            toastId: "success1",
          });
        } catch (error) {
          toast.error("Error Borrando Club!", {
            position: toast.POSITION.TOP_CENTER,
          });
          console.log(error);
        }
      }
    );
  };

  const handleCancelar = () => {
    setIsEditable(false);
    setIsDelete(false);
    onClose();
    setNewData(club);
    setValidationErrors({
      nombre: false,
      sigla: false,
      descripcion: false,
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    let errorMessage = "";

    if (name === "nombre") {
      errorMessage = value.trim() === "" ? "Este campo es requerido" : "";
    }

    if (name === "sigla") {
      errorMessage = value.trim() === "" ? "Este campo es requerido" : "";
    }

    if (name === "descripcion") {
      errorMessage = value.trim() === "" ? "Este campo es requerido" : "";
    }
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let errorMessage = "";

    if (name === "nombre") {
      if (value.length === 0 || !value.trim()) {
        errorMessage = "Este campo es requerido";
      }
    }

    if (name === "sigla") {
      if (value.length === 0 || !value.trim()) {
        errorMessage = "Este campo es requerido";
      }
    }

    if (name === "descripcion") {
      if (value.length === 0 || !value.trim()) {
        errorMessage = "Este campo es requerido";
      }
    }

    setNewData({
      ...newData,
      [name]: value,
    });

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage !== "",
    }));
  };

  const [fileName, setFileName] = useState("");
  const [previewImgClubUrl, setPreviewImgClubUrl] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileName(file?.name);
    if (file) {
      setNewData({
        ...newData,
        logo: file,
      });
      const reader = new FileReader();
      reader.onload = (event) => {
        const url = URL.createObjectURL(file);
        setPreviewImgClubUrl(url);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <React.Fragment>
      <Box key={club.id} style={{ width: "100%" }}>
        <Box>
          <form ref={formRef}>
            <Grid container sx={{ justifyContent: "space-between" }}>
              {isEditable ? (
                <Grid item xs={5} md={9}></Grid>
              ) : (
                <Grid item xs={5} md={9}>
                  <Typography className="Titulo">{newData.nombre}</Typography>
                </Grid>
              )}
              {Origen === "auth" && (
                <Box xs={5} md={3}>
                  <IconButton
                    disabled={isDelete}
                    onClick={() => {
                      setIsEditable(!isEditable);
                    }}
                    color="primary"
                    aria-label="add to shopping cart"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    disabled={isEditable}
                    onClick={() => {
                      setIsDelete(!isDelete);
                    }}
                    color="primary"
                    aria-label="add to shopping cart"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Grid>
            <Divider className="DividerMC" />
            {isEditable ? (
              <Box className="ContenidoN">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Box
                    sx={{
                      maxHeight: { xs: 250, md: "auto" },
                      width: { xs: "100%", md: "20%" },
                      height: "auto",
                    }}
                  >
                    <Grid item xs={10} className="GridItemCN">
                      <Box className="BoxImagenCN">
                        <Typography className="Texto">Foto</Typography>
                        <input
                          label="Foto"
                          name="foto"
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          id="imagenInput"
                        />
                        {(club?.logo || previewImgClubUrl) && (
                          <img
                            src={
                              previewImgClubUrl
                                ? previewImgClubUrl
                                : `${API_BASE_URL}/images/${club?.logo}`
                            }
                            alt={fileName}
                            style={{
                              maxWidth: "90%",
                              maxHeight: "50%",
                              marginRight: "auto",
                              marginLeft: "auto",
                              marginTop: "-15px",
                            }}
                          />
                        )}
                        <Divider className="DividerCN" />
                        <Box
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <label htmlFor="imagenInput">
                            <Button
                              variant="outlined"
                              component="span"
                              color="primary"
                              className="BotonBody BotonCN"
                              sx={{}}
                            >
                              Seleccionar
                            </Button>
                          </label>
                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                  <Box sx={{ width: "80%" }}>
                    <Grid container className="GridContenedorN">
                      <Grid item style={{ margin: "7px" }}>
                        <TextField
                          sx={{ mx: 1 }}
                          label="ID"
                          name="id"
                          value={newData.id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item style={{ margin: "7px" }}>
                        <TextField
                          sx={{ mx: 1 }}
                          label="Nombre"
                          name="nombre"
                          value={newData.nombre}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          fullWidth
                          error={validationErrors.nombre}
                          helperText={
                            validationErrors.nombre && "Este campo es requerido"
                          }
                        />
                      </Grid>
                      <Grid item style={{ margin: "7px" }}>
                        <TextField
                          sx={{ mx: 1 }}
                          label="Sigla"
                          name="sigla"
                          value={newData.sigla}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          fullWidth
                          error={validationErrors.sigla}
                          helperText={
                            validationErrors.sigla && "Este campo es requerido"
                          }
                        />
                      </Grid>
                      <Box sx={{ flexGrow: 1, height: 5 }} />
                      <Grid item style={{ margin: "7px" }}>
                        <TextField
                          sx={{ mx: 1 }}
                          label="Descripción"
                          name="descripcion"
                          value={newData.descripcion}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          fullWidth
                          error={validationErrors.descripcion}
                          helperText={
                            validationErrors.descripcion &&
                            "Este campo es requerido"
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box className="ContenidoN">
                <Grid
                  container
                  direction={{ xs: "column", sm: "row" }}
                  spacing={2}
                >
                  <Grid item xs={12} sm={4} md={3} lg={2}>
                    <Box
                      component="img"
                      sx={{
                        maxHeight: 250,
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                        display: "block",
                        mx: "auto",
                      }}
                      src={
                        club.logo === ""
                          ? noimage
                          : `${API_BASE_URL}/images/${club.logo}`
                      }
                      alt={"logo"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={9} lg={10}>
                    <Grid container className="GridContenedorN">
                      {isDelete && (
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              pb: 5,
                            }}
                          >
                            ¿Desea Eliminar este Club?
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <TextField
                          type="text"
                          label="ID"
                          aria-label="id"
                          variant="filled"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          readOnly
                          disabled
                          sx={{
                            whiteSpace: "pre",
                            borderColor: "transparent",
                            width: "100%",
                          }}
                          value={club.id}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          type="text"
                          label="Nombre"
                          aria-label="nombre"
                          variant="filled"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          readOnly
                          sx={{
                            whiteSpace: "pre",
                            borderColor: "transparent",
                            width: "100%",
                          }}
                          value={club.nombre}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          type="text"
                          label="Sigla"
                          aria-label="sigle"
                          variant="filled"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          readOnly
                          sx={{
                            whiteSpace: "pre",
                            borderColor: "transparent",
                            width: "100%",
                          }}
                          value={club.sigla}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          type="text"
                          label="Descripción"
                          aria-label="descripcion"
                          variant="filled"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          readOnly
                          sx={{
                            whiteSpace: "pre",
                            borderColor: "transparent",
                            width: "100%",
                          }}
                          value={club.descripcion}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            <Divider className="DividerMC" />
            <Grid className="gridBotonesMC">
              {!isEditable ? (
                isDelete ? (
                  <>
                    <Grid container sx={{ justifyContent: "end" }}>
                      <Button onClick={handleDelete} className="BotonBody">
                        Eliminar
                      </Button>
                      <Button onClick={handleCancelar} className="BotonBody">
                        Cancelar
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <></>
                )
              ) : (
                <Grid container sx={{ justifyContent: "end" }}>
                  <Button onClick={handleEdit} className="BotonBody">
                    Guardar
                  </Button>
                  <Button onClick={handleCancelar} className="BotonBody">
                    Cancelar
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </Box>

        <ModalConfirmacion />
      </Box>
    </React.Fragment>
  );
};

export default ModalClub;
