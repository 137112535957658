import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import BarraNavegacion from "../../../../../components/BarraNavegacion";
import PieDePagina from "../../../../../components/PieDePagina";
import {
  useAgregarClubMutation,
  useGetClubByIdQuery,
} from "../../../../../redux/services/club";

const CrearClub = ({ sinmenu }) => {
  const [validationErrors, setValidationErrors] = React.useState({
    id: false,
    nombre: false,
    sigla: false,
    descripcion: false,
  });

  const initialState = {
    id: 0,
    nombre: "",
    sigla: "",
    descripcion: "",
    logo: "",
  };

  const [club, setClub] = React.useState(initialState);
  const [legajo, setLegajo] = React.useState();

  const handleBlur = (e) => {
    const { name, value } = e.target;
    let errorMessage = "";

    if (name === "id") {
      setLegajo(value);
      if (value == 0 || isNaN(value)) {
        errorMessage = "Este campo es requerido";
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
      } else {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: value.trim() === "",
        }));
      }
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === "",
      }));
    }

    if (name === "nombre") {
      errorMessage = value.trim() === "" ? "Este campo es requerido" : "";
    }

    if (name === "sigla") {
      errorMessage = value.trim() === "" ? "Este campo es requerido" : "";
    }

    if (name === "descripcion") {
      errorMessage = value.trim() === "" ? "Este campo es requerido" : "";
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let errorMessage = "";

    if (name === "id") {
      if (value == 0 || isNaN(value)) {
        errorMessage = "Este campo es requerido";
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
      }
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === "",
      }));
    }

    if (name === "nombre") {
      if (value.length === 0 || !value.trim()) {
        errorMessage = "Este campo es requerido";
      }
    }

    if (name === "sigla") {
      if (value.length === 0 || !value.trim()) {
        errorMessage = "Este campo es requerido";
      }
    }

    if (name === "descripcion") {
      if (value.length === 0 || !value.trim()) {
        errorMessage = "Este campo es requerido";
      }
    }

    setClub({
      ...club,
      [name]: value,
    });

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage !== "",
    }));
  };

  const { data: dataLicencia } = useGetClubByIdQuery(legajo);

  React.useEffect(() => {
    const fetchLicencia = async () => {
      try {
        let errorMessage = "";
        if (legajo) {
          const result = await dataLicencia;
          if (result.clubIndividual) {
            toast.error("Ya existe un club con ese ID!");
            errorMessage = `Ya existe un club con ese ID!, ${result.clubIndividual.nombre}`;
            setValidationErrors((prevErrors) => ({
              ...prevErrors,
              id: errorMessage,
            }));
          }
        }
      } catch (error) {
        console.error("Error obteniendo datos de la licencia:", error);
        toast.error("El competidor no se encuentra cargado!");
        return;
      }
    };

    if (legajo) {
      fetchLicencia(legajo);
    }
  }, [dataLicencia]);

  const handleRequired = () => {
    const errors = {
      id: club.id === 0 || isNaN(club.id),
      nombre: club.nombre.trim() === "",
      sigla: club.sigla.trim() === "",
      descripcion: club.descripcion.trim() === "",
    };

    setValidationErrors((prevState) => {
      return {
        ...prevState,
        id: club.id === 0 || isNaN(club.id),
        nombre: club.nombre.trim() === "",
        sigla: club.sigla.trim() === "",
        descripcion: club.descripcion.trim() === "",
      };
    });

    if (Object.values(errors).some((error) => error)) {
      console.log("No se puede guardar debido a errores de validación");
      return true;
    }

    return false;
  };

  const [agregarClub, error] = useAgregarClubMutation();

  const handlePublicar = async (e) => {
    e.preventDefault();

    if (handleRequired()) {
      toast.error("Todos los datos son obligatorios!");
      return;
    }

    try {
      const Club = {
        id: club.id,
        nombre: club.nombre,
        sigla: club.sigla,
        descripcion: club.descripcion,
        logo: club.logo,
      };

      const modId = await agregarClub(Club);

      if (modId.error) throw new Error(error);
      setClub(initialState);
      toast.success("Club creado!", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success3",
      });
    } catch (error) {
      toast.error("Error creando el Club!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("Error creating post:", error);
    }
  };

  const [fileName, setFileName] = useState("");
  const [previewImgClubUrl, setPreviewImgClubUrl] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileName(file?.name);
    if (file) {
      setClub({
        ...club,
        logo: file,
      });
      const reader = new FileReader();
      reader.onload = (event) => {
        const url = URL.createObjectURL(file);
        setPreviewImgClubUrl(url);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <React.Fragment>
      <Box style={{ marginTop: "5%" }}>
        {!sinmenu && <BarraNavegacion />}

        <Box className="ContenidoPpalCN">
          <Grid container className="GridPpalCN">
            <Grid item className="GridItemCN">
              <Typography className="Titulo TituloPpalCN">
                Crear Club
              </Typography>
            </Grid>
            <Divider className="DividerCN" />

            <Grid container justifyContent={"center"}>
              <Grid item md={4} xs={11} className="GridItemCN">
                <Box className="BoxImagenCN">
                  <Typography className="Texto">Foto</Typography>
                  <input
                    label="Foto"
                    name="foto"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    id="imagenInput"
                  />
                  {club.logo && (
                    <img
                      src={previewImgClubUrl}
                      alt={fileName}
                      style={{ maxWidth: "100%", marginTop: "16px" }}
                    />
                  )}

                  <Divider className="DividerCN" />

                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <label htmlFor="imagenInput">
                      <Button
                        variant="outlined"
                        component="span"
                        color="primary"
                        className="BotonBody BotonCN"
                        sx={{}}
                      >
                        Seleccionar
                      </Button>
                    </label>
                  </Box>
                </Box>
              </Grid>
              <Grid container md={7} xs={11} className="GridItemCN">
                <Grid item className="GridItemCN">
                  <TextField
                    sx={{ mx: 1 }}
                    type="number"
                    label="ID"
                    name="id"
                    value={club.id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    fullWidth
                    error={validationErrors.id}
                    helperText={validationErrors.id}
                  />
                </Grid>
                <Grid item className="GridItemCN">
                  <TextField
                    sx={{ mx: 1 }}
                    label="Nombre"
                    name="nombre"
                    value={club.nombre}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    fullWidth
                    error={validationErrors.nombre}
                    helperText={
                      validationErrors.nombre && "Este campo es requerido"
                    }
                  />
                </Grid>
                <Grid item className="GridItemCN">
                  <TextField
                    sx={{ mx: 1 }}
                    label="Sigla"
                    name="sigla"
                    value={club.sigla}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    fullWidth
                    error={validationErrors.sigla}
                    helperText={
                      validationErrors.sigla && "Este campo es requerido"
                    }
                  />
                </Grid>
                <Grid item className="GridItemCN">
                  <TextField
                    sx={{ mx: 1 }}
                    label="Descripción"
                    name="descripcion"
                    value={club.descripcion}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    fullWidth
                    error={validationErrors.descripcion}
                    helperText={
                      validationErrors.descripcion && "Este campo es requerido"
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid item className="GridBotonesCN">
              <Button className="BotonBody BotonCN" onClick={handlePublicar}>
                Publicar
              </Button>
            </Grid>
          </Grid>
        </Box>
        {!sinmenu && <PieDePagina />}
      </Box>
    </React.Fragment>
  );
};

export default CrearClub;
