import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { categoriaApi } from './services/categoria';
import { modalidadApi } from './services/modalidad';
import { novedadApi } from './services/novedad';
import { competenciaApi } from './services/competencia';
import { tipoCompetenciaApi } from './services/tipoCompetencia';
import { mensajeContactoApi } from './services/contacto';
import { rankingApi } from './services/ranking';
import { competidorApi } from './services/competidor';
import { recordApi } from './services/record';
import { historialApi } from './services/historial';
import { clubApi } from './services/club';
import { sponsorsApi } from './services/sponsors';
import { reglamentoApi } from './services/reglamento';
import { tipoRecordApi } from './services/tipoRecord';
import { ajusteApi } from './services/ajustes';
import { htmlApi } from './services/resultados';
import { authApi } from './services/auth';
import { modalidadHomeApi } from './services/modalidadHome';
import { localStorageMiddleware, rehydrateState } from './middleware/localStorageMiddleware';
import ranking from './features/rankingSlice';
import auth from './features/authSlice';
import modalidadReducer from './features/modalidadSlice';
import deleteResultSlice from './features/deleteResultSlice';

const preloadedState = rehydrateState();

export const store = configureStore({
    reducer: {
        ranking: ranking,
        modalidad: modalidadReducer,
        result: deleteResultSlice,
        [categoriaApi.reducerPath]: categoriaApi.reducer,
        [modalidadApi.reducerPath]: modalidadApi.reducer,
        [modalidadHomeApi.reducerPath]: modalidadHomeApi.reducer,
        [novedadApi.reducerPath]: novedadApi.reducer,
        [competenciaApi.reducerPath]: competenciaApi.reducer,
        [tipoCompetenciaApi.reducerPath]: tipoCompetenciaApi.reducer,
        [mensajeContactoApi.reducerPath]: mensajeContactoApi.reducer,
        [rankingApi.reducerPath]: rankingApi.reducer,
        [htmlApi.reducerPath]: htmlApi.reducer,
        [competidorApi.reducerPath]: competidorApi.reducer,
        [historialApi.reducerPath]: historialApi.reducer,
        [clubApi.reducerPath]: clubApi.reducer,
        [sponsorsApi.reducerPath]: sponsorsApi.reducer,
        [reglamentoApi.reducerPath]: reglamentoApi.reducer,
        [tipoRecordApi.reducerPath]: tipoRecordApi.reducer,
        [ajusteApi.reducerPath]: ajusteApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [recordApi.reducerPath]: recordApi.reducer,
        auth: auth,
    },
    preloadedState,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(categoriaApi.middleware)
            .concat(modalidadApi.middleware)
            .concat(modalidadHomeApi.middleware)
            .concat(novedadApi.middleware)
            .concat(competenciaApi.middleware)
            .concat(tipoCompetenciaApi.middleware)
            .concat(mensajeContactoApi.middleware)
            .concat(rankingApi.middleware)
            .concat(competidorApi.middleware)
            .concat(recordApi.middleware)
            .concat(historialApi.middleware)
            .concat(clubApi.middleware)
            .concat(sponsorsApi.middleware)
            .concat(reglamentoApi.middleware)
            .concat(tipoRecordApi.middleware)
            .concat(ajusteApi.middleware)
            .concat(authApi.middleware)
            .concat(htmlApi.middleware)
            .concat(localStorageMiddleware)
})

setupListeners(store.dispatch)