import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useGetTablaChicaHtmlByIdQuery } from "../../../../../redux/services/resultados";

export default function ListarManga({ actualizar }) {
  const [htmlData, setHtmlData] = React.useState();
  const { state } = useLocation();
  const { data, isLoading } = useGetTablaChicaHtmlByIdQuery(
    state ? state?.id : null
  );

  React.useEffect(() => {
    const fetchDataHtml = async () => {
      try {
        const result = await data;

        if (result && result.datosTablaChica) {
          setHtmlData(result.datosTablaChica);
        }
      } catch (error) {
        console.error("Error al obtener datosHTML:", error);
      }
    };

    fetchDataHtml();
  }, [data]);

  return (
    <Box style={{ width: "100%", marginTop: "10px" }}>
      {!isLoading ? (
        htmlData ? (
          <TableContainer
            component={Paper}
            style={{ width: "100%", overflowX: "auto" }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="Titulo">Task</TableCell>
                  <TableCell align="center" className="Titulo">
                    Date
                  </TableCell>
                  <TableCell align="center" className="Titulo">
                    Distance [km]
                  </TableCell>
                  <TableCell align="center" className="Titulo">
                    FTV Validity
                  </TableCell>
                  <TableCell align="center" className="Titulo">
                    Type
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {htmlData?.map((posicion, indice) => (
                  <TableRow
                    key={indice}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{posicion.task}</TableCell>
                    <TableCell align="center">
                      {new Date(posicion.date).toLocaleString()}
                    </TableCell>
                    <TableCell align="center">{posicion.distance}</TableCell>
                    <TableCell align="center">{posicion.ftvValidity}</TableCell>
                    <TableCell align="center">{posicion.type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography className="Titulo" sx={{ textAlign: "center", my: 2 }}>
            No hay Resultados para Mostrar
          </Typography>
        )
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}
