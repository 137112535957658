/* eslint-disable eqeqeq */
import { Box, Button, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography, CircularProgress } from '@mui/material';
import React from 'react';
import { toast } from 'react-toastify';
import BarraNavegacion from '../../../../../components/BarraNavegacion';
import PieDePagina from '../../../../../components/PieDePagina';
import { useAgregarCompetidorSeleccionMutation, useGetCompetidorByIdQuery, useModificarCompetidorMutation } from '../../../../../redux/services/competidor';
import { useGetListaModalidadesSelectQuery } from '../../../../../redux/services/modalidad';

const CrearSeleccion = ({ sinmenu }) => {
    const [validationErrors, setValidationErrors] = React.useState({
        idPilotoFK: false,
        descripcion: false,
    });

    const initialState = {
        idPilotoFK: 0,
        nombre: '',
        ciudad: '',
        provincia: '',
        idModalidadFk: 0,
        activo: true,
    };

    const [seleccion, setSeleccion] = React.useState(initialState);
    const [pilotoEdit, setPilotoEdit] = React.useState(null);
    const [legajo, setLegajo] = React.useState();
    const [hasError, setHasError] = React.useState({
        modalidad: false,
    });

    const [dataModalidades, setDataModalidades] = React.useState();
    const { data: resModalidades, isLoading: isLoadingModalidad } = useGetListaModalidadesSelectQuery();

    React.useEffect(() => {

        try {
            setDataModalidades(resModalidades?.listarModalidades)
        } catch (error) {
            console.log('Error al obtener los modalidades:', error)
        }

        return () => {
            setDataModalidades();
        }
    }, [resModalidades]);


    const handleChangeDescription = (e) => {
        const { name, value } = e.target;

        let errorMessage = '';

        if (!value.trim()) {
            errorMessage = 'Este campo es requerido';
        }

        setPilotoEdit({
            ...pilotoEdit,
            [name]: value,
        });

        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errorMessage !== '',
        }));

    };
    const handleChange = (e) => {
        const { name, value } = e.target;

        let errorMessage = '';

        if (name === 'idModalidadFK') {
            if (!value.trim()) {
                errorMessage = 'Este campo es requerido';
            }
        }

        setSeleccion({
            ...seleccion,
            [name]: value,
        });

        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errorMessage !== '',
        }));

    };


    //Licencia

    const handleChangeLicencia = (e) => {
        e.preventDefault();
        const { value } = e.target;
        setLegajo(value)
    }

    const handleBlurLicencia = (e) => {
        e.preventDefault();
        const { value } = e.target;

        let errorMessage = '';

        if (value.trim() === '' || isNaN(value) || parseFloat(value) <= 0) {
            errorMessage = 'Este campo es obligatorio';
            setValidationErrors(prevErrors => {
                let newOBjet = {
                    ...prevErrors,

                }
                newOBjet.idPilotoFK = true
                return { ...newOBjet }
            });
        } else {
            setValidationErrors(prevErrors => {
                let newOBjet = {
                    ...prevErrors,
                }
                newOBjet.idPilotoFK = false
                return { ...newOBjet }
            });
        }

        if (errorMessage === '') {
            setLegajo(value);
        }

        if (!dataLicencia) {
            toast.error("No existe un competidor con esa Licencia!");
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                // eslint-disable-next-line eqeqeq
                idPiloto: true,
            }));
        }

    };

    const { data: dataLicencia, } = useGetCompetidorByIdQuery(legajo, {
        skip: legajo ? false : true,
    });

    React.useEffect(() => {
        const fetchLicencia = async () => {
            try {
                if (legajo) {
                    const result = await dataLicencia;
                    if (result) {
                        setPilotoEdit(result.competidorIndividual);
                        const { idCompetidor, nombre, ciudad, provincia } = result.competidorIndividual;
                        setSeleccion(prevSeleccion => {
                            return {
                                ...prevSeleccion,
                                idPilotoFK: idCompetidor,
                                nombre,
                                ciudad,
                                provincia
                            };
                        });
                    }

                }
            } catch (error) {
                console.error("Error obteniendo datos de la licencia:", error);
                toast.error("El competidor no se encuentra cargado!");
                return;
            }
        };


        if (legajo) {
            fetchLicencia();
        }

    }, [dataLicencia]);

    const actualizarPosicion = (e) => {
        const { value } = e.target;
        setSeleccion(prevRecord => {
            return {
                ...prevRecord,
                idPilotoFK: value
            };
        });
    }

    //Aca va el endpoint de crear Selección
    const [agregarCompetidorSeleccion, error] = useAgregarCompetidorSeleccionMutation();
    const [modificarCompetidor, { error: errorCompetidor }] = useModificarCompetidorMutation();


    const handlePublicar = async (e) => {
        e.preventDefault();

        if (seleccion.idPilotoFK === 0) {
            setValidationErrors({ ...validationErrors, idPilotoFK: true });
            toast.error('Falta Competidor!', {
                autoClose: 2000,
            });
            return;
        } else {
            setValidationErrors({ ...validationErrors, idPilotoFK: false });
        }

        if (seleccion.idModalidadFk === 0) {
            setHasError({ ...hasError, modalidad: true });
            toast.error('Falta modalidad!', {
                autoClose: 2000,
            });
            return;
        } else {
            setHasError({ ...hasError, modalidad: false });
        }


        try {
            const SeleccionNacional = {
                idCompetidor: seleccion.idPilotoFK,
                idModalidad: seleccion.idModalidadFk,
            }
            const result = await modificarCompetidor(pilotoEdit);
            if (result.errorCompetidor) throw new Error(result.errorCompetidor)
            setPilotoEdit(null);

            const modId = await agregarCompetidorSeleccion(SeleccionNacional);
            if (modId.error) throw new Error(error)
            setSeleccion(initialState);
            setLegajo(0)
            toast.success('Competidor agregado a la selección!', {
                position: toast.POSITION.TOP_CENTER,
                toastId: 'success3',
            });
            window.location.reload();
        } catch (error) {
            toast.error('Error al competidor agregado a la selección!', {
                position: toast.POSITION.TOP_CENTER
            });
            console.error("Error creating post:", error);
        } finally {

        }
    }


    return (
        <React.Fragment>
            <Box sx={{ mt: '5%' }}>
                {!sinmenu && <BarraNavegacion />}

                <Box className="ContenidoPpalCN">
                    <Grid container className="GridPpalCN" spacing={2}>
                        <Grid item xs={12}>
                            <Typography className="Titulo TituloPpalCN">
                                Agregar competidor a la Selección Nacional
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider className="DividerCN" />
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    sx={{ mx: 1 }}
                                    style={{margin: '3px'}}
                                    label="Licencia"
                                    name="licencia"
                                    value={legajo}
                                    onBlur={(e) => handleBlurLicencia(e)}
                                    onChange={(e) => handleChangeLicencia(e)}
                                    required
                                    fullWidth
                                    error={validationErrors.idPilotoFK}
                                    helperText={validationErrors.idPilotoFK && 'Este campo es requerido'}
                                />
                                <TextField
                                    sx={{ mx: 1 }}
                                    style={{margin: '3px'}}
                                    label="Nombre y Apellido"
                                    name="nombre"
                                    value={seleccion.nombre || ''}
                                    onChange={(e) => actualizarPosicion(e)}
                                    required
                                    fullWidth
                                    disabled
                                />
                                <TextField
                                    sx={{ mx: 1 }}
                                    style={{margin: '3px'}}
                                    label="Ciudad"
                                    name="ciudad"
                                    value={seleccion.ciudad || ''}
                                    onChange={(e) => actualizarPosicion(e)}
                                    disabled
                                    required
                                    fullWidth
                                />
                                <TextField
                                    sx={{ mx: 1 }}
                                    style={{margin: '3px'}}
                                    label="Provincia"
                                    name="provincia"
                                    value={seleccion.provincia || ''}
                                    onChange={(e) => actualizarPosicion(e)}
                                    disabled
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <TextField
                                    label="Descripcion"
                                    name="descripcion"
                                    value={pilotoEdit?.descripcion}
                                    onChange={handleChangeDescription}
                                    required
                                    fullWidth
                                    multiline
                                    rows={9}
                                    error={validationErrors.descripcion}
                                    helperText={validationErrors.descripcion && 'Este campo es requerido'}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider className="DividerCN" />
                        </Grid>
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item xs={12} md={6}>
                                {resModalidades ? (
                                    <FormControl fullWidth sx={{ textAlign: 'center' }} error={hasError.modalidad}>
                                        <InputLabel id="demo-simple-select-label">Modalidad</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="idModalidadFk"
                                            value={seleccion.idModalidadFk}
                                            label="Modalidad"
                                            onChange={handleChange}
                                            required
                                        >
                                            {dataModalidades?.map((modalidad) => (
                                                <MenuItem key={modalidad.id} value={modalidad.id}>{modalidad.nombre}</MenuItem>
                                            ))}
                                        </Select>
                                        {hasError.modalidad && <FormHelperText sx={{ color: '#D32F2F', textAlign: 'center' }}>Campo requerido!</FormHelperText>}
                                    </FormControl>
                                ) : (
                                    isLoadingModalidad ? (
                                        <Grid container justifyContent="center" alignItems="center">
                                            <Grid item xs={12} md={12} sx={{ width: '50%', height: '50%', textAlign: 'center' }}>
                                                <CircularProgress sx={{ width: '30px', height: '30px' }} />
                                                <Typography sx={{ color: "rgb(0, 150, 215)", fontSize: "15px", mt: 2 }}>Cargando Modalidad</Typography>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Typography className="Titulo">{' No hay Modalidad para mostrar.'}</Typography>
                                    )
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider className="DividerCN" />
                        </Grid>
                        <Grid item xs={12} className="GridBotonesCN" sx={{ textAlign: 'center', mt: 2 }}>
                            <Button className="BotonBody BotonCN" onClick={handlePublicar}>Publicar</Button>
                        </Grid>
                    </Grid>
                </Box>
                {!sinmenu && <PieDePagina />}
            </Box>
        </React.Fragment>
    );
}

export default CrearSeleccion