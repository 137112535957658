import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BarraNavegacion from "../../../../../components/BarraNavegacion";
import PieDePagina from "../../../../../components/PieDePagina";
import { useGetCompetidorByIdQuery } from "../../../../../redux/services/competidor";
import { useCargaManualHtmlMutation } from "../../../../../redux/services/resultados";

const CargaManual = ({ sinmenu }) => {
  let navigate = useNavigate();

  const initialState = {
    posicion: "",
    idCompetidor: 0,
    nombre: "",
    genero: "M",
    nacionalidad: "ARG",
    glider: "",
    sponsor: "",
    t: Array.from({ length: 12 }, () => null),
    total: 0,
    idCompetenciaFk: 0,
  };

  const { state } = useLocation();
  const [resultado, setResultado] = React.useState(initialState);
  const [legajo, setLegajo] = React.useState();

  const [validationErrors, setValidationErrors] = React.useState({
    posicion: false,
    idCompetidor: false,
    total: false,
  });

  const [inputEnabled, setInputEnabled] = useState({});

  useEffect(() => {
    const initialInputEnabled = {};
    initialInputEnabled[0] = true;
    setInputEnabled(initialInputEnabled);
  }, []);

  const handlePositionChange = (e, index) => {
    const newValue = parseFloat(e.target.value);
    const newInputEnabled = { ...inputEnabled };
    newInputEnabled[index] = true;

    if (!isNaN(newValue) && newValue !== "" && index < resultado.t.length - 1) {
      newInputEnabled[index + 1] = true;
    }

    setInputEnabled(newInputEnabled);

    setResultado((prevState) => {
      const updatedT = [...prevState.t];
      updatedT[index] = isNaN(newValue) ? null : newValue;
      return { ...prevState, t: updatedT };
    });
    setValidationErrors({ ...validationErrors, total: isNaN(resultado.total) });
  };

  const handleBlurPosition = (e, index) => {
    const newInputEnabled = { ...inputEnabled };

    newInputEnabled[index] = true;

    setInputEnabled(newInputEnabled);
    setValidationErrors({ ...validationErrors, total: isNaN(resultado.total) });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === "idCompetidor") {
      setLegajo(value);

      // eslint-disable-next-line eqeqeq
      if (value == 0 || isNaN(value)) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          // eslint-disable-next-line eqeqeq
          [name]: true,
        }));
      } else {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          // eslint-disable-next-line eqeqeq
          [name]: false,
        }));
      }
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === "",
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "idCompetidor") {
      setLegajo(value);

      // eslint-disable-next-line eqeqeq
      if (value == 0 || isNaN(value)) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          // eslint-disable-next-line eqeqeq
          [name]: true,
        }));
      } else {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          // eslint-disable-next-line eqeqeq
          [name]: false,
        }));
      }
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === "",
      }));
    }

    setResultado({
      ...resultado,
      [name]: value,
    });
  };

  const { data: dataLicencia } = useGetCompetidorByIdQuery(legajo);

  React.useEffect(() => {
    const fetchLicencia = async () => {
      try {
        if (legajo) {
          const result = await dataLicencia;

          if (result) {
            if (result.competidorIndividual === null) {
              toast.error("¡El competidor no se encuentra cargado!");
              setValidationErrors((prevErrors) => {
                return { ...prevErrors, idCompetidor: true };
              });
            }

            const { idCompetidor, nombre, genero, nacionalidad } =
              result.competidorIndividual;
            setResultado((prevResultado) => {
              return {
                ...prevResultado,
                idCompetidor: idCompetidor,
                nombre: nombre,
                genero: genero ? genero : "M",
                nacionalidad: nacionalidad ? nacionalidad : "ARG",
              };
            });
          }
        }
      } catch (error) {
        console.error("Error obteniendo datos de la licencia:", error);
        toast.error("El competidor no se encuentra cargado!");
        return;
      }
    };

    if (legajo) {
      fetchLicencia(legajo.index);
    }
  }, [dataLicencia]);

  const handleRequired = () => {
    const errors = {
      posicion: resultado.posicion.trim() === "",
      idCompetidor:
        resultado.idCompetidor === 0 || isNaN(resultado.idCompetidor),
      total: isNaN(resultado.total),
    };

    setValidationErrors((prevState) => {
      return {
        ...prevState,
        posicion: resultado.posicion.trim() === "",
        idCompetidor:
          resultado.idCompetidor === 0 || isNaN(resultado.idCompetidor),
        total: isNaN(resultado.total),
      };
    });
    console.log(validationErrors);
    if (Object.values(errors).some((error) => error)) {
      console.log("No se puede guardar debido a errores de validación");
      return true;
    }

    return false;
  };

  const [cargaManualHtml, error] = useCargaManualHtmlMutation();

  const [res, setRes] = useState();

  useEffect(() => {
    const arrayT = Array.from({ length: 12 }, () => null);
    return () => {
      setResultado((prevResultado) => {
        return {
          ...prevResultado,
          posicion: "",
          idCompetidor: 0,
          nombre: "",
          genero: "M",
          nacionalidad: "ARG",
          glider: "",
          sponsor: "",
          t: arrayT,
          total: 0,
          idCompetenciaFk: 0,
        };
      });
    };
  }, [res]);

  const handlePublicar = async (e) => {
    e.preventDefault();

    if (handleRequired()) {
      toast.error("Todos los datos son obligatorios!");
      return;
    }

    try {
      const total = resultado.t.reduce(
        (accumulator, currentPosition) => accumulator + currentPosition,
        0
      );

      const Result = {
        ...resultado,
        genero: resultado.genero,
        idCompetenciaFk: state.id,
        idCompetidor: resultado.idCompetidor.toString(),
        nacionalidad: resultado.nacionalidad,
        nombre: resultado.nombre,
        posicion: resultado.posicion,
        t: resultado.t.filter((item) => item !== null),
        total: total,
      };
      const modId = await cargaManualHtml(Result);
      if (modId.error) throw new Error(error);
      setRes(modId);

      window.location.reload();
      toast.success("Resultado creado!", {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "success3",
      });
    } catch (error) {
      toast.error("Error creando  Resultado!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("Error creating result:", error);
    }
  };

  const handleFinalizar = () => {
    navigate(-1);
  };

  return (
    <React.Fragment>
      <Box style={{ marginTop: "5%" }}>
        {!sinmenu && <BarraNavegacion />}

        <Box className="ContenidoPpalCN">
          <Grid container className="GridPpalCN">
            <Grid item className="GridItemCN">
              <Typography className="Titulo TituloPpalCN">
                Crear Resultado por competidor
              </Typography>
            </Grid>
            <Divider className="DividerCN" />
            <Grid container sx={{ justifyContent: "space-between" }}>
              <Grid itemxs={12} md={2} className="GridItemCN">
                <TextField
                  sx={{}}
                  label="Posición"
                  name="posicion"
                  value={resultado.posicion}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  fullWidth
                  error={validationErrors.posicion}
                  helperText={
                    validationErrors.posicion && "Este campo es requerido"
                  }
                />
              </Grid>
              <Grid item xs={12} md={2} className="GridItemCN">
                <TextField
                  sx={{}}
                  label="Licencia"
                  name="idCompetidor"
                  value={resultado.idCompetidor}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  fullWidth
                  error={validationErrors.idCompetidor}
                  helperText={
                    validationErrors.idCompetidor && "Este campo es requerido"
                  }
                />
              </Grid>
              <Grid itemxs={12} md={3} className="GridItemCN">
                <TextField
                  sx={{}}
                  label="Nombre y Apellido"
                  name="nombre"
                  value={resultado.nombre || ""}
                  required
                  fullWidth
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={2} className="GridItemCN">
                <TextField
                  sx={{}}
                  label="Género"
                  name="genero"
                  value={resultado.genero || ""}
                  disabled
                  required
                  fullWidth
                  error={validationErrors.genero}
                  helperText={
                    validationErrors.genero && "Este campo es requerido"
                  }
                />
              </Grid>
              <Grid item xs={12} md={2} className="GridItemCN">
                <TextField
                  sx={{}}
                  label="Nacionalidad"
                  name="nacionalidad"
                  value={resultado.nacionalidad || ""}
                  disabled
                  required
                  fullWidth
                  error={validationErrors.nacionalidad}
                  helperText={
                    validationErrors.nacionalidad && "Este campo es requerido"
                  }
                />
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid
              container
              className="GridItemCN"
              spacing={2}
              sx={{ marginTop: "10px" }}
            >
              {resultado.t?.map((manga, indice) => (
                <Grid item xs={5} md={3} key={indice} sx={{}}>
                  <TextField
                    label={`T${indice + 1}`}
                    name={`manga-${indice}`}
                    type="number"
                    value={isNaN(manga) ? "" : manga}
                    onChange={(e) => handlePositionChange(e, indice)}
                    onBlur={(e) => handleBlurPosition(e, indice)}
                    required
                    disabled={!inputEnabled[indice]}
                    error={indice === 0 && validationErrors.total}
                    color="primary"
                    focused
                  />
                </Grid>
              ))}
            </Grid>

            <Divider className="DividerCN" />
            <Grid item className="GridBotonesCN">
              <Button className="BotonBody BotonCN" onClick={handlePublicar}>
                Publicar
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Grid item className="GridBotonesCN">
          <Button
            className="BotonBody BotonCN"
            style={{ width: "auto" }}
            onClick={handleFinalizar}
          >
            Finalizar Publicación
          </Button>
        </Grid>
        {!sinmenu && <PieDePagina />}
      </Box>
    </React.Fragment>
  );
};

export default CargaManual;
