import { createSlice } from '@reduxjs/toolkit';


const deleteResultSlice = createSlice({
  name: 'result',
  initialState: {
    result: null,
  },
  reducers: {
    setDeleteResult: (state, action) => {
          state.result = action.payload;
          localStorage.setItem('deleteResult', true);
    },
    clearDeleteResult: (state, action) => {
        state.result = action.payload;
        localStorage.setItem('deleteResult', null);
    },
  },
});

export default deleteResultSlice.reducer;
export const { setDeleteResult, clearDeleteResult } = deleteResultSlice.actions;