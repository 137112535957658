import React, { useState } from "react";
import { Box, IconButton, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import InfoIcon from "@mui/icons-material/Info";
import { blue } from "@mui/material/colors";

function DataGridRecordPublico({ rows }) {
  const columns = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
      flex: 0,
      width: 1,
      headerClassName: "hidden-column",
      cellClassName: "hidden-column",
    },
    {
      field: "piloto",
      headerName: "Piloto",
      minWidth: 300,
      sortable: false,
    },
    {
      field: "tipo",
      headerName: "Tipo",
      width: 300,
      sortable: false,
      filterable: false,
    },
    {
      field: "marca",
      headerName: "Marca",
      width: 100,
      sortable: false,
      filterable: false,
    },
    {
      field: "territorio",
      headerName: "Territorio",
      width: 100,
      sortable: false,
      filterable: false,
    },
    {
      field: "provincia",
      headerName: "Provincia",
      sortable: false,
      renderCell: (params) => (params?.value === "null" ? "" : params.value),
    },
    {
      field: "despegue",
      headerName: "Ciudad/Despegue",
      minWidth: 150,
      sortable: false,
    },
    {
      field: "estado",
      headerName: "Estado",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => (params?.value === "null" ? "" : params.value),
    },
    {
      field: "fecha",
      headerName: "Fecha de Vuelo",
      sortable: false,
      description: "columna de fecha",
      minWidth: 120,
      valueFormatter: (params) => dayjs(params.value).format("DD/MM/YYYY"),
    },
    {
      field: "track",
      headerName: "Link",
      sortable: false,
      minWidth: "100",
      renderCell: (params) =>
        // <a href={params.value} target="_blank" rel="noopener noreferrer">{params.value}</a>
        params?.value !== "" ? (
          <IconButton
            component="a"
            href={
              params.value.startsWith("http://") ||
              params.value.startsWith("https://")
                ? params.value
                : `https://${params.value}`
            }
            target="_blank"
            rel="noopener noreferrer"
            size="small"
            sx={{ color: blue[700] }}
          >
            <InfoIcon />
          </IconButton>
        ) : (
          "--"
        ),
    },
  ];

  const [sortModel] = useState([
    { field: "estado", sort: "desc" },
    { field: "fecha", sort: "asc" },
  ]);

  const HiddenHeaderIconGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-menuIcon": {
      display: "none", // Oculta el icono del menú de opciones en la cabecera
    },
  }));

  return (
    <Box sx={{ height: "30%", width: "100%" }}>
      <HiddenHeaderIconGrid
        autoHeight
        style={{ maxWidth: "100%", minWidth: "auto" }}
        rows={rows}
        columns={columns}
        sortModel={sortModel}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5]}
      />
    </Box>
  );
}

export default DataGridRecordPublico;
