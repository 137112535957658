import React, { useContext } from "react";
import { MenuContext } from "../context/MenuContext";
import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import MenuLateral from "../components/MenuLateral";
import { useSelector } from "react-redux";

const Acercade = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://localhost:5000";
  const { menuState } = useContext(MenuContext);
  const state = useSelector((state) => state.modalidad);
  const opcMenu = [
    {
      opcion: "Acerca de...",
      link: "/modalidad/Cnd",
      state,
    },
    {
      opcion: "Resultados",
      link: "/ModalidadCnd",
      state,
    },
    {
      opcion: "Reglamento",
      link: "/ReglamentoCnd",
      state,
    },

    {
      opcion: "Historial",
      link: "/Historial-Publico",
      state,
    },
    {
      opcion: "Records",
      link: "/RecordPublico",
      state,
    },
    {
      opcion: "Selección Argentina",
      link: "/SeleccionPublicada",
      state,
    },
  ];

  const BoxConFondo = {
    backgroundImage: `url(${API_BASE_URL}/images/${state?.modalidad?.imagen})`,
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    textAlign: "center",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  };

  return (
    <>
      <Box style={{ marginTop: "5%" }}>
        <MenuLateral opcMenu={opcMenu} menuTitulo={"CND"} typeMenu={false} />
        <Box
          className={`${
            menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
          }`}
        >
          <Box
            className="ContenedorN"
            style={{ width: "80%", marginTop: "50px" }}
          >
            <Box className="ContSecundarioN">
              <Box sx={BoxConFondo}>
                <div className="SombraN">
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        className="TituloN"
                        variant="h1"
                        style={{ fontSize: 24, textTransform: "uppercase" }}
                      >
                        {"CND"}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Box>
              <Box className="ContenidoN">
                <Grid container className="GridContenedorN">
                  <Grid item>
                    <TextField
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      readOnly
                      fullWidth
                      multiline
                      sx={{ borderColor: "transparent" }}
                      value={state?.modalidad?.descripcion}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Divider className="DividerN" />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Acercade;
