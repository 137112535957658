import React, { useContext, useEffect, useState } from "react";
import "./Styles/ParapenteXC.css";
import PieDePagina from "../components/PieDePagina";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import Calendario from "../components/Calendario";
import MenuLateral from "../components/MenuLateral";
import NovedadCard from "../components/NovedadCard";
import { MenuContext } from "../context/MenuContext";
import { ModalidadContext } from "../context/ModalidadContext";
import { useGetListaCompetenciasQuery } from "../redux/services/competencia";
import { useGetListaNovedadesQuery } from "../redux/services/novedad";
import { useSelector } from "react-redux";

function ParapenteXC() {
  const { menuState } = useContext(MenuContext);
  const state = useSelector((state) => state.modalidad);

  const { modalidadState, setModalidadState } = useContext(ModalidadContext);

  useEffect(() => {
    setModalidadState(state);

    return () => {
      setModalidadState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const [competencias, setCompetencias] = useState();
  const {
    data: dataCompetencias,
    error: errorCompetencias,
    isError: isErrorCompetencias,
    isFetching: isFetchingCompetencia,
  } = useGetListaCompetenciasQuery();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const datosFiltrados = dataCompetencias?.listaCompetencia
          ?.filter((dato) => {
            const ayer = dayjs().subtract(1, "day");
            return (
              dayjs(dato.fechaInicio).isAfter(ayer, "date") ||
              dayjs(dato.fechaFin).isAfter(ayer, "date")
            );
          })
          .map((dato) => ({
            ...dato,
            fechaInicio: dayjs(dato.fechaInicio).format("YYYY-MM-DD"),
            fechaFin: dayjs(dato.fechaFin).format("YYYY-MM-DD"),
          }))
          .filter((dato) => {
            return dato.activo !== false;
          })
          .filter((dato) => {
            return dato.idModalidadFk === state?.modalidad?.id;
          })
          .reverse();

        setCompetencias(datosFiltrados);
      } catch (error) {
        console.error("Error al obtener los posts:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCompetencias]);

  const [novedades, setNovedades] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;
  const {
    data: dataNovedades,
    error: errorNovedades,
    isError: isErrorNovedades,
    isLoading: isLoadingNovedades,
  } = useGetListaNovedadesQuery();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const datosFiltrados = dataNovedades?.listaNovedades
          .filter((dato) => {
            const aunmes = dayjs(dato.fechaPublicacion).add(30, "day");
            return dayjs(aunmes).isAfter(dayjs(), "date");
          })
          .map((dato) => ({
            ...dato,
            fechaPublicacion: dayjs(dato.fechaPublicacion).format("YYYY-MM-DD"),
          }))
          .filter((dato) => {
            return dato.idModalidadFk === state?.modalidad?.id;
          })
          .filter((dato) => {
            return dato.activo === true;
          });

        setNovedades(datosFiltrados);
      } catch (error) {
        console.error("Error al obtener los posts:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataNovedades]);

  const totalPages = Math.ceil(novedades?.length / pageSize);

  const paginatedNovedades = novedades?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };


  const opcMenuTemp = [
    {
      opcion: "Novedades",
      link: "/modalidad/*",
      state,
    },
    {
      opcion: "Reglamento",
      link: "/Reglamento",
      state,
    },
    {
      opcion: "Historial",
      link: "/Historial-Publico",
      state,
    },
    {
      opcion: "Resultados",
      link: "/ResultadosPublicados",
      state,
    },
    {
      opcion: "Ranking",
      link: "/Ranking",
      state,
    },
    {
      opcion: "Historial Ranking",
      link: "/HistorialRanking",
      state,
    },
    {
      opcion: "Selección Argentina",
      link: "/SeleccionPublicada",
      state,
    },
  ];

  const opcMenu = opcMenuTemp.filter(item => {
    if ((item.opcion === "Historial Ranking" || item.opcion === "Ranking") && state?.modalidad?.id !== 14) {
      return false;
    }
    return true;
  });

  const isMobile = window.innerWidth <= 768;

  return (
    <React.Fragment>
      <Box>
        <MenuLateral
          opcMenu={opcMenu}
          menuTitulo={modalidadState?.modalidad?.nombre}
          typeMenu={false}
        />

        <Box
          className={`${menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
            }`}
          style={{}}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              className="gridColumnaPXC2"
            >
              <Box className="boxCategoriaPXC">
                <Typography className="TituloPXC">
                  Sobre {modalidadState?.modalidad?.nombre}:
                </Typography>
              </Box>
              <Box className="boxCategoriaPXC">
                <Typography
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 20,
                    textAlign: "justify",
                    whiteSpace: "pre-line",
                  }}
                  className="TextoPXC"
                >
                  {modalidadState?.modalidad?.descripcion}
                </Typography>
              </Box>
              <Divider sx={{ width: "100%", m: 2, marginBottom: "50px" }} />

              {competencias ? (
                <Box className="boxCalendarioPXC">
                  <Typography className="Subtitulo">Calendario</Typography>
                  <Typography className="Titulo" sx={{ marginBottom: "15px" }}>
                    Próximas competencias:
                  </Typography>

                  {competencias.length === 0 ? (
                    <Typography
                      className="Titulo"
                      style={{ fontSize: 14, alignSelf: "center" }}
                    >
                      No hay próximas competencias previstas.
                    </Typography>
                  ) : (
                    competencias.map((competencia) => (
                      <Calendario
                        key={competencia.id}
                        competencia={competencia}
                      />
                    ))
                  )}
                </Box>
              ) : isFetchingCompetencia ? (
                <CircularProgress />
              ) : (
                <Typography className="Titulo">
                  {" No hay competencias para mostrar."}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              className="gridNovedadesPXC"
              style={{ alignSelf: "center", textAlign: "center" }}
            >
              {novedades ? (
                <>
                  {novedades?.length === 0 ? (
                    <Typography
                      className="Titulo"
                      style={{ fontSize: 14, alignSelf: "center" }}
                    >
                      No hay novedades para mostrar.
                    </Typography>
                  ) : (
                    <Grid
                      container
                      xs={3}
                      md={6}
                      lg={12}
                      sx={{ justifyContent: "center" }}
                    >
                      {novedades.map((novedad) => (
                        <NovedadCard key={novedad.id} novedad={novedad} />
                      ))}
                    </Grid>
                  )}
                </>
              ) : isLoadingNovedades ? (
                <Typography className="Titulo">
                  {" No hay novedades para mostrar."}
                </Typography>
              ) : (
                <CircularProgress />
              )}
            </Grid>
          </Grid>
        </Box>

        <PieDePagina />
      </Box>
    </React.Fragment>
  );
}

export default ParapenteXC;
