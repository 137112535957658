import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../../../context/MenuContext";
import CrearRecord from "./componentes/CrearRecord";
import { useGetListaRecordsQuery } from "../../../../redux/services/record";
import DataGridRecord from "./componentes/DataGridRecord";
import FiltrosRecord from "./componentes/FiltrosRecord";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Record = () => {
  const { menuState } = useContext(MenuContext);

  const [dataRecords, setDataRecords] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { data: resRecords, isLoading: isLoadingRecords } =
    useGetListaRecordsQuery();
  React.useEffect(() => {
    try {
      setDataRecords(resRecords?.listaRecords);
    } catch (error) {
      console.log(error);
    }

    return () => {
      setDataRecords();
    };
  }, [resRecords]);

  useEffect(() => {
    setIsLoading(isLoadingRecords);
  }, [isLoadingRecords]);
  return (
    <>
      <Box
        className={`${
          menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
        }`}
      >
        <>
          <FiltrosRecord
            setDataRecords={setDataRecords}
            setIsLoading={setIsLoading}
          />
        </>
        <Grid
          container
          style={{ marginTop: "5%", display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={12} key={Date.now()}>
            <Typography
              className="Titulo TituloPpalCN"
              sx={{ textAlign: "center" }}
            >
              Lista de records
            </Typography>
          </Grid>
          <Divider className="DividerCN" />
          <div style={{ width: "100%", height: "100%" }}>
            {isLoading ? (
              <Item
                sx={{
                  p: 1,
                  maxWidth: "97%",
                  minWidth: "97%",
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  justifyContent: "center",
                }}
                elevation={3}
              >
                <CircularProgress />
              </Item>
            ) : dataRecords ? (
              <DataGridRecord sx={{}} rows={dataRecords} />
            ) : (
              <Item
                sx={{
                  p: 1,
                  maxWidth: "97%",
                  minWidth: "97%",
                  backgroundColor: "transparent",
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                }}
                elevation={3}
              >
                <Box className="Subtitulo" sx={{ width: "99%" }}>
                  No hay records para mostrar.{" "}
                </Box>
              </Item>
            )}
          </div>
        </Grid>
        <CrearRecord />
      </Box>
    </>
  );
};

export default Record;
