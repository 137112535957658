import React, { useState } from "react";
import "./Styles/Inicio.css";
import "./Styles/ParapenteXC.css";
import {
  Box,
  CircularProgress,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import BarraNavegacion from "../components/BarraNavegacion";
import CalendarioCompetencias from "../components/CalendarioCompetencias";
import NoticiasCarrusel from "../components/NoticiasCarrusel";
import PieDePagina from "../components/PieDePagina";
import { MenuContext } from "../context/MenuContext";
import { useGetListaCompetenciasQuery } from "../redux/services/competencia";
import { useGetListaNovedadesQuery } from "../redux/services/novedad";
import { Link } from "react-router-dom";

function Inicio() {
  const [competencias, setCompetencias] = useState();
  const [novedades, setNovedades] = useState();
  const [paginaActual, setPaginaActual] = useState(1);
  const [elementosPorPagina] = useState(3);
  const indexUltimoElemento = paginaActual * elementosPorPagina;
  const indexPrimerElemento = indexUltimoElemento - elementosPorPagina;
  const novedadesActuales = novedades?.slice(
    indexPrimerElemento,
    indexUltimoElemento
  );

  const paginate = (pageNumber) => {
    setPaginaActual(pageNumber);
  };
  const [paginaActualCompetencia, setPaginaActualCompetencia] = useState(1);
  const [elementosPorPaginaCompetencia] = useState(3);
  const indexUltimoElementoCompetencia = paginaActualCompetencia * elementosPorPaginaCompetencia;
  const indexPrimerElementoCompetencia = indexUltimoElementoCompetencia - elementosPorPaginaCompetencia;
  const competenciasActuales = competencias?.slice(indexPrimerElementoCompetencia, indexUltimoElementoCompetencia);

  const paginateCompetencia = (pageNumber) => {
    setPaginaActualCompetencia(pageNumber);
  };

  const { data: dataCompetencias, isLoading: isLoadingCompetencias } =
    useGetListaCompetenciasQuery();
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const datosFiltrados = dataCompetencias?.listaCompetencia
          ?.filter((dato) => {
            const ayer = dayjs().subtract(1, "day");
            return (
              dayjs(dato.fechaInicio).isAfter(ayer, "date") ||
              dayjs(dato.fechaFin).isAfter(ayer, "date")
            );
          })
          .map((dato) => ({
            ...dato,
            fechaInicio: new Date(dato.fechaInicio).toLocaleDateString(),
            fechaFin: new Date(dato.fechaFin).toLocaleDateString(),
          }))
          .filter((dato) => {
            return dato.activo !== false;
          })
          .sort((a, b) =>
            dayjs(b.fechaPublicacion).diff(dayjs(a.fechaPublicacion))
          )
          .reverse();

        setCompetencias(datosFiltrados);
      } catch (error) {
        console.error("Error al obtener las competencias:", error);
      }
    };

    fetchData();
  }, [dataCompetencias]);

  const { data: dataNovedades, isLoading: isLoadingNovedades } = useGetListaNovedadesQuery();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const datosFiltrados = dataNovedades?.listaNovedades
          ?.filter((dato) => {
            const aunmes = dayjs(dato.fechaPublicacion).add(60, "day");
            return dayjs(aunmes).isAfter(dayjs(), "date");
          })
          .map((dato) => ({
            ...dato,
            fechaPublicacion: dayjs(dato.fechaPublicacion).format("YYYY-MM-DD"),
          }))
          .filter((dato) => {
            return dato.activo !== false;
          })
          .sort((a, b) =>
            dayjs(b.fechaPublicacion).diff(dayjs(a.fechaPublicacion))
          );

        setNovedades(datosFiltrados);
      } catch (error) {
        console.error("Error al obtener novedades:", error);
      }
    };

    fetchData();
  }, [dataNovedades]);

  const { setMenuState } = React.useContext(MenuContext);

  React.useEffect(() => {
    setMenuState(false);

    return () => {
      setMenuState(true);
    };
  }, []);

  return (
    <React.Fragment>
      <BarraNavegacion />
      <Box className="contPrincipalI" style={{ margin: 0 }}>
        <Box
          sx={{ border: "1px solid transparent", height: "100%", py: "3rem" }}
        />
        <Grid container sx={{ flexDirection: "row" }}>
          <Grid item xs={12} md={6} style={{ height: "unset" }} sx={{ px: 1 }}>
            {novedades ? (
              <>
                <div style={{ height: "90%" }}>
                  <Box className="">
                    <Typography
                      className="Titulo"
                      sx={{ marginBottom: "15px" }}
                    >
                      Novedades:
                    </Typography>
                    {novedadesActuales?.length === 0 ? (
                      <Typography
                        className="Titulo"
                        style={{ fontSize: 14, alignSelf: "center" }}
                      >
                        No hay novedades para mostrar.
                      </Typography>
                    ) : (
                      novedadesActuales?.map((novedad) => (
                        <div style={{ height: 150, cursor: "pointer" }}>
                          <NoticiasCarrusel
                            style={{}}
                            key={novedad.id}
                            novedad={novedad}
                            origen={"inicio"}
                            type={true}
                          />
                        </div>
                      ))
                    )}
                  </Box>
                </div>
                <div
                  style={{ height: "10%", marginTop: "1%", marginBottom: "1%" }}
                >
                  <Pagination
                    count={Math.ceil(novedades.length / elementosPorPagina)}
                    page={paginaActual}
                    onChange={(event, value) => paginate(value)}
                  />
                </div>
              </>
            ) : isLoadingNovedades ? (
              <CircularProgress />
            ) : (
              <Typography className="Titulo">
                {" No hay novedades para mostrar."}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className=""
            style={{ height: "unset" }}
            sx={{ px: 1 }}
          >
            {competencias ? (
              <>
                <div style={{ height: "90%" }}>
                  <Box className="">
                    <Typography
                      className="Titulo"
                      sx={{ marginBottom: "15px" }}
                    >
                      Próximas competencias:
                    </Typography>
                    {competenciasActuales?.length === 0 ? (
                      <Typography
                        className="Titulo"
                        style={{ fontSize: 14, alignSelf: "center" }}
                      >
                        No hay próximas competencias previstas.
                      </Typography>
                    ) : (
                      competenciasActuales?.map((competencia) => (
                        <div style={{ height: 150, cursor: "pointer" }}>
                          <CalendarioCompetencias
                            key={competencia.id}
                            competencia={competencia}
                          />
                        </div>
                      ))
                    )}
                  </Box>
                </div>
                <div
                  style={{ height: "10%", marginTop: "1%", marginBottom: "1%" }}
                >
                  <Pagination
                    count={Math.ceil(
                      competencias.length / elementosPorPaginaCompetencia
                    )}
                    page={paginaActualCompetencia}
                    onChange={(event, value) => paginateCompetencia(value)}
                  />
                </div>
              </>
            ) : isLoadingCompetencias ? (
              <CircularProgress />
            ) : (
              <Typography className="Titulo">
                {" No hay competencias para mostrar."}
              </Typography>
            )}
          </Grid>
          <Box
            sx={{ border: "1px solid transparent", height: 30, flexGrow: 1 }}
          />
        </Grid>
      </Box>
      {!isLoadingNovedades && !isLoadingNovedades && (
        <Box className="contPrincipalI" style={{ padding: 10 }}>
          <Link
            className="Titulo"
            to={"/NovedadesAnteriores"}
            style={{ textAlign: "center", mt: 3 }}
          >
            Noticias Anteriores
          </Link>
        </Box>
      )}
      <PieDePagina />
    </React.Fragment>
  );
}

export default Inicio;
