import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://localhost:5000';
export const fetchRankingData = createAsyncThunk('ranking/fetchData', async () => {
    const response = await fetch(`${API_BASE_URL}/calcular-ranking`)
    const data = await response.json()
    return data;
});

const rankingSlice = createSlice({
    name: 'ranking',
    initialState: {
        data: null,
        loading: false,
        error: null,
    },
    reducers: {
        resetRankingData(state) {
            state.data = null;
            state.loading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRankingData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchRankingData.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchRankingData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default rankingSlice.reducer;
export const { resetRankingData } = rankingSlice;