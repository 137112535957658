import React, { useContext, useEffect, useState } from "react";
import "../../../../../components/Styles/Calendario.css";
import InfoIcon from "@mui/icons-material/Info";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MenuContext } from "../../../../../context/MenuContext";
import {
  useBorrarHtmlByIdMutation,
  useGetHtmlByIdQuery,
} from "../../../../../redux/services/resultados";
import ResultadosMangasDetalle from "./ResultadosMangasDetalle";
import { useDispatch, useSelector } from "react-redux";
import { fetchRankingData } from "../../../../../redux/features/rankingSlice";
import { setDeleteResult } from "../../../../../redux/features/deleteResultSlice";

function VerResultadosHtml(props) {
  const [htmlData, setHtmlData] = React.useState();
  const [mangas, setMangas] = useState([]);
  const { menuState } = useContext(MenuContext);
  const { state } = useLocation();
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const { data, isLoading } = useGetHtmlByIdQuery(state ? state.id : null);
  React.useEffect(() => {
    const fetchDataHtml = async () => {
      try {
        const result = await data;

        if (result && result.datosHTML) {
          setHtmlData(result.datosHTML);
        }
      } catch (error) {
        console.error("Error al obtener datosHTML:", error);
      }
    };

    fetchDataHtml();
  }, [data]);

  const [modalState, setModalState] = useState(false);

  const handleCloseModal = () => {
    setModalState(false);
  };
  const handleOpenModal = (t) => {
    setMangas(t);
    setModalState(true);
  };

  const [borrarHtmlById, error, isFetching] = useBorrarHtmlByIdMutation();

  const handleDelete = async () => {
    handleOpen();
    try {
      const resp = await borrarHtmlById(state.id);
      if (resp.error) throw new Error(resp.error);
      await handleCalcularRanking();
      toast.success("Html eliminado", {
        position: toast.POSITION.TOP_RIGTH,
      });
      dispatch(setDeleteResult(true));
      navigate(-1);
    } catch (error) {
      toast.error("Error eliminando html", {
        position: toast.POSITION.TOP_RIGTH,
      });
      handleClose();
    }
  };

  const {
    data: dataRanking,
    loading,
    error: errorCalcular,
  } = useSelector((state) => state.ranking);
  const handleCalcularRanking = async () => {
    try {
      let res = await dispatch(fetchRankingData());
      console.log("dataRanking, loading, errorCalcular");
      console.log(dataRanking, loading, errorCalcular);
      if (!loading)
        if (res.payload.ok !== true) {
          toast.error(`${res.payload.mensaje}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          throw new Error();
        } else {
          toast.success("Ranking calculado!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload();
        }
    } catch (error) {
      toast.error("Error calculando Ranking!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    if (!isFetching) handleClose();
  }, [isFetching]);

  return (
    <React.Fragment>
      <Box
        className={`${
          menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
        }`}
        style={{ marginTop: "7%" }}
      >
        <Grid container sx={{ height: "100%" }} spacing={2}>
          <Box className="Acordeon">
            <Box className="AcordeonSummary">
              <div
                style={{
                  marginTop: "2%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography className="Titulo" sx={{ width: "100%" }}>
                    {state?.titulo}
                  </Typography>
                  <Typography
                    className="Subtitulo textoPequeñoCal"
                    style={{ fontSize: 16 }}
                  >
                    Fin: {dayjs(state?.fechaFin).format("DD-MM-YYYY")}
                  </Typography>
                </div>
                <div>
                  <Button variant="outlined" onClick={handleDelete}>
                    Eliminar Resultados
                  </Button>
                </div>
              </div>
            </Box>
            <Box style={{ marginTop: "1%", overflowX: "auto" }}>
              {!isLoading ? (
                htmlData && (
                  <Table>
                    <TableHead
                      sx={{}}
                      style={{
                        width: "100%",
                        alignContent: "center",
                        alignSelf: "center",
                        height: "auto",
                      }}
                    >
                      <TableRow>
                        <TableCell>
                          <Typography variant="body1" className="Titulo">
                            Posición
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" className="Titulo">
                            Id
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" className="Titulo">
                            Nombre
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" className="Titulo">
                            Nacionalidad
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" className="Titulo">
                            Género
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" className="Titulo">
                            Glider
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" className="Titulo">
                            Auspiciantes
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" className="Titulo">
                            Total
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            className="Titulo"
                          ></Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {htmlData.map((item, index) => (
                      <TableBody
                        key={index}
                        sx={{}}
                        style={{
                          width: "98%",
                          alignContent: "center",
                          alignSelf: "center",
                          height: "auto",
                        }}
                      >
                        <TableRow>
                          <TableCell>
                            <Typography variant="body2" className="body">
                              {item.posicion}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" className="body">
                              {item.idCompetidor}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" className="body">
                              {item.nombre}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" className="body">
                              {item.nacionalidad}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" className="body">
                              {item.genero}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" className="body">
                              {item.glider}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" className="body">
                              {item.sponsor}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" className="body">
                              {item.total}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" style={{ width: "5%" }}>
                            <Typography
                              onClick={() => {
                                handleOpenModal(item.t);
                              }}
                              variant="body1"
                              className="Titulo"
                            >
                              <InfoIcon color="primary" />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                  </Table>
                )
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
      <ResultadosMangasDetalle
        modalState={modalState}
        closeModal={handleCloseModal}
        mangas={mangas}
        idCompetencia={state ? state.id : ""}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <div
          id="div1"
          style={{
            p: 5,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress color="inherit" />
          <p style={{ textAlign: "center", fontSize: 10 }}>
            Eliminando Resultados...
          </p>
          <p style={{ textAlign: "center", color: "orange" }}>
            No actualice esta página
          </p>
        </div>
      </Backdrop>
    </React.Fragment>
  );
}

export default VerResultadosHtml;
