import React, { useEffect, useState } from "react";
import "./Styles/Novedad.css";
import {
  Box,
  Divider,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useGetModalidadByIdQuery } from "../redux/services/modalidad";

function Competencia({
  modalStateCompetencia,
  CloseModal,
  Competencia,
  imagenVistaPreviaCompetenciaCrear,
  base64,
}) {
  const [dataModalidad, setDataModalidad] = React.useState();
  const { data: resModalidad } = useGetModalidadByIdQuery(
    Competencia.idModalidadFk
  );

  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "https://localhost:5000";

  React.useEffect(() => {
    const fetchModalidad = async () => {
      try {
        const result = await resModalidad;

        if (result && result.modalidadIndividual) {
          setDataModalidad(result.modalidadIndividual.nombre);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchModalidad();
    return () => {
      setDataModalidad();
    };
  }, [resModalidad]);

  const BoxConFondo = {
    backgroundImage: `url(${API_BASE_URL}/images/${imagenVistaPreviaCompetenciaCrear})`,
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    textAlign: "center",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  };

  function FormattedInput({ value }) {
    return (
      <Typography
        component="div"
        variant="body1"
        dangerouslySetInnerHTML={{ __html: value }}
      />
    );
  }

  const [imagePreviews, setImagePreviews] = useState([]);

  useEffect(() => {
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };
    const loadPreviews = async () => {
      const imgs = [];
      for (const file of base64) {
        const base64 = await getBase64(file);
        imgs.push(base64);
      }
      setImagePreviews(imgs);
    };

    if (base64 && base64.length > 0) {
      loadPreviews();
    }
  }, [base64]);

  return (
    <React.Fragment>
      <Modal
        open={modalStateCompetencia}
        onClose={CloseModal}
        className="ModalN"
        sx={{}}
      >
        <Box className="ContenedorN">
          <Box className="ContSecundarioN">
            <Box
              sx={BoxConFondo}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                className="SombraN"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Typography
                  sx={{ textTransform: "uppercase" }}
                  gutterBottom
                  variant="h3"
                >
                  {Competencia.titulo}
                </Typography>
                <Typography
                  sx={{ px: 5, WebkitBoxOrient: "vertical", lineHeight: 2 }}
                  gutterBottom
                  variant="body2"
                >
                  {Competencia.asunto ||
                  Competencia.asunto.toLowerCase() === "null"
                    ? Competencia.asunto
                    : ""}
                </Typography>
                <Typography sx={{ py: 1 }} gutterBottom variant="overline">
                  Modalidad : {dataModalidad} - Ubicación:{" "}
                  {Competencia.ubicacion}
                </Typography>
              </div>
            </Box>
            <Box className="ContenidoN">
              <Grid container className="GridContenedorN">
                <Grid item>
                  <TextField
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                      inputComponent: FormattedInput,
                      value: Competencia.desarrollo,
                    }}
                    style={{ height: "100%" }}
                    label="Desarrollo"
                    name="desarrollo"
                    value={Competencia.desarrollo}
                    fullWidth
                    required
                    multiline
                    rows={9}
                  />
                </Grid>
                <Divider className="DividerN" />
                <Grid item>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {imagePreviews?.map((item, index) => (
                      <div key={index} className="imageContainer">
                        <img
                          style={{ width: "100px", height: "100px", margin: 5 }}
                          src={item}
                          alt={index}
                        />
                      </div>
                    ))}
                  </div>
                </Grid>
                <Divider className="DividerN" />
                <Grid className="GridPieN">
                  <Typography className="Texto" sx={{ textAlign: "" }}>
                    {" "}
                    Fecha Inicio:{" "}
                    <b>
                      {dayjs(Competencia.fechaInicio).format("DD/MM/YYYY")}{" "}
                    </b>{" "}
                  </Typography>
                  <Typography className="Texto" sx={{ textAlign: "right" }}>
                    {" "}
                    Fecha Fin:{" "}
                    <b>
                      {" "}
                      {dayjs(Competencia.fechaFin).format("DD/MM/YYYY")}{" "}
                    </b>{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default Competencia;
