import { Box, Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import noimage from "../static/img/sinfoto.png";

const TablaSeleccionInicio = ({
  seleccion: datosSeleccion,
  nombreModalidad,
  idModalidad,
}) => {
  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "https://localhost:5000";
  const [seleccionNacional, setSeleccionNacional] = useState(datosSeleccion);
  return (
    <React.Fragment>
      <Box style={{ marginTop: "5%" }}>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableCell className="Titulo" colSpan={6} align="center">
                    <Typography variant="h5" sx={{ my: 2, fontWeight: "bold" }}>
                      Selección Argentina de {nombreModalidad}
                    </Typography>
                  </TableCell>
                </TableHead>
                <TableBody>
                  <>
                    <TableRow>
                      <TableCell className="Titulo" align="left">
                        Foto
                      </TableCell>
                      <TableCell className="Titulo" align="left">
                        Nombre
                      </TableCell>
                      <TableCell className="Titulo" align="left">
                        Ciudad
                      </TableCell>
                      <TableCell className="Titulo" align="left">
                        Provincia
                      </TableCell>
                      <TableCell className="Titulo" align="left">
                        Descripción
                      </TableCell>
                    </TableRow>
                    {seleccionNacional?.map((posicion, indice) => (
                      <TableRow key={indice}>
                        <TableCell component="th" scope="row">
                          <img
                            src={
                              posicion?.foto
                                ? `${API_BASE_URL}/images/${posicion?.foto}`
                                : noimage
                            }
                            style={{ width: 100, height: 100, borderRadius: 5 }}
                            alt="imagen jugador selección"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {posicion.nombre}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {posicion.ciudad}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {posicion.provincia}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography
                            sx={{
                              display: "-webkit-box",
                              overflow: "hidden",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 20,
                              textAlign: "justify",
                              whiteSpace: "pre-line",
                            }}
                            className="TextoPXC"
                          >
                            {posicion.descripcion}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default TablaSeleccionInicio;
