import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
  styled,
} from "@mui/material";
// import { Box, Button, CircularProgress, Divider, Grid, Paper, Typography, styled, } from '@mui/material';
import React, { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../../../context/MenuContext";
// import ListarRecord from './componentes/ListarRecord';
import { useLocation } from "react-router-dom";
import MenuLateral from "../../../../components/MenuLateral";
import { useGetListaRecordsQuery } from "../../../../redux/services/record";
import FiltrosRecordPublico from "./componentes/FiltrosRecordPublico";
import DataGridRecordPublico from "./componentes/DataGridRecordPublico";
import { useSelector } from "react-redux";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const RecordPublico = () => {
  const { menuState } = useContext(MenuContext);
  const state = useSelector((state) => state.modalidad);

  const [dataRecords, setDataRecords] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { data: resRecords, isLoading: isLoadingRecords } = useGetListaRecordsQuery();

  React.useEffect(() => {
    try {
      setDataRecords(resRecords?.listaRecords);
    } catch (error) {
      console.log(error);
    }

    return () => {
      setDataRecords();
    };
  }, [resRecords]);

  useEffect(() => {
    setDataRecords((prev) => {
      return prev;
    });
  }, [dataRecords]);

  const opcMenu = [
    {
      opcion: "Acerca de...",
      link: "/modalidad/Cnd",
      state,
    },
    {
      opcion: "Resultados",
      link: "/ModalidadCnd",
      state,
    },
    {
      opcion: "Reglamento",
      link: "/ReglamentoCnd",
      state,
    },
    {
      opcion: "Historial",
      link: "/Historial-Publico",
      state,
    },
    {
      opcion: "Records",
      link: "/RecordPublico",
      state,
    },
    {
      opcion: "Selección Argentina",
      link: "/SeleccionPublicada",
      state,
    },
  ];

  return (
    <>
      <Box style={{ marginTop: "5%" }}>
        <MenuLateral opcMenu={opcMenu} menuTitulo={"Record"} typeMenu={false} />
        <Box
          className={`${
            menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
          }`}
        >
          <>
            <FiltrosRecordPublico
              setDataRecords={setDataRecords}
              setIsLoading={setIsLoading}
            />
          </>
          <Grid
            container
            style={{
              marginTop: "5%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} key={Date.now()}>
              <Typography
                className="Titulo TituloPpalCN"
                sx={{ textAlign: "center" }}
              >
                Lista de records
              </Typography>
            </Grid>
            <Divider className="DividerCN" />
            <div style={{ width: "100%", height: "100%" }}>
              {!isLoading ? (
                dataRecords ? (
                  <DataGridRecordPublico sx={{}} rows={dataRecords} />
                ) : (
                  <Item
                    sx={{
                      p: 1,
                      maxWidth: "97%",
                      minWidth: "97%",
                      backgroundColor: "transparent",
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                    }}
                    elevation={3}
                  >
                    <Box className="Subtitulo" sx={{ width: "99%" }}>
                      No hay records para mostrar.{" "}
                    </Box>
                  </Item>
                )
              ) : (
                <Item
                  sx={{
                    p: 1,
                    maxWidth: "97%",
                    minWidth: "97%",
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    justifyContent: "center",
                  }}
                  elevation={3}
                >
                  <CircularProgress />
                </Item>
              )}
            </div>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default RecordPublico;
