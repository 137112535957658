import React from 'react';
import FAVL_Logo from '../static/img/FAVL-logo1.png';
import './Styles/PieDePagina.css';
import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { MenuContext } from '../context/MenuContext';

function PieDePagina() {

  const { menuState } = React.useContext(MenuContext);

  return (
    <React.Fragment>
      <Box
        className={`${menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
          }`}
      >
        <Grid container className="gridContainerPdP">
          <Grid item xs={12} md={3} className="gridItemPdP">
            {/* <Typography component={Link} to={"https://www.favl.com.ar/"} target='_blank' className="TituloPdP" sx={{ textDecoration: 'none' }}>Contactanos</Typography> */}

          </Grid>
          <Grid item xs={12} md={6} className="gridItemPdP BordesLateralesPdP">
            <Typography className="TituloPdP">Sobre nosotros</Typography>
            <Typography className="SubtituloPdP">
            La Federación Argentina de Vuelo Libre es el núcleo unificador de los apasionados del vuelo en todo el país. Algunas de nuestras responsabilidades más relevantes son:
            </Typography>
            <ul className="SubtituloPdP">
              <li>Promovemos y regulamos la realización de campeonatos oficiales.</li>
              <li>Fomentamos la exploración y el descubrimiento de nuevos vuelos.</li>
              <li>Desarrollamos y estructuramos metodologías de enseñanza.</li>
              <li>Representamos a nuestro país en el ámbito internacional, y ante la FAI.</li>
              <li>Somos un vehículo para el intercambio y el desarrollo.</li>
            </ul>

            <Typography className="SubtituloPdP">
            ¡Bienvenido a un mundo donde la libertad tiene alas y el cielo no tiene límites!
            </Typography>
          </Grid>
          <Grid component={Link} target='_blank' to={"https://www.favl.com.ar/"} item xs={3} className="gridItemPdP">
            <img title='Logo' src={FAVL_Logo} className="imgPdP" aria-label='log favl'></img>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default PieDePagina