import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../../../context/MenuContext";
import { useGetListaModalidadesSelectQuery } from "../../../../redux/services/modalidad";
import {
  useAgregarPdfReglamentoMutation,
  useAgregarReglamentoMutation,
  useGetListaReglamentosQuery,
} from "../../../../redux/services/reglamento";
import { toast } from "react-toastify";
import TablaReglamentosAdmin from "./componentes/TablaReglamentosAdmin";

const ReglamentosModalidades = () => {
  const { menuState } = useContext(MenuContext);
  const [reglamentos, setReglamentos] = useState([]);
  const initialState = {
    nombre: "",
    idModalidad: 14,
    reglamento: "",
    nombrePdf: "",
  };
  const [newData, setNewData] = useState(initialState);
  const [nombreModalidad, setNombreModalidad] = useState("Parapente XC");
  const [dataModalidades, setDataModalidades] = React.useState();
  const [hasError, setHasError] = React.useState({
    modalidad: false,
  });

  const { data: resModalidades, isLoading: isLoadingModalidad } =
    useGetListaModalidadesSelectQuery();
  React.useEffect(() => {
    try {
      setDataModalidades(resModalidades?.listarModalidades);
    } catch (error) {
      console.log("Error al obtener los modalidades:", error);
    }

    return () => {
      setDataModalidades();
    };
  }, [resModalidades]);

  const { data: dataReglamentos, isLoading: isLoadingReglamentos } =
    useGetListaReglamentosQuery(newData?.idModalidad);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setReglamentos(dataReglamentos?.listaReglamento);
      } catch (error) {
        console.error("Error al obtener los reglamentos:", error);
      }
    };
    fetchData();
    return () => {
      setReglamentos([]);
    };
  }, [newData.idModalidad, dataReglamentos]);

  const handleChangeModalidad = (e) => {
    const { value, name } = e.target;

    const datosFiltrados = dataModalidades?.filter((dato) => {
      return dato.id === value;
    });
    setNombreModalidad(datosFiltrados[0].nombre);
    setNewData({
      ...newData,
      [name]: value,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData({
      ...newData,
      [name]: value,
    });
  };

  const hiddenInputRef = React.createRef();
  const [fileNameRules, setFileNameRules] = useState("");

  const handleOpenFileDialog = () => {
    hiddenInputRef.current.click();
  };
  const HiddenInput = styled("input")({
    display: "none",
  });

  const handleReglaChange = (e) => {
    const file = e.target.files[0];
    setFileNameRules(file?.name);
    setNewData({
      ...newData,
      reglamento: file,
    });
  };

  const [agregarReglamento, error] = useAgregarReglamentoMutation();

  const [postId, setPostId] = useState();

  const handlePublicar = async (e) => {
    e.preventDefault();

    try {
      const resp = await agregarReglamento(newData);
      if (resp.error) throw new Error(error);
      setPostId(resp.data.mensaje);
      toast.success("Reglamento creado!", {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "success3",
      });
    } catch (error) {
      toast.error("Error creando Reglamento!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("Error creating Reglamento:", error);
    }
  };

  const [agregarPdfReglamento, { error: errorPdf }] =
    useAgregarPdfReglamentoMutation();
  useEffect(() => {
    const AgregarPdf = async () => {
      try {
        if (!postId) return;
        const resp = await agregarPdfReglamento({
          pdf: newData.reglamento,
          idReglamento: postId,
        });
        if (resp.error) throw new Error(error);
        setNewData(initialState);
        setFileNameRules("");
        toast.success("pdf Agregado!", {
          position: toast.POSITION.TOP_RIGHT,
          toastId: "success1",
        });
      } catch (error) {
        toast.error("Error agregando pdf!", {
          position: toast.POSITION.TOP_RIGHT,
          toastId: "success1",
        });
        console.log(errorPdf);
      }
    };

    AgregarPdf();
  }, [postId]);

  const isMobile = window.innerWidth <= 768;

  return (
    <>
      <Box
        className={`${
          menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
        }`}
        style={{ marginTop: isMobile ? "15%" : "6%" }}
      >
        <Typography
          className="Titulo"
          variant="h5"
          sx={{
            textAlign: "center",
            my: 2,
            fontWeight: "bold",
            border: "2px dotted grey",
          }}
        >
          Subir Reglamento{" "}
        </Typography>
        <Grid container sx={{ justifyContent: "space-around" }}>
          <>
            <Grid
              item
              xs={12}
              md={4}
              className="GridItemCN"
              style={{ flexDirection: "column" }}
            >
              <TextField
                label="Nombre"
                name="nombre"
                value={newData.nombre}
                onChange={handleChange}
                sx={{ bgcolor: "transparent", minWidth: "100%", mx: 1, mb: 2 }}
                required
                fullWidth
              />
              <TextField
                label="Reglamento .pdf, .doc, .docx"
                name="reglasPDF"
                value={newData.nombrePdf ? newData.nombrePdf : fileNameRules}
                variant="outlined"
                autoComplete="off"
                onClick={handleOpenFileDialog}
                InputProps={{ readOnly: true }}
                sx={{ bgcolor: "transparent", minWidth: "100%", mx: 1 }}
                required
                fullWidth
              />
              <HiddenInput
                name="reglasPDF"
                type="file"
                ref={hiddenInputRef}
                accept=".pdf, .doc, .docx"
                onChange={handleReglaChange}
              />
            </Grid>
          </>

          <Grid
            item
            xs={12}
            md={4}
            className="GridItemCN"
            style={{ flexDirection: "column" }}
          >
            {resModalidades ? (
              <FormControl
                fullWidth
                sx={{ textAlign: "center" }}
                error={hasError.modalidad}
              >
                <InputLabel id="demo-simple-select-label">Modalidad</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="idModalidad"
                  value={newData.idModalidad}
                  label="Modalidad"
                  onChange={handleChangeModalidad}
                  required
                >
                  {dataModalidades?.map((modalidad) => (
                    <MenuItem key={modalidad.id} value={modalidad.id}>
                      {modalidad.nombre}
                    </MenuItem>
                  ))}
                </Select>
                {hasError.modalidad && (
                  <FormHelperText
                    sx={{ color: "#D32F2F", textAlign: "center" }}
                  >
                    Campo requerido!
                  </FormHelperText>
                )}
              </FormControl>
            ) : isLoadingModalidad ? (
              <Grid container justifyContent="center" alignItems="center">
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ width: "50%", height: "50%", textAlign: "center" }}
                >
                  <CircularProgress style={{ width: "30px", height: "30px" }} />
                  <Typography
                    style={{
                      color: "rgb(0, 150, 215)",
                      fontSize: "15px",
                      marginTop: "10px",
                    }}
                  >
                    Cargando Modalidad
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Typography className="Titulo">
                {" No hay Modalidad para mostrar."}
              </Typography>
            )}
            <Button
              className="BotonBody BotonCN"
              disabled={fileNameRules === "" || newData.nombre === ""}
              onClick={handlePublicar}
              style={{ width: "90%", alignSelf: "center" }}
            >
              Publicar
            </Button>
          </Grid>

          <Grid item xs={12} sx={{ my: 5 }}>
            {isLoadingReglamentos ? (
              <Box style={{ margin: "20px", textAlign: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                <Typography
                  className="Titulo"
                  variant="h5"
                  sx={{
                    textAlign: "center",
                    my: 2,
                    fontWeight: "bold",
                    border: "2px dotted grey",
                  }}
                >
                  Reglamentos de {nombreModalidad}{" "}
                </Typography>
                {!reglamentos || reglamentos?.length === 0 ? (
                  <Typography
                    className="Titulo"
                    style={{ margin: "20px", textAlign: "center" }}
                  >
                    No hay Reglamentos para mostrar.
                  </Typography>
                ) : (
                  <Box>
                    <TablaReglamentosAdmin
                      reglamentos={reglamentos}
                      nombreModalidad={nombreModalidad}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ReglamentosModalidades;
