import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Styles/CrearNovedad.css";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import BarraNavegacion from "../components/BarraNavegacion.js";
import Dropzone from "../components/Dropzone.js";
import PieDePagina from "../components/PieDePagina";
import { useGetListaModalidadesSelectQuery } from "../redux/services/modalidad.js";
import {
  useAgregarImagenNovedadMutation,
  useAgregarNovedadMutation,
} from "../redux/services/novedad.js";
import Novedad from "./Novedad";
import { LoadingButton } from "@mui/lab";

dayjs.locale({
  ...require("dayjs/locale/es"),
  weekStart: 0,
});

function CrearNovedad({ sinmenu }) {
  const [fecha] = React.useState(Date.now());
  const [editorContent, setEditorContent] = useState("");

  const desarrolloNovedadNewRef = useRef(null);

  const [base64, setBase64] = useState([]);

  const initialState = {
    titulo: "",
    imagen: "",
    fechaPublicacion: dayjs(fecha).format("YYYY-MM-DD"),
    asunto: "",
    desarrollo: "",
    autor: "FAVL",
    activo: true,
    idModalidadFk: 0,
  };

  const [novedad, setNovedad] = useState(initialState);

  const [validationErrors, setValidationErrors] = useState({
    titulo: false,
    desarrollo: false,
    autor: false,
  });

  const [errorFecha, setErrorFecha] = React.useState(null);

  const errorMessageFecha = React.useMemo(() => {
    switch (errorFecha) {
      case "disablePast": {
        return "Por favor seleccione una fecha vigente";
      }
      case "invalidDate": {
        return "Fecha inválida";
      }
      default: {
        return "";
      }
    }
  }, [errorFecha]);

  const [modalState, setModalState] = useState(false);

  const handleOpenModal = () => {
    setModalState(true);
  };

  const handleCloseModal = () => {
    setModalState(false);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === "",
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNovedad({
      ...novedad,
      [name]: value,
    });

    if (isNaN(value)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === "",
      }));
    }
  };

  const handleChangeDesarrollo = useCallback(
    (e) => {
      e.preventDefault();
      const { value } = e.target;
      setNovedad({
        ...novedad,
        desarrollo: value,
      });

      if (isNaN(value)) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          desarrollo: value.trim() === "",
        }));
      }
    },
    [novedad]
  );

  const [imagenVistaPrevia, setImagenVistaPrevia] = useState("");
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNovedad({
        ...novedad,
        imagen: file,
      });
      const reader2 = new FileReader();
      reader2.onload = (event) => {
        setImagenVistaPrevia(event.target.result);
      };
      reader2.readAsDataURL(file);
    }
  };

  const [agregarNovedad, { error, isLoading: isLoadingAgregarNovedad }] =
    useAgregarNovedadMutation();
  const [agregarImagenNovedad] = useAgregarImagenNovedadMutation();
  const [postId, setPostId] = useState();

  useEffect(() => {
    setNovedad({
      ...novedad,
      desarrollo: editorContent,
    });
  }, [editorContent]);

  const handlePublicar = async (e) => {
    e.preventDefault();

    if (novedad.titulo.trim() === "")
      setValidationErrors({ ...validationErrors, titulo: true });
    if (validationErrors.titulo || novedad.titulo.trim() === "") {
      toast.error("Campo título es obligatorio!");
      return;
    }
    if (novedad.autor.trim() === "")
      setValidationErrors({ ...validationErrors, autor: true });
    if (validationErrors.autor || novedad.autor.trim() === "") {
      toast.error("Campo autor es obligatorio!");
      return;
    }
    if (novedad.desarrollo.trim() === "")
      setValidationErrors({ ...validationErrors, desarrollo: true });
    if (validationErrors.desarrollo || novedad.desarrollo.trim() === "") {
      toast.error("Campo desarrollo es obligatorio!");
      return;
    }
    try {
      const resp = await agregarNovedad(novedad)
      if (resp.error) throw new Error(error)
      setPostId(resp.data.mensaje)
      const editorInstance = window.tinymce?.get('desarrolloNovedadNew');
      if (editorInstance) {
        editorInstance.setContent("");
      }
      setNovedad(initialState);
      toast.success("Novedad creada!", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success1",
      });
    } catch (error) {
      toast.error("Error creando la Novedad!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("Error creating post:", error);
    }
  };

  useEffect(() => {
    const AgregarImagen = async () => {
      base64?.map(async (img, i) => {
        try {
          if (!postId) return;
          await agregarImagenNovedad({ imagen: img, idNovedad: postId });
          toast.success("Imagen Agregada!", {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "success1",
          });
        } catch (error) {
          toast.error("Error agregando imágenes!", {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "success1",
          });
        }
      });
    };

    AgregarImagen();

    return () => {
      setBase64([]);
    };
  }, [postId]);

  const [modalidades, setModalidades] = useState();
  const {
    data: dataModalidades,
    isLoading: isLoadingModalidad,
    isError: isErrorModalidades,
  } = useGetListaModalidadesSelectQuery();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setModalidades(dataModalidades?.listarModalidades);
      } catch (error) {
        console.error("Error al obtener los modalidades:", error);
      }
    };

    fetchData();
  }, [dataModalidades]);

  const [isEditable, setIsEditable] = useState(false);
  useEffect(() => {
    const editorInstance = window.tinymce?.get('desarrolloNovedadNew');
    if (editorInstance) {
      editorInstance.setContent("");
    }
    setIsEditable(true);
    return () => {
      setIsEditable(false);
    };
  }, []);

  React.useEffect(() => {
    const initEditor = () => {
      if (desarrolloNovedadNewRef.current) {
        window.tinymce?.init({
          selector: "#desarrolloNovedadNew", 
          menubar: false,
          min_height: 250,
          max_height: 250,
          width: "100%",
          plugins: ["link"],
          toolbar:
            "undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | link",
          branding: false,
          setup: (editor) => {
            editor.on('init', () => {
              const editorInstance = window.tinymce?.get('desarrolloNovedadNew');
              if (editorInstance) {
                editorInstance.setContent("");
              }
            });
            editor.on("change", (e) => {
              const content = editor.getContent();
              setEditorContent(content);
            });

            editor.on("paste", (e) => {
              e.preventDefault();

              const clipboardData = e.clipboardData || window.Clipboard;
              const html = clipboardData.getData("text/html");

              const cleanedHtml = cleanHtmlContent(html);
              editor.insertContent(cleanedHtml);
            });

            editor.on("dragover dragenter", (e) => {
              const types = e.dataTransfer.types;
              if (Array.from(types).some((type) => type === "Files")) {
                e.preventDefault();
              }
            });

            editor.on("drop", (e) => {
              const types = e.dataTransfer.types;
              if (Array.from(types).some((type) => type === "Files")) {
                e.preventDefault();
              }
            });
          },
        });
      }
    };

    const cleanHtmlContent = (html) => {
      const tempElement = document.createElement("div");
      tempElement.innerHTML = html;

      const cleanElement = (element) => {
        if (element.nodeType === Node.ELEMENT_NODE) {
          element.removeAttribute("style");
          element.removeAttribute("class");

          if (element.style && element.style.backgroundImage) {
            element.style.backgroundImage = "none";
          }
        }

        element.childNodes.forEach((child) => {
          cleanElement(child);
        });
      };
      cleanElement(tempElement);

      const imgElements = tempElement.querySelectorAll("img");
      imgElements.forEach((img) => {
        img.parentNode.removeChild(img);
      });

      const cleanedHtml = tempElement.innerHTML;
      tempElement.remove();

      return cleanedHtml;
    };
    initEditor();
    return () => {
      if (window.tinymce?.get(desarrolloNovedadNewRef.current?.id)) {
        window.tinymce.get(desarrolloNovedadNewRef.current?.id).remove();
      }
    };
  }, [isEditable]);

  const [formDataImagenes, setFormDataImagenes] = useState(new FormData());

  useEffect(() => {
    const updateFormData = () => {
      const newFormData = new FormData();
      base64.forEach(async (file, index) => {
        newFormData.append(`file${index + 1}`, file, file.name);
      });
      setFormDataImagenes(newFormData);
    };
    updateFormData();
  }, [base64]);

  useEffect(() => {
    return () => {
      setFormDataImagenes(new FormData());
    };
  }, [postId]);

  return (
    <React.Fragment>
      <Box>
        {!sinmenu && <BarraNavegacion />}
        <Box className="ContenidoPpalCN">
          <Grid container className="GridPpalCN">
            <Grid item className="GridItemCN">
              <Typography className="Titulo TituloPpalCN">
                Crear publicación de Novedad
              </Typography>
            </Grid>
            <Divider className="DividerCN" />
            <Grid container className="GridItemCN">
              <Grid item md={4} xs={12} style={{}}>
                <TextField
                  label="Título"
                  name="titulo"
                  value={novedad.titulo}
                  onChange={handleChange}
                  required
                  fullWidth
                  error={validationErrors.titulo}
                  helperText={
                    validationErrors.titulo && "Este campo es requerido"
                  }
                />
              </Grid>
              <Divider orientation="vertical" className="DividerVerticalCN" />
              <Grid item md={3} xs={12} style={{}}>
                <TextField
                  label="Autor"
                  name="autor"
                  value={novedad.autor}
                  onChange={handleChange}
                  required
                  fullWidth
                  error={validationErrors.autor}
                  helperText={
                    validationErrors.autor && "Este campo es requerido"
                  }
                />
              </Grid>
              <Divider orientation="vertical" className="DividerVerticalCN" />
              <Tooltip title="Se publica con fecha actual">
                <Grid item md={3} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={dayjs(novedad.fechaPublicacion)}
                      label="Fecha de Publicación"
                      name="fecha"
                      minDate={dayjs()}
                      disablePast
                      disabled
                      disableTextEditor
                      inputFormat="DD/MM/YYYY"
                      format="DD/MM/YYYY"
                      onChange={(newValue) => {
                        const fecha = dayjs(newValue).format("YYYY-MM-DD");
                        try {
                          setNovedad((prevNovedad) => ({
                            ...prevNovedad,
                            fechaPublicacion: fecha,
                          }));
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      sx={{}}
                      onError={(newError) => setErrorFecha(newError)}
                      slotProps={{
                        textField: {
                          style: { width: "100%" },
                          helperText: errorMessageFecha,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Tooltip>
            </Grid>
            <Divider className="DividerCN" />
            <Grid container sx={{ justifyContent: "space-between" }}>
              <Grid item xs={12} md={5.5} className="GridItemCN">
                <Box sx={{ width: "100%" }}>
                  {dataModalidades ? (
                    <FormControl fullWidth style={{}}>
                      <InputLabel id="demo-simple-select-label">
                        Modalidad
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="idModalidadFk"
                        value={novedad.idModalidadFk}
                        label="Modalidad"
                        onChange={handleChange}
                        required
                        sx={{ height: 80 }}
                      >
                        {modalidades?.map((modalidad) => (
                          <MenuItem key={modalidad.id} value={modalidad.id}>
                            {modalidad.nombre}
                          </MenuItem>
                        ))}
                        <MenuItem style={{ display: "none" }} key={0} value={0}>
                          {" "}
                        </MenuItem>
                      </Select>
                      {isErrorModalidades && (
                        <FormHelperText sx={{ color: "#D32F2F" }}>
                          {" "}
                          Campo requerido!
                        </FormHelperText>
                      )}
                    </FormControl>
                  ) : isLoadingModalidad ? (
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          width: "50%",
                          height: "50%",
                          textAlign: "center",
                        }}
                      >
                        <CircularProgress
                          style={{ width: "30px", height: "30px" }}
                        />
                        <Typography
                          style={{
                            color: "rgb(0, 150, 215)",
                            fontSize: "15px",
                            marginTop: "10px",
                          }}
                        >
                          Cargando Modalidad
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography className="Titulo">
                      {" No hay Modalidad para mostrar."}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={6} className="GridItemCN" style={{}}>
                <TextField
                  label="Asunto"
                  name="asunto"
                  value={novedad.asunto}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  multiline
                  rows={2}
                />
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid
              container
              className="GridItemCN"
              style={{ minHeight: 262, marginTop: -7 }}
            >
              <Grid
                item
                xs={12}
                md={8}
                className="GridItemCN"
                style={{ minHeight: 262, marginTop: -7 }}
              >
                <TextField
                  inputRef={desarrolloNovedadNewRef}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={{ height: 262, maxHeight: 262 }}
                  label="Desarrollo"
                  name="desarrollo"
                  value={editorContent}
                  onChange={handleChangeDesarrollo}
                  fullWidth
                  required
                  multiline
                  rows={9}
                  error={validationErrors.desarrollo}
                  helperText={
                    validationErrors.desarrollo && "Este campo es requerido"
                  }
                  id="desarrolloNovedadNew"
                  variant="standard"
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={3}
                className="GridItemCN"
                style={{ minHeight: 240 }}
              >
                <Box className="BoxImagenCN" style={{ minHeight: 240 }}>
                  <Typography className="Texto">Imagen Encabezado</Typography>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none", minHeight: 250 }}
                    id="imagenInput"
                  />
                  {novedad.imagen && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        objectFit: "fill",
                        maxHeight: "50%",
                      }}
                    >
                      <img
                        src={imagenVistaPrevia ? imagenVistaPrevia : null}
                        alt="Vista previa de la imagen"
                        style={{ maxWidth: "30%", marginTop: "16px" }}
                      />
                    </Box>
                  )}
                  <Divider className="DividerCN" />
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <label htmlFor="imagenInput">
                      <Button
                        variant="outlined"
                        component="span"
                        color="primary"
                        className="BotonBody BotonCN"
                        sx={{}}
                      >
                        Seleccionar
                      </Button>
                    </label>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid container sx={{ flexDirection: "column" }}>
              <Dropzone className="" setBase64={setBase64} base64={base64} />
            </Grid>
            <Divider className="DividerCN" />
            <Grid item className="GridBotonesCN" style={{ marginTop: "2%" }}>
              <Button className="BotonBody BotonCN" onClick={handleOpenModal}>
                Previsualizar
              </Button>
              <LoadingButton
                loading={isLoadingAgregarNovedad}
                className="BotonBody BotonCN"
                onClick={handlePublicar}
              >
                Publicar
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
        {!sinmenu && <PieDePagina />}
      </Box>
      <Novedad
        key={novedad.id}
        ModalState={modalState}
        CloseModal={handleCloseModal}
        Novedad={novedad}
        Origen={"previsualizar"}
        imagenVistaPrevia={imagenVistaPrevia}
        base64={base64}
      />
    </React.Fragment>
  );
}

export default CrearNovedad;
