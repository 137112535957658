import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://localhost:5000';

const getAuthToken = () => {
    const token = localStorage.getItem('accessToken');
    return token;
};

export const modalidadHomeApi = createApi({
    reducerPath: 'modalidadHomeApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL,
        // Interceptamos la solicitud saliente y agregamos el encabezado de autorización
        prepareHeaders: (headers) => {
            // Obtener el token de autorización
            const token = getAuthToken();
            if (token) {
                // Si hay un token, lo agregamos al encabezado de autorización
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ['Modalidades'],
    endpoints: (builder) => ({
        getListaModalidades: builder.query({
            query: () => '/buscar-lista-modalidades',
            providesTags: ['Modalidades'],
        }),
    }),
})

export const {
    useGetListaModalidadesQuery,
} = modalidadHomeApi;