import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import GetAppIcon from "@mui/icons-material/GetApp";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import React, { Fragment, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { toast } from "react-toastify";
import useConfirmationModal from "../../../../../hooks/useConfirmationModal ";
import { useGetCompetidorByIdQuery } from "../../../../../redux/services/competidor";
import {
  useBorrarHistorialByIdMutation,
  useBorrarPdfHistorialByIdMutation,
  useModificarHistorialMutation,
} from "../../../../../redux/services/historial";
import { useGetListaModalidadesSelectQuery } from "../../../../../redux/services/modalidad";
import TableHistorial from "./TableHistorial";

const ModalHistorial = ({ historial, Origen = "readonly", onClose }) => {
  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "https://localhost:5000";
  const [reload, setReload] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState({
    posiciones: false,
    desde: false,
    hasta: false,
    licencia: [false, false, false],
  });
  const [isEditable, setIsEditable] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [newData, setNewData] = React.useState(historial);
  const formRef = React.useRef();

  const [temporada, setTemporada] = React.useState(historial);
  const [legajo, setLegajo] = React.useState();

  useEffect(() => {
    setTemporada(historial);
  }, [historial, reload]);

  const [hasError, setHasError] = React.useState({
    modalidad: false,
  });

  const [dataModalidades, setDataModalidades] = React.useState();
  const { data: resModalidades, isLoading: isLoadingModalidad } =
    useGetListaModalidadesSelectQuery();

  React.useEffect(() => {
    try {
      setDataModalidades(resModalidades?.listarModalidades);
    } catch (error) {
      console.log("Error al obtener los modalidades:", error);
    }

    return () => {
      setDataModalidades();
    };
  }, [resModalidades]);

  const actualizarPosicion = (e, index) => {
    const { name, value } = e.target;
    setTemporada((prevState) => {
      const nuevasPosiciones = [...prevState.posiciones];
      nuevasPosiciones[index] = {
        ...nuevasPosiciones[index],
        [name]: value,
      };
      return { ...prevState, posiciones: nuevasPosiciones };
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    let errorMessage = "";

    if (name === "desde" || name === "hasta") {
      errorMessage =
        value === "" || value.trim() === "" || isNaN(value)
          ? "Este campo es requerido"
          : "";
      if (
        name === "desde" &&
        temporada.hasta &&
        parseInt(value) >= parseInt(temporada.hasta)
      ) {
        errorMessage = "El año desde debe ser menor que el año hasta";
      } else if (
        name === "hasta" &&
        temporada.desde &&
        parseInt(value) <= parseInt(temporada.desde)
      ) {
        errorMessage = "El año hasta debe ser mayor que el año desde";
      }
    }
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const isValidYear = /^\d{4}$/.test(value) && ["1", "2"].includes(value[0]);
    let errorMessage = "";

    if (name === "desde" || name === "hasta") {
      if (!value.trim()) {
        errorMessage = "Este campo es requerido";
      } else if (!isValidYear) {
        errorMessage = "El número debe comenzar con 1 o 2 y contener 4 dígitos";
      } else if (
        name === "desde" &&
        temporada.hasta &&
        parseInt(value) >= parseInt(temporada.hasta)
      ) {
        errorMessage = "El año desde debe ser menor que el año hasta";
      } else if (
        name === "hasta" &&
        temporada.desde &&
        parseInt(value) <= parseInt(temporada.desde)
      ) {
        errorMessage = "El año hasta debe ser mayor que el año desde";
      }
    }
    setTemporada({
      ...temporada,
      [name]: value,
    });

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage !== "",
    }));
  };

  const handleChangeLicencia = (e, index) => {
    e.preventDefault();
    const { value } = e.target;
    setTemporada((prevState) => {
      let newObjet = {
        ...prevState,
      };
      newObjet.posiciones[index] = value;
      return {
        ...newObjet,
      };
    });
  };

  const handleBlurLicencia = (e, index) => {
    e.preventDefault();
    const { value } = e.target;
    setLegajo({
      ...legajo,
      index,
      id: value,
    });
    setTemporada((prevTemporada) => {
      const updatedPosiciones = [...prevTemporada.posiciones];
      if (updatedPosiciones[index]) {
        updatedPosiciones[index] = {
          ...updatedPosiciones[index],
          id: value,
        };
      }
      return {
        ...prevTemporada,
        posiciones: updatedPosiciones,
      };
    });
  };

  const { data: dataLicencia } = useGetCompetidorByIdQuery(legajo?.id);
  const [
    modificarHistorial,
    { isLoading: isLoadingHistorial, isError: isErrorHistorial },
  ] = useModificarHistorialMutation();

  React.useEffect(() => {
    const fetchLicencia = async (index) => {
      try {
        if (legajo) {
          const result = await dataLicencia;
          if (result) {
            const { idCompetidor, nombre, ciudad, provincia } =
              result.competidorIndividual;
            setTemporada((prevTemporada) => {
              const nuevasPosiciones = [...prevTemporada.posiciones];
              nuevasPosiciones[index] = {
                id: idCompetidor,
                nombre: nombre,
                ciudad: ciudad,
                provincia: provincia,
              };
              return { ...prevTemporada, posiciones: nuevasPosiciones };
            });
          }
        }
      } catch (error) {
        console.error("Error obteniendo datos de la licencia:", error);
        toast.error("El competidor no se encuentra cargado!");
        return;
      }
    };
    if (legajo) {
      fetchLicencia(legajo.index);
    }
  }, [dataLicencia]);

  const hiddenInputRef = React.createRef();
  const [fileNameRules, setFileNameRules] = React.useState("");

  const handleOpenFileDialog = () => {
    hiddenInputRef.current.click();
  };
  const HiddenInput = styled("input")({
    display: "none",
  });

  const handleReglaChange = (e) => {
    const file = e.target.files[0];
    setFileNameRules(file?.name);
    if (file) {
      setTemporada({
        ...temporada,
        pdf: file,
        nombrePdf: file?.name,
      });
    }
  };

  const handleRequired = () => {
    const errors = {
      desde: temporada.desde === 0 || isNaN(temporada.desde),
      hasta: temporada.hasta === 0 || isNaN(temporada.hasta),
    };

    setValidationErrors((prevState) => {
      return {
        ...prevState,
        desde: temporada.desde === 0 || isNaN(temporada.desde),
        hasta: temporada.hasta === 0 || isNaN(temporada.hasta),
      };
    });

    if (Object.values(errors).some((error) => error)) {
      console.log("No se puede guardar debido a errores de validación");
      return true;
    }

    return false;
  };

  const { openConfirm, ModalConfirmacion } = useConfirmationModal();

  const handleEdit = async (e) => {
    e.preventDefault();
    let hasLicenciaErrors = false;
    temporada.posiciones.map((posicion, index) => {
      const id = parseFloat(posicion.id);
      if (isNaN(id) || id <= 0) {
        hasLicenciaErrors = true;
        setValidationErrors((prevState) => {
          let newObjet = {
            ...prevState,
          };
          newObjet.licencia[index] = true;
          return {
            ...newObjet,
          };
        });
      } else {
        setValidationErrors((prevState) => {
          let newObjet = {
            ...prevState,
          };
          newObjet.licencia[index] = false;
          return {
            ...newObjet,
          };
        });
        hasLicenciaErrors = false;
      }
    });

    if (hasLicenciaErrors) {
      toast.error("Todos los datos de licencia son obligatorios");
    }

    if (handleRequired()) {
      toast.error("Todos los datos son obligatorios!");
      return;
    }

    if (temporada.idModalidadFk === 0) {
      setHasError({ ...hasError, modalidad: true });
      toast.error("Falta modalidad!", {
        autoClose: 2000,
      });
      return;
    } else {
      setHasError({ ...hasError, modalidad: false });
    }
    if (temporada.idTipoCompetenciaFk === 0) {
      setHasError({ ...hasError, tipoCompetencia: true });
      toast.error("Falta tipo de competencia!", {
        autoClose: 2000,
      });
      return;
    } else {
      setHasError({ ...hasError, tipoCompetencia: false });
    }

    try {
      const Historial = {
        id: newData.id,
        temporada: `${temporada.desde}/${temporada.hasta}`,
        idModalidadFK: temporada.idModalidadFk,
        piloto1: temporada.posiciones[0].id,
        piloto2: temporada.posiciones[1].id,
        piloto3: temporada.posiciones[2].id,
        info: temporada.track,
        pdf: temporada.pdf === "null" ? null : temporada.pdf,
        nombrePdf: fileNameRules || temporada.nombrePdf,
      };
      const result = await modificarHistorial({ nuevoHistorial: Historial });

      if (result.error) {
        throw new Error(result.error.message);
      }

      handleCancelar();
      setTemporada(historial);
      toast.success("Historial actualizado con éxito!", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success1",
      });
      window.location.reload();
      setReload((prevReload) => !prevReload);
    } catch (error) {
      toast.error("Error Actualizando Historial!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.log(error);
    }
  };

  const [borrarPdfHistorialById] = useBorrarPdfHistorialByIdMutation();

  const handleDeletePdf = async () => {
    try {
      const result = borrarPdfHistorialById(newData.id);
      if (result.error) throw new Error(result.error);
      setTemporada({ ...temporada, pdf: "", nombrePdf: "" });
      toast.success("Pdf eliminado con éxito!", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success1",
      });
    } catch (error) {
      toast.error("Error Borrando Pdf!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.log(error);
    }
  };

  const [borrarHisotirialById] = useBorrarHistorialByIdMutation();

  const handleDelete = async () => {
    openConfirm(
      "Confirmación de Borrado",
      "¿Eliminar este historial?",
      "Eliminar",
      async () => {
        try {
          const result = await borrarHisotirialById(historial.id);
          if (result.error) throw new Error(result.error);
          handleCancelar();
          toast.success("Historial eliminado con éxito!", {
            position: toast.POSITION.TOP_CENTER,
            toastId: "success1",
          });
          window.location.reload();
          setReload((prevReload) => !prevReload);
        } catch (error) {
          toast.error("Error Borrando Historial!", {
            position: toast.POSITION.TOP_CENTER,
          });
          console.log(error);
        }
      }
    );
  };

  const handleCancelar = () => {
    setIsEditable(false);
    setFileNameRules("");
    setIsDelete(false);
    onClose();
    setTemporada(historial);
    setValidationErrors({
      posiciones: false,
      desde: false,
      hasta: false,
      licencia: [false, false, false],
    });
  };

  return (
    <React.Fragment>
      <Box key={historial.id} style={{ width: "100%" }}>
        <Box>
          <form ref={formRef}>
            <Grid container sx={{ justifyContent: "space-between" }}>
              {isEditable ? (
                <Grid item xs={5} md={9}></Grid>
              ) : (
                <Grid item xs={5} md={9}></Grid>
              )}
              {Origen === "auth" && (
                <Box xs={5} md={3}>
                  <IconButton
                    disabled={isDelete}
                    onClick={() => {
                      setIsEditable(!isEditable);
                    }}
                    color="primary"
                    aria-label="add to shopping cart"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    disabled={isEditable}
                    onClick={() => {
                      setIsDelete(!isDelete);
                    }}
                    color="primary"
                    aria-label="add to shopping cart"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Grid>
            <Divider className="DividerMC" />
            {isEditable ? (
              <Box container className="GridPpalCN">
                <Box>
                  <Box>
                    <Grid>
                      {temporada.posiciones?.map((posicion, indice) => (
                        <Fragment key={indice}>
                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid
                              item
                              xs={12}
                              sm={1}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                              >
                                {indice + 1}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={2} className="GridItemCN">
                              <TextField
                                sx={{ mx: { xs: 0, sm: 1 } }}
                                label="Licencia"
                                name="licencia"
                                value={posicion.id}
                                onBlur={(e) => handleBlurLicencia(e, indice)}
                                onChange={(e) =>
                                  handleChangeLicencia(e, indice)
                                }
                                required
                                fullWidth
                                error={validationErrors.licencia[indice]}
                                helperText={
                                  validationErrors.licencia[indice] &&
                                  "Este campo es requerido"
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={2} className="GridItemCN">
                              <TextField
                                sx={{ mx: { xs: 0, sm: 1 } }}
                                label="Nombre y Apellido"
                                name="nombre"
                                value={posicion.nombre || ""}
                                onChange={(e, index) =>
                                  actualizarPosicion(e, index)
                                }
                                required
                                fullWidth
                                disabled
                                error={validationErrors.nombre}
                                helperText={
                                  validationErrors.nombre &&
                                  "Este campo es requerido"
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={2} className="GridItemCN">
                              <TextField
                                sx={{ mx: { xs: 0, sm: 1 } }}
                                label="Ciudad"
                                name="ciudad"
                                value={posicion.ciudad || ""}
                                onChange={actualizarPosicion}
                                disabled
                                required
                                fullWidth
                                error={validationErrors.ciudad}
                                helperText={
                                  validationErrors.ciudad &&
                                  "Este campo es requerido"
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={2} className="GridItemCN">
                              <TextField
                                sx={{ mx: { xs: 0, sm: 1 } }}
                                label="Provincia"
                                name="provincia"
                                value={posicion.provincia || ""}
                                onChange={actualizarPosicion}
                                disabled
                                required
                                fullWidth
                                error={validationErrors.provincia}
                                helperText={
                                  validationErrors.provincia &&
                                  "Este campo es requerido"
                                }
                              />
                            </Grid>
                          </Grid>
                        </Fragment>
                      ))}
                      <Divider className="DividerCN" />
                      <Grid item className="GridItemCN">
                        <Grid item xs={12} className="GridItemCN">
                          <TextField
                            sx={{ mx: 1 }}
                            label="Año desde"
                            type="number"
                            name="desde"
                            value={temporada.desde}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{
                              min: 1000,
                              max: 2999,
                              step: 1,
                            }}
                            required
                            fullWidth
                            error={validationErrors.desde}
                            helperText={
                              validationErrors.desde &&
                              "Este campo es requerido y debe tener formato de año"
                            }
                          />
                        </Grid>
                        <Grid item xs={12} className="GridItemCN">
                          <TextField
                            sx={{ mx: 1 }}
                            label="Año hasta"
                            type="number"
                            name="hasta"
                            value={temporada.hasta}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{
                              min: 1000,
                              max: 2999,
                              step: 1,
                            }}
                            required
                            fullWidth
                            error={validationErrors.hasta}
                            helperText={
                              validationErrors.hasta &&
                              "Este campo es requerido, debe tener formato de año y mayor que el año desde"
                            }
                          />
                        </Grid>
                      </Grid>
                      <Divider className="DividerCN" />
                      <Grid item className="GridItemCN">
                        <Grid item xs={12} className="GridItemCN">
                          {resModalidades ? (
                            <FormControl
                              fullWidth
                              sx={{ textAlign: "center" }}
                              error={hasError.modalidad}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Modalidad
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="idModalidadFk"
                                value={temporada.idModalidadFk}
                                label="Modalidad"
                                onChange={handleChange}
                                required
                              >
                                {dataModalidades?.map((modalidad) => (
                                  <MenuItem
                                    key={modalidad.id}
                                    value={modalidad.id}
                                  >
                                    {modalidad.nombre}
                                  </MenuItem>
                                ))}
                              </Select>
                              {hasError.modalidad && (
                                <FormHelperText
                                  sx={{ color: "#D32F2F", textAlign: "center" }}
                                >
                                  Campo requerido!
                                </FormHelperText>
                              )}
                            </FormControl>
                          ) : isLoadingModalidad ? (
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={12}
                                md={12}
                                style={{
                                  width: "50%",
                                  height: "50%",
                                  textAlign: "center",
                                }}
                              >
                                <CircularProgress
                                  style={{ width: "30px", height: "30px" }}
                                />
                                <Typography
                                  style={{
                                    color: "rgb(0, 150, 215)",
                                    fontSize: "15px",
                                    marginTop: "10px",
                                  }}
                                >
                                  Cargando Modalidad
                                </Typography>
                              </Grid>
                            </Grid>
                          ) : (
                            <Typography className="Titulo">
                              {" No hay Historial para mostrar."}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} className="GridItemCN">
                          <TextField
                            sx={{ mx: 1 }}
                            label="Link"
                            name="track"
                            value={
                              temporada.track !== null &&
                              temporada.track !== "null"
                                ? temporada.track
                                : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            fullWidth
                            error={validationErrors.track}
                            helperText={
                              validationErrors.track &&
                              "Este campo es requerido"
                            }
                          />
                        </Grid>
                      </Grid>
                      <Divider className="DividerCN" />
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <TextField
                            label="Archivo .pdf, .doc, .docx"
                            name="nombrePdf"
                            value={
                              fileNameRules
                                ? fileNameRules
                                : temporada.nombrePdf
                            }
                            variant="outlined"
                            autoComplete="off"
                            onClick={handleOpenFileDialog}
                            InputProps={{ readOnly: true }}
                            sx={{ flexGrow: 1, mr: 1 }}
                            required
                            fullWidth
                          />
                          {temporada.pdf !== null &&
                            temporada.pdf !== "null" && (
                              <IconButton onClick={handleDeletePdf}>
                                <DeleteIcon />
                              </IconButton>
                            )}
                          <HiddenInput
                            name="nombrePdf"
                            type="file"
                            ref={hiddenInputRef}
                            accept=".pdf, .doc, .docx"
                            onChange={handleReglaChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box className="ContenidoN" style={{ background: "#ffffff" }}>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ width: "100%" }}>
                    <Grid container className="GridPpalCN">
                      {isDelete && (
                        <Grid item>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              pb: 5,
                            }}
                          >
                            ¿Desea Eliminar este Historial?
                          </Typography>
                        </Grid>
                      )}
                      {<TableHistorial historial={historial} />}
                      <Divider className="DividerCN" />
                      <Grid item className="GridItemCN">
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={12} md={6}>
                            <Typography
                              fullWidth
                              sx={{ textAlign: "center" }}
                              className="Titulo"
                            >
                              Año desde
                            </Typography>
                            <Typography sx={{ mx: 1, textAlign: "center" }}>
                              {temporada.desde}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={12} md={6}>
                            <Typography
                              fullWidth
                              sx={{ textAlign: "center" }}
                              className="Titulo"
                            >
                              Año hasta
                            </Typography>
                            <Typography sx={{ mx: 1, textAlign: "center" }}>
                              {temporada.hasta}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider className="DividerCN" />
                      <Grid item className="GridItemCN">
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid>
                            <Typography
                              fullWidth
                              sx={{ textAlign: "center" }}
                              className="Titulo"
                            >
                              PDF:
                            </Typography>
                            {temporada.pdf === "null" ||
                            temporada.pdf === null ? (
                              <Typography style={{ marginLeft: "10px" }}>
                                <FileDownloadOffIcon color="primary" />
                              </Typography>
                            ) : (
                              <IconButton
                                fullWidth
                                sx={{ textAlign: "center" }}
                                style={{ marginLeft: "5px" }}
                                component="a"
                                target="_blank"
                                href={`${API_BASE_URL}/images/${temporada.pdf}`}
                                download={`${temporada.nombrePdf}`}
                              >
                                <GetAppIcon color="primary" />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid>
                            <Typography
                              fullWidth
                              sx={{ textAlign: "center" }}
                              className="Titulo"
                            >
                              Link
                            </Typography>
                            {temporada.track === "null" ||
                            temporada.track === null ? (
                              <Typography
                                sx={{
                                  mx: 1,
                                  textAlign: "center",
                                  color: "black",
                                  textDecoration: "none",
                                  fontWeight: "bold",
                                }}
                              >
                                <HorizontalRuleIcon color="primary" />
                              </Typography>
                            ) : (
                              <Typography
                                style={{ marginLeft: "5px" }}
                                component="a"
                                target="_blank"
                                fullWidth
                                sx={{ textAlign: "center" }}
                                href={
                                  temporada.track.startsWith("http")
                                    ? temporada.track
                                    : `http://${temporada.track}`
                                }
                              >
                                <InfoIcon color="primary" />
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider className="DividerCN" />
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={12} md={6}>
                          <Typography
                            fullWidth
                            sx={{ textAlign: "center" }}
                            className="Titulo"
                          >
                            Modalidad
                          </Typography>
                          <Typography fullWidth sx={{ textAlign: "center" }}>
                            {temporada.modalidadNombre}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            )}
            <Divider className="DividerMC" />
            <Grid className="gridBotonesMC">
              {!isEditable ? (
                isDelete ? (
                  <>
                    <Grid container sx={{ justifyContent: "end" }}>
                      <Button onClick={handleDelete} className="BotonBody">
                        Eliminar
                      </Button>
                      <Button onClick={handleCancelar} className="BotonBody">
                        Cancelar
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <></>
                )
              ) : (
                <Grid container sx={{ justifyContent: "end" }}>
                  <LoadingButton
                    loading={isLoadingHistorial}
                    onClick={handleEdit}
                    className="BotonBody"
                  >
                    Guardar
                  </LoadingButton>
                  <Button onClick={handleCancelar} className="BotonBody">
                    Cancelar
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </Box>

        <ModalConfirmacion />
      </Box>
    </React.Fragment>
  );
};

export default ModalHistorial;
