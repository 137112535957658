import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import BarraNavegacion from "../../../../../components/BarraNavegacion";
import PieDePagina from "../../../../../components/PieDePagina";
import { useAgregarTipoCompetenciaMutation } from "../../../../../redux/services/tipoCompetencia";

const CrearTipoCompetencia = ({ sinmenu }) => {
  const [validationErrors, setValidationErrors] = React.useState({
    nombre: false,
    modificador: false,
  });

  const initialState = {
    nombre: "",
    modificador: 0,
    activo: true,
  };

  const [tipoCompetencia, setTipoCompetencia] = React.useState(initialState);

  const handleBlur = (e) => {
    const { name, value } = e.target;

    if (name === "modificador")
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        // eslint-disable-next-line eqeqeq
        [name]: value == 0 || isNaN(value),
      }));
    else
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === "",
      }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setTipoCompetencia({
      ...tipoCompetencia,
      [name]: value,
    });

    if (name === "modificador")
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        // eslint-disable-next-line eqeqeq
        [name]: value == 0 || isNaN(value),
      }));
    else
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === "",
      }));
  };

  const handleRequired = () => {
    const errors = {
      nombre: tipoCompetencia.nombre.trim() === "",
      modificador:
        tipoCompetencia.modificador === 0 || isNaN(tipoCompetencia.modificador),
    };

    setValidationErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      console.log("No se puede guardar debido a errores de validación");
      return true;
    }

    return false;
  };

  const [agregarTipoCompetencia, error] = useAgregarTipoCompetenciaMutation();

  const handlePublicar = async (e) => {
    e.preventDefault();

    if (handleRequired()) {
      toast.error("Todos los datos son obligatorios!");
      return;
    }

    try {
      const modId = await agregarTipoCompetencia(tipoCompetencia);
      if (modId.error) throw new Error(error);
      setTipoCompetencia(initialState);
      toast.success("TipoCompetencia creada!", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success3",
      });
    } catch (error) {
      toast.error("Error creando la TipoCompetencia!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("Error creating post:", error);
    }
  };

  return (
    <React.Fragment>
      <Box>
        {!sinmenu && <BarraNavegacion />}
        <Box className="ContenidoPpalCN">
          <Grid container className="GridPpalCN">
            <Grid item className="GridItemCN">
              <Typography className="Titulo TituloPpalCN">
                Crear Tipo de Competencia
              </Typography>
            </Grid>
            <Divider className="DividerCN" />
            <Grid container sx={{ justifyContent: "space-between" }}>
              <Grid item xs={12} className="GridItemCN">
                <TextField
                  sx={{ mx: 1 }}
                  label="Nombre"
                  name="nombre"
                  value={tipoCompetencia.nombre}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  fullWidth
                  error={validationErrors.nombre}
                  helperText={
                    validationErrors.nombre && "Este campo es requerido"
                  }
                />
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid item className="GridItemCN">
              <Grid item xs={12} className="GridItemCN">
                <TextField
                  sx={{ mx: 1 }}
                  label="Multiplicador"
                  name="modificador"
                  value={tipoCompetencia.modificador}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  fullWidth
                  error={validationErrors.modificador}
                  helperText={
                    validationErrors.modificador && "Este campo es requerido"
                  }
                />
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid item className="GridBotonesCN">
              <Button className="BotonBody BotonCN" onClick={handlePublicar}>
                Publicar
              </Button>
            </Grid>
          </Grid>
        </Box>
        {!sinmenu && <PieDePagina />}
      </Box>
    </React.Fragment>
  );
};

export default CrearTipoCompetencia;
