import React, { useContext, useEffect, useState } from "react";
import "../../../../../components/Styles/Calendario.css";
import InfoIcon from "@mui/icons-material/Info";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MenuContext } from "../../../../../context/MenuContext";
import {
  useBorrarHtmlByIdMutation,
  useGetHtmlByIdQuery,
} from "../../../../../redux/services/resultados";
import LayoutResultadosPublicados from "../LayoutResultadosPublicos";
import ResultadosMangasPublicadas from "./ResultadosMangasPublicadas";

function VerResultadosPublicados(props) {
  const [htmlData, setHtmlData] = React.useState();
  const [mangas, setMangas] = useState([]);
  const { menuState } = useContext(MenuContext);
  const { state } = useLocation();
  const { competencia } = state;
  let navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const { data, isLoading } = useGetHtmlByIdQuery(
    competencia ? competencia.id : null
  );
  React.useEffect(() => {
    const fetchDataHtml = async () => {
      try {
        const result = await data;

        if (result && result.datosHTML) {
          setHtmlData(result.datosHTML);
        }
      } catch (error) {
        console.error("Error al obtener datosHTML:", error);
      }
    };

    fetchDataHtml();
  }, [data]);

  const [modalState, setModalState] = useState(false);

  const handleCloseModal = () => {
    setModalState(false);
  };
  const handleOpenModal = (t) => {
    setMangas(t);
    setModalState(true);
  };

  const [borrarHtmlById, error, isFetching] = useBorrarHtmlByIdMutation();

  useEffect(() => {
    if (!isFetching) handleClose();
  }, [isFetching]);

  return (
    <LayoutResultadosPublicados nombre="Resultados">
      <Box
        className={`${
          menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
        }`}
        style={{
          marginTop: "7%",
          width: "90%",
          maxWidth: menuState ? "calc(100vw -  20%)" : "100vw",
          overflowX: "auto",
        }}
      >
        <Grid container sx={{ height: "100%", width: "100%" }} spacing={2}>
          <div
            style={{
              marginTop: "2%",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography className="Titulo" sx={{ width: "100%" }}>
                {competencia?.titulo}
              </Typography>
              <Typography
                className="Subtitulo textoPequeñoCal"
                style={{ fontSize: 16 }}
              >
                Fin: {dayjs(competencia?.fechaFin).format("DD-MM-YYYY")}
              </Typography>
            </div>
          </div>
          <Box style={{ width: "100%", marginTop: "1%" }}>
            {!isLoading ? (
              htmlData ? (
                <Table
                  sx={{
                    width: { xs: "100%", md: "auto" },
                    overflow: "auto",
                    maxWidth: "100vw",
                    overflowX: "auto",
                  }}
                >
                  <TableHead
                    style={{
                      width: "100%",
                      alignContent: "center",
                      alignSelf: "center",
                      height: "auto",
                    }}
                  >
                    <TableRow>
                      <TableCell>
                        <Typography variant="body1" className="Titulo">
                          Posición
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" className="Titulo">
                          Id
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" className="Titulo">
                          Nombre
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" className="Titulo">
                          Nacionalidad
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" className="Titulo">
                          Género
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" className="Titulo">
                          Glider
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" className="Titulo">
                          Auspiciantes
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" className="Titulo">
                          Total
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          className="Titulo"
                        ></Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {htmlData.map((item, index) => (
                    <TableBody
                      key={index}
                      style={{
                        width: "98%",
                        alignContent: "center",
                        alignSelf: "center",
                        height: "auto",
                      }}
                    >
                      <TableRow>
                        <TableCell>
                          <Typography variant="body2" className="body">
                            {item.posicion}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" className="body">
                            {item.idCompetidor}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" className="body">
                            {item.nombre}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" className="body">
                            {item.nacionalidad}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" className="body">
                            {item.genero}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" className="body">
                            {item.glider}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" className="body">
                            {item.sponsor}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" className="body">
                            {item.total}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ width: "5%" }}>
                          <Typography
                            onClick={() => handleOpenModal(item.t)}
                            variant="body1"
                            className="Titulo"
                          >
                            <InfoIcon color="primary" />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
                </Table>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography textAlign={"center"} className="Titulo">
                    No hay resultados para mostrar
                  </Typography>
                </div>
              )
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </Box>
        </Grid>
      </Box>
      <ResultadosMangasPublicadas
        modalState={modalState}
        closeModal={handleCloseModal}
        mangas={mangas}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <div
          id="div1"
          style={{
            p: 5,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress color="inherit" />
          <p style={{ textAlign: "center", fontSize: 10 }}>
            Eliminando Resultados...{" "}
          </p>
          <p style={{ textAlign: "center", color: "orange" }}>
            No actualice esta página
          </p>
        </div>
      </Backdrop>
    </LayoutResultadosPublicados>
  );
}

export default VerResultadosPublicados;
