import React, { useContext, useState } from "react";
import "../../Styles/ParapenteXC.css";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import NoticiasInicio from "../../../components/NoticiasInicio";
import { useGetListaNovedadesQuery } from "../../../redux/services/novedad";
import CrearNovedad from "../../CrearNovedad";
import { MenuContext } from "../../../context/MenuContext";

const Novedades = () => {
  const [novedades, setNovedades] = useState();
  const { menuState } = useContext(MenuContext);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const { data: dataNovedades, isLoading: isLoadingNovedades } =
    useGetListaNovedadesQuery();
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const datosFiltrados = dataNovedades?.listaNovedades
          ?.filter((dato) => {
            const aunmes = dayjs(dato.fechaPublicacion).add(60, "day");
            return dayjs(aunmes).isAfter(dayjs(), "date");
          })
          .map((dato) => ({
            ...dato,
            fechaPublicacion: dayjs(dato.fechaPublicacion).format("YYYY-MM-DD"),
          }))
          .filter((dato) => {
            return dato.activo !== false;
          })
          .sort((a, b) =>
            dayjs(b.fechaPublicacion).diff(dayjs(a.fechaPublicacion))
          );
        setNovedades(datosFiltrados);
      } catch (error) {
        console.error("Error al obtener los novedades:", error);
      }
    };

    fetchData();
  }, [dataNovedades]);

  const totalPages = Math.ceil(novedades?.length / pageSize);
  const paginatedNovedades = novedades?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <React.Fragment>
      <Box>
        <Box
          className={`${menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
            }`}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ flexDirection: "column" }}
              className="gridColumnaPXC"
            >
              {novedades ? (
                <Box className="boxCalendarioPXC">
                  <Typography className="Subtitulo">Calendario.</Typography>
                  <Typography className="Titulo" sx={{ marginBottom: "15px" }}>
                    Novedades:
                  </Typography>
                  {paginatedNovedades?.length === 0 ? (
                    <Typography
                      className="Titulo"
                      style={{ fontSize: 14, alignSelf: "center" }}
                    >
                      No hay novedades para mostrar.
                    </Typography>
                  ) : (
                    paginatedNovedades?.map((novedad) => (
                      <NoticiasInicio
                        key={novedad.id}
                        novedad={novedad}
                        origen={"auth"}
                      />
                    ))
                  )}
                </Box>
              ) : !isLoadingNovedades ? (
                <Typography className="Titulo">
                  {" No hay novedades para mostrar."}
                </Typography>
              ) : (
                <>
                  {" "}
                  <CircularProgress />
                </>
              )}
              {!isNaN(totalPages) && paginatedNovedades?.length > 0 && (
                <Box mt={2} display="flex" justifyContent="center">
                  <Button
                    variant="outlined"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    {"<<"}
                  </Button>
                  <Typography
                    variant="body1"
                    style={{ margin: "5px 10px 0px 10px" }}
                  >
                    Pag.{currentPage} /{totalPages}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    {">>"}
                  </Button>
                </Box>
              )}
              <Divider sx={{ width: "100%", m: 2 }} />
              <Box style={{ width: "100%" }} className="">
                <CrearNovedad sinmenu={true} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Novedades;
