import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authApi } from "./auth";
import { store } from "../store";
import { logout, setCredentials } from "../features/authSlice";
import { toast } from "react-toastify";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://localhost:5000";

const getAuthToken = () => {
  return localStorage.getItem("refreshToken");
};

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.accessToken;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!isRefreshing) {
      isRefreshing = true;
      const refresh = getAuthToken();

      if (refresh) {
        try {
          const refreshResult = await api.dispatch(
            authApi.endpoints.refreshToken.initiate({ refreshToken: refresh })
          );

          if (refreshResult.data.respuestaToken !== null) {
            const newToken = refreshResult.data.respuestaToken.accessToken;
            const newRefreshToken = refreshResult.data.respuestaToken.refreshToken;

            const userData = {
              respuestaToken: {
                accessToken: newToken,
                refreshToken: newRefreshToken,
              },
            };
            store.dispatch(setCredentials(userData));
            processQueue(null, newToken);

            result = await baseQuery(
              {
                ...args,
                headers: {
                  ...args.headers,
                  Authorization: `Bearer ${newToken}`,
                },
              },
              api,
              extraOptions
            );
          } else {
            processQueue("Token refresh failed", null);
            store.dispatch(logout());
            toast.error(
              "Lo sentimos, tu sesión ha expirado debido a inactividad. Por favor, vuelve a iniciar sesión para continuar.",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          }
        } catch (error) {
          processQueue(error, null);
          store.dispatch(logout());
          toast.error(
            "Error al intentar refrescar el token. Por favor, vuelve a iniciar sesión.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        } finally {
          isRefreshing = false;
        }
      } else {
        store.dispatch(logout());
        toast.error(
          "No se encontró un token de refresco. Por favor, vuelve a iniciar sesión.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    } else {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
      .then(token => {
        result = baseQuery(
          {
            ...args,
            headers: {
              ...args.headers,
              Authorization: `Bearer ${token}`,
            },
          },
          api,
          extraOptions
        );
        return result;
      })
      .catch(err => {
        return result;
      });
    }
  }

  return result;
};

export const recordApi = createApi({
  reducerPath: "recordApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Records"],
  endpoints: (builder) => ({
    getListaRecords: builder.query({
      query: (skip = false) => {
        if (skip) {
          return { data: null };
        }
        return {
          url: "buscar-lista-records",
          method: "GET",
        };
      },
      providesTags: ["Records"],
    }),
    agregarRecord: builder.mutation({
      query: (nuevoRecord) => {
        const formData = new FormData();
        Object.keys(nuevoRecord).forEach((key) => {
          formData.append(String(key), nuevoRecord[key]);
        });
        return {
          url: `agregar-record`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Records"],
    }),
    modificarRecord: builder.mutation({
      query: (nuevoRecord) => {
        const formData = new FormData();
        Object.keys(nuevoRecord).forEach((key) => {
          formData.append(String(key), nuevoRecord[key]);
        });
        return {
          url: `modificar-record`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Records"],
    }),
    borrarRecordById: builder.mutation({
      query: (id) => ({
        url: `borrar-record?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Records"],
    }),
    getRecordById: builder.query({
      query: (id) => `buscar-record-individual?id=${id}`,
    }),
    traerRecordByFiltro: builder.mutation({
      query: (filtroRecord, skip = false) => {
        if (!filtroRecord || skip) {
          return { data: null };
        }

        let urlSegments = [];

        if (filtroRecord.estado !== " ") {
          urlSegments.push(`estado=${filtroRecord.estado}`);
        }

        if (filtroRecord.territorio !== " ") {
          urlSegments.push(`territorio=${filtroRecord.territorio}`);
        }

        if (filtroRecord.tipo !== " ") {
          urlSegments.push(`tipo=${filtroRecord.tipo}`);
        }

        if (filtroRecord.idCategoria !== 0) {
          urlSegments.push(`idCategoria=${filtroRecord.idCategoria}`);
        }

        const baseUrl = "buscar-record-filtro";
        const queryString =
          urlSegments.length > 0 ? `?${urlSegments.join("&")}` : "";

        const finalUrl = `${baseUrl}${queryString}`;

        return {
          url: finalUrl,
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useGetListaRecordsQuery,
  useAgregarRecordMutation,
  useModificarRecordMutation,
  useGetRecordByIdQuery,
  useTraerRecordByFiltroMutation,
  useBorrarRecordByIdMutation,
} = recordApi;
