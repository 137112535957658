import { createContext, useState, } from 'react';

export const MenuContext = createContext(null);

export default function MenuContextProvider({ children }) {
    const [menuState, setMenuState] = useState(true);


    return (
        <MenuContext.Provider value={{ menuState, setMenuState }}>
            {children}
        </MenuContext.Provider>
    )
}

