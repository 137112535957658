import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Styles/Novedad.css";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import useConfirmationModal from "../hooks/useConfirmationModal ";
import {
  useGetListaModalidadesSelectQuery,
  useGetModalidadByIdQuery,
} from "../redux/services/modalidad";
import {
  useBorrarNovedadByIdMutation,
  useGetImagenesByNovedadQuery,
  useModificarNovedadMutation,
} from "../redux/services/novedad";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://localhost:5000";

function Novedad({
  ModalState,
  CloseModal,
  Novedad,
  Origen,
  imagenVistaPrevia,
  base64,
}) {
  const initialValues = {};
  const [newData, setNewData] = useState(Novedad);
  const { openConfirm, ModalConfirmacion } = useConfirmationModal();
  const [isEditable, setIsEditable] = useState(false);

  const {
    data: dataModalidad,
    isLoading,
    isError,
  } = useGetModalidadByIdQuery(newData.idModalidadFk);
  const [modalidad, setModalidad] = useState("");

  const desarrolloNovedadEditRef = useRef(null);

  React.useEffect(() => {
    const fetchModalidad = async () => {
      try {
        const result = await dataModalidad;

        if (result && result.modalidadIndividual) {
          setModalidad(result.modalidadIndividual.nombre);
        }
      } catch (error) {
        console.error("Error al obtener la modalidad:", error);
      }
    };

    fetchModalidad();
  }, [dataModalidad]);

  React.useEffect(() => {
    if (isError) {
      setModalidad("Cargando modalidad...");
    }
  }, [isError]);

  React.useEffect(() => {
    if (isLoading) {
      setModalidad("Cargando modalidad...");
    }
  }, [isLoading]);

  React.useEffect(() => {
    if (Novedad) {
      setNewData({
        ...Novedad,
        fechaPublicacion: dayjs(Novedad?.fechaPublicacion, {
          format: "YYYY-MM-DD",
        }),
      });
    } else {
      setNewData(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Novedad]);

  const BoxConFondo = {
    backgroundImage: `url(${API_BASE_URL}/images/${Novedad?.imagen})`,
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    textAlign: "center",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  };
  const [borrarNovedadById, { error, isFetching }] =
    useBorrarNovedadByIdMutation();

  const handleDelete = async () => {
    openConfirm(
      "Confirmación de Borrado",
      "¿Eliminar esta novedad publicada?",
      "Eliminar",
      async () => {
        try {
          const result = await borrarNovedadById(Novedad.id);
          if (result.error) throw new Error(result.error);
          toast.success("Novedad eliminada con Éxito!", {
            position: toast.POSITION.TOP_CENTER,
            toastId: "success3",
          });
        } catch (error) {
          console.log(error);
          toast.error("Error actualizando novedad!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
    );
  };

  const [validationErrors, setValidationErrors] = useState({
    titulo: false,
    desarrollo: false,
    autor: false,
  });

  const [errorFecha, setErrorFecha] = React.useState(null);

  const errorMessageFecha = React.useMemo(() => {
    switch (errorFecha) {
      case "disablePast": {
        return "Por favor seleccione una fecha vigente";
      }
      case "invalidDate": {
        return "Fecha inválida";
      }
      default: {
        return "";
      }
    }
  }, [errorFecha]);

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === "idModalidadFk" || name === "asunto") return;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === "",
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData({
      ...newData,
      [name]: value,
    });

    if (name === "idModalidadFk" || name === "asunto") return;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === "",
    }));
  };

  const [modificarNovedad] = useModificarNovedadMutation();
  const [isUpdating, setIsUpdating] = useState(false);
  const handleEdit = async () => {
    setNewData({ ...newData, id: newData.id });
    setNewData({
      ...newData,
      fechaPublicacion: dayjs(newData?.fechaPublicacion, {
        format: "YYYY-MM-DD",
      }),
    });

    if (newData.titulo?.trim() === "")
      setValidationErrors({ ...validationErrors, titulo: true });
    if (validationErrors.titulo || newData.titulo?.trim() === "") {
      toast.error("Campo título es obligatorio!");
      return;
    }
    if (newData.autor?.trim() === "")
      setValidationErrors({ ...validationErrors, autor: true });
    if (validationErrors.autor || newData.autor?.trim() === "") {
      toast.error("Campo autor es obligatorio!");
      return;
    }
    if (newData.desarrollo?.trim() === "")
      setValidationErrors({ ...validationErrors, desarrollo: true });
    if (validationErrors.desarrollo || newData.desarrollo?.trim() === "") {
      toast.error("Campo desarrollo es obligatorio!");
      return;
    }

    // let updatedData = { ...newData };
    let updatedData = {
      id: newData.id,
      titulo: newData.titulo,
      imagen: newData.imagen,
      asunto: newData.asunto,
      desarrollo: newData.desarrollo,
      autor: newData.autor,
      activo: true,
      idModalidadFk: newData.idModalidadFk,
    };


    if (typeof updatedData.imagen === "string") {
      updatedData.imagen = null;
    }

    try {
      const compeId = await modificarNovedad(updatedData);
      if (compeId.error) throw new Error(error);
      toast.success("¡Novedad actualizada con éxito!", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success2",
      });
    } catch (error) {
      toast.error("¡Error!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.log(error);
    }
    setIsEditable(false);
    setIsUpdating(false);
  };

  const handleCancelar = () => {
    CloseModal();
    setIsEditable(false);
  };

  const [dataModalidades, setDataModalidades] = useState();
  const { data: resModalidades, isLoading: isLoadingModalidades } =
    useGetListaModalidadesSelectQuery();
  React.useEffect(() => {
    try {
      setDataModalidades(resModalidades?.listarModalidades);
    } catch (error) {
      console.log(error);
    }
    return () => {
      setDataModalidades();
    };
  }, [resModalidades]);

  const [newImagenVistaPrevia, setNewImagenVistaPrevia] = useState("");
  const handleImageChange2 = (e) => {
    const file2 = e.target.files[0];

    if (file2) {
      setNewData({
        ...newData,
        imagen: file2,
      });
      const readerModalNovedad = new FileReader();
      readerModalNovedad.onload = (event) => {
        setNewImagenVistaPrevia(event.target.result);
      };
      readerModalNovedad.readAsDataURL(file2);
    }
  };

  const handleChangeDesarrollo = useCallback(
    (e) => {
      const { name, value } = e.target;
      setNewData({
        ...newData,
        [name]: value,
      });

      if (isNaN(value)) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: value.trim() === "",
        }));
      }
    },
    [setNewData]
  );

  React.useEffect(() => {
    const initEditor = () => {
      if (desarrolloNovedadEditRef.current) {
        window.tinymce?.init({
          selector: "#desarrolloNovedadEdit",
          menubar: false,
          min_height: 250,
          max_height: 250,
          width: "100%",
          plugins: ["paste", "link"],
          toolbar:
            "undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | link",
          branding: false,
          setup: (editor) => {
            editor.on("change", () => {
              const content = editor.getContent();
              handleChangeDesarrollo({
                target: { name: "desarrollo", value: content },
              });
            });
          },
        });
      }
    };

    initEditor();

    return () => {
      if (window.tinymce?.get(desarrolloNovedadEditRef.current?.id)) {
        window.tinymce.get(desarrolloNovedadEditRef.current?.id).remove();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditable]);

  function FormattedInput({ value }) {
    return (
      <Typography
        component="div"
        variant="body1"
        dangerouslySetInnerHTML={{ __html: value }}
      />
    );
  }
  const { data: dataImagenes } = useGetImagenesByNovedadQuery(
    Novedad.id ? Novedad.id : null
  );

  const [imagePreviews, setImagePreviews] = useState([]);
  useEffect(() => {
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };
    const loadPreviews = async () => {
      const imgs = [];
      for (const file of base64) {
        const base64 = await getBase64(file);
        imgs.push(base64);
      }
      setImagePreviews(imgs);
    };

    if (base64 && base64.length > 0) {
      loadPreviews();
    }
  }, [base64]);

  const isMobile = window.innerWidth <= 768;

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <React.Fragment>
      <Modal
        open={ModalState}
        onClose={handleCancelar}
        className="ModalN"
        sx={{}}
      >
        {!isEditable ? (
          <Box className="ContenedorN" style={{ marginTop: "50px" }}>
            <Box className="ContSecundarioN">
              <Box sx={BoxConFondo}>
                <div className="SombraN">
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        className="TituloN"
                        variant="h1"
                        style={{ fontSize: 50 }}
                      >
                        {newData.titulo}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {newData.asunto &&
                        (newData.asunto !== "null" ||
                          newData.asunto !== "undefined") &&
                        (newData.asunto.startsWith("http://") ||
                          newData.asunto.startsWith("https://") ||
                          newData.asunto.startsWith("www.")) ? (
                        <Box
                          component="a"
                          href={
                            newData.asunto.startsWith("http://") ||
                              newData.asunto.startsWith("https://")
                              ? newData.asunto
                              : "https://" + newData.asunto
                          }
                          target="_blank"
                          className="SubtituloN"
                          sx={{ color: "#fff" }}
                          style={{ textTransform: "lowercase" }}
                        >
                          {newData.asunto ||
                            newData.asunto.toLowerCase() !== "null"
                            ? newData.asunto
                            : ""}
                        </Box>
                      ) : (
                        <Typography
                          sx={{
                            px: 5,
                            WebkitBoxOrient: "vertical",
                            lineHeight: 2,
                          }}
                          gutterBottom
                          variant="body2"
                          style={{ textTransform: "uppercase" }}
                        >
                          {newData.asunto &&
                            (newData.asunto.toLowerCase().trim() === "null" ||
                              newData.asunto.toLowerCase().trim() === "undefined"
                              ? ""
                              : newData.asunto)}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  {Origen === "auth" ? (
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        pb: 5,
                        gap: 2,
                        pr: 5,
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton
                        aria-label="edit "
                        onClick={() => {
                          setIsEditable(!isEditable);
                        }}
                        color="primary"
                      >
                        <EditIcon sx={{ fontSize: "1.5em", mb: 0 }} />
                      </IconButton>

                      <IconButton
                        loading={isFetching}
                        aria-label="delete"
                        onClick={() => {
                          handleDelete();
                        }}
                        color="primary"
                      >
                        <DeleteIcon sx={{ fontSize: "1.5em", mb: 0 }} />
                      </IconButton>
                      <ModalConfirmacion />
                    </Box>
                  )
                    : (<Box sx={{ pb: 9 }}>&nbsp;</Box>)}
                </div>
              </Box>

              <Box className="ContenidoN">
                <Grid container className="GridContenedorN">
                  <Grid item>
                    {newData.desarrollo?.startsWith("<") ? (
                      <Grid item>
                        <TextField
                          InputProps={{
                            disableUnderline: true,
                            readOnly: true,
                            inputComponent: FormattedInput,
                            value: newData.desarrollo,
                          }}
                          style={{ height: "100%" }}
                          label="Desarrollo"
                          name="desarrollo"
                          value={newData.desarrollo}
                          fullWidth
                          required
                          multiline
                          rows={9}
                          error={validationErrors.desarrollo}
                          helperText={
                            validationErrors.desarrollo &&
                            "Este campo es requerido"
                          }
                        />
                      </Grid>
                    ) : (
                      <Grid item>
                        <TextField
                          InputProps={{
                            value: newData.desarrollo,
                          }}
                          required
                          multiline
                          rows={9}
                          label="Desarrollo"
                          aria-label="desarrollo"
                          className="Subtitulo"
                          disabled
                          defaultValue={newData.desarrollo}
                          value={newData.desarrollo}
                          name="desarrollo"
                          error={validationErrors.desarrollo}
                          helperText={
                            validationErrors.desarrollo &&
                            "Este campo es requerido"
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                  <>
                    {dataImagenes && dataImagenes?.listaImagenes.length > 0 ? (
                      <>
                        <Divider className="DividerN" />
                        <Grid item xs={12}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: isMobile ? "column" : "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {dataImagenes?.listaImagenes?.map((item, index) => (
                              <div key={index} style={{ width: "100%" }}>
                                <img
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    margin: 5,
                                  }}
                                  src={`${API_BASE_URL}/images/${item}`}
                                  alt={`Imagenes ${index}`}
                                  onClick={() =>
                                    handleImageClick(
                                      `${API_BASE_URL}/images/${item}`
                                    )
                                  }
                                />
                              </div>
                            ))}
                          </div>
                        </Grid>
                        <Divider className="DividerN" />
                      </>
                    ) : (
                      <>
                        <Divider className="DividerN" />
                        <Grid item>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: isMobile ? "column" : "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {imagePreviews?.map((item, index) => (
                              <div key={index} className="imageContainer">
                                <img
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    margin: 5,
                                  }}
                                  src={item}
                                  alt={index}
                                  onClick={() => handleImageClick(item)}
                                />
                              </div>
                            ))}
                          </div>
                        </Grid>
                        <Divider className="DividerN" />
                      </>
                    )}
                    <Dialog open={open} onClose={handleClose}>
                      <DialogContent>
                        {selectedImage && (
                          <img
                            src={selectedImage}
                            alt="Imagen seleccionada"
                            style={{ width: "100%" }}
                          />
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          Cerrar
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                  <Grid className="GridPieN">
                    <Typography className="Texto">
                      {" "}
                      Autor: <b> {newData.autor} </b>{" "}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography className="Texto">
                      {" "}
                      Modalidad:{" "}
                      <b>
                        {" "}
                        {newData.idModalidadFk} - {modalidad}{" "}
                      </b>{" "}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography className="Texto" sx={{ textAlign: "right" }}>
                      {" "}
                      {dayjs(newData.fechaPublicacion).format(
                        "DD-MM-YYYY"
                      )}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            <Box className="ContenedorN" style={{ marginTop: "50px" }}>
              <Box className="ContSecundarioN">
                <Box sx={BoxConFondo}>
                  <div className="SombraN">
                    <Grid
                      container
                      sx={{ my: 2, gap: 10, justifyContent: "center" }}
                    >
                      <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{ my: 2, gap: 15 }}
                        style={{ padding: "7px" }}
                      >
                        <TextField
                          inputProps={{
                            style: {
                              color: "#fff",
                              fontSize: "1.5em",
                              paddingTop: 5,
                            },
                          }}
                          sx={{
                            mt: 2,
                            width: "100%",
                            border: "1px solid #fff",
                            "& label": {
                              color: "#fff",
                            },
                          }}
                          required
                          multiline
                          label="Título"
                          aria-label="Título"
                          defaultValue={newData.titulo}
                          value={newData.titulo}
                          name="titulo"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={validationErrors.titulo}
                          helperText={
                            validationErrors.titulo && "Este campo es requerido"
                          }
                        />
                        <TextField
                          inputProps={{
                            style: {
                              color: "#fff",
                              fontSize: 15,
                              paddingTop: 5,
                            },
                          }}
                          sx={{
                            mt: 2,
                            width: "100%",
                            border: "1px solid #fff",
                            "& label": {
                              color: "#fff",
                            },
                          }}
                          required
                          multiline
                          label="Link"
                          aria-label="link"
                          className="SubtituloN"
                          value={
                            newData.asunto &&
                              (newData.asunto.toLowerCase() === "null" ||
                                newData.asunto.toLowerCase() === "undefined")
                              ? ""
                              : newData.asunto
                          }
                          name="asunto"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={5}
                        style={{
                          minHeight: 200,
                          backgroundColor: "#fff",
                          p: 2,
                          mb: 3,
                        }}
                      >
                        <Box
                          style={{ minHeight: 200, height: 200, width: "100%" }}
                        >
                          <Typography className="Texto">
                            Imagen Encabezado
                          </Typography>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange2}
                            style={{ display: "none", minHeight: 200 }}
                            id="imagenInputEdit"
                          />
                          {newImagenVistaPrevia ? (
                            <img
                              src={newImagenVistaPrevia}
                              alt={`Vista previa de la imagen`}
                              style={{ maxWidth: "20%", marginTop: "16px" }}
                            />
                          ) : newData.imagen &&
                            (newData.imagen !== null ||
                              newData?.imagen !== "null") ? (
                            <Box
                              style={{
                                width: "100%",
                                height: "40%",
                                display: "flex",
                                alignContent: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Box
                                style={{
                                  backgroundImage: `url(${API_BASE_URL}/images/${newData?.imagen})`,
                                  width: "30%",
                                  height: "80%",
                                  marginTop: "16px",
                                  backgroundSize: "fill",
                                  backgroundPosition: "center center",
                                }}
                              />
                            </Box>
                          ) : (
                            <div
                              style={{
                                marginTop: "10%",
                                backgroundColor: "transparent",
                              }}
                            />
                          )}
                          <Divider className="DividerCN" />
                          <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            <label htmlFor="imagenInputEdit">
                              <Button
                                variant="outlined"
                                component="span"
                                color="primary"
                                className="BotonBody BotonCN"
                                sx={{}}
                              >
                                Seleccionar
                              </Button>
                            </label>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </Box>
                <Grid
                  item
                  xs={12}
                  sx={{
                    my: 2,
                    gap: 2,
                    mt: 5,
                    width: "100%",
                    alignSelf: "center",
                  }}
                >
                  <Box
                    className="ContenidoN"
                    style={{ width: isMobile ? "90%" : "95%" }}
                  >
                    <Grid container className="GridContenedorN">
                      <TextField
                        inputRef={desarrolloNovedadEditRef}
                        InputProps={{
                          disableUnderline: true,
                          readOnly: false,
                        }}
                        style={{ height: 262, maxHeight: 262 }}
                        label="Desarrollo"
                        name="desarrollo"
                        value={newData.desarrollo}
                        defaultValue={newData.desarrollo}
                        onChange={handleChangeDesarrollo}
                        fullWidth
                        required
                        multiline
                        rows={9}
                        error={validationErrors.desarrollo}
                        helperText={
                          validationErrors.desarrollo &&
                          "Este campo es requerido"
                        }
                        id="desarrolloNovedadEdit"
                        variant="standard"
                      />
                    </Grid>

                    <Grid
                      container
                      sx={{ justifyContent: "space-between", gap: 2, my: 5 }}
                    >
                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          multiline
                          label="Autor"
                          aria-label="autor"
                          className="Subtitulo"
                          defaultValue={newData.autor}
                          value={newData.autor}
                          name="autor"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={validationErrors.autor}
                          helperText={
                            validationErrors.autor && "Este campo es requerido"
                          }
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box sx={{ minWidth: 120 }}>
                          {resModalidades ? (
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Modalidad
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="idModalidadFk"
                                value={newData.idModalidadFk}
                                defaultValue={newData.idModalidadFk}
                                label="Modalidad"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                              >
                                {isLoadingModalidades ? (
                                  <>
                                    <CircularProgress />
                                  </>
                                ) : (
                                  dataModalidades?.map((modalidad) => (
                                    <MenuItem value={modalidad.id}>
                                      {modalidad?.nombre}
                                    </MenuItem>
                                  ))
                                )}
                              </Select>
                            </FormControl>
                          ) : isLoadingModalidades ? (
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={12}
                                md={12}
                                style={{
                                  width: "50%",
                                  height: "50%",
                                  textAlign: "center",
                                }}
                              >
                                <CircularProgress
                                  style={{ width: "30px", height: "30px" }}
                                />
                                <Typography
                                  style={{
                                    color: "rgb(0, 150, 215)",
                                    fontSize: "15px",
                                    marginTop: "10px",
                                  }}
                                >
                                  Cargando Modalidad
                                </Typography>
                              </Grid>
                            </Grid>
                          ) : (
                            <Typography className="Titulo">
                              {" No hay Modalidad para mostrar."}
                            </Typography>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            sx={{ width: "100%" }}
                            value={
                              dayjs(newData?.fechaPublicacion, {
                                format: "YYYY-MM-DD",
                              }).isValid()
                                ? dayjs(newData?.fechaPublicacion, {
                                  format: "YYYY-MM-DD",
                                })
                                : null
                            }
                            label="Fecha Publicación"
                            name="fecha"
                            minDate={dayjs(newData?.fechaPublicacion, {
                              format: "YYYY-MM-DD",
                            })}
                            readOnly
                            inputFormat="DD/MM/YYYY"
                            format="DD/MM/YYYY"
                            onError={(newError) => setErrorFecha(newError)}
                            slotProps={{
                              textField: {
                                helperText: errorMessageFecha,
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>

                    <Divider className="DividerN" />
                    <Grid container sx={{ justifyContent: "end" }}>
                      <LoadingButton
                        loading={isUpdating}
                        onClick={handleEdit}
                        className="BotonBody"
                      >
                        Guardar
                      </LoadingButton>
                      <Button onClick={handleCancelar} className="BotonBody">
                        Cancelar
                      </Button>
                    </Grid>
                  </Box>
                </Grid>
              </Box>
            </Box>
          </>
        )}
      </Modal>
    </React.Fragment>
  );
}

export default Novedad;
