import React, { useContext, useEffect, useState } from "react";
import "./SeleccionPublicada.styles.css";
import PieDePagina from "../../../../components/PieDePagina";
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import MenuLateral from "../../../../components/MenuLateral";
import { MenuContext } from "../../../../context/MenuContext";
import { useGetCompetidorSeleccionModalidadQuery } from "../../../../redux/services/competidor";
import TablaSeleccionInicio from "../../../TablaSeleccionInicio";
import { useSelector } from "react-redux";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function SeleccionPublicada() {
  const { menuState } = useContext(MenuContext);
  const state = useSelector((state) => state.modalidad);

  const opcMenu = [
    {
      opcion: "Acerca de...",
      link: "/modalidad/Cnd",
      state,
    },
    {
      opcion: "Resultados",
      link: "/ModalidadCnd",
      state,
    },
    {
      opcion: "Reglamento",
      link: "/ReglamentoCnd",
      state,
    },
    {
      opcion: "Historial",
      link: "/Historial-Publico",
      state,
    },
    {
      opcion: "Records",
      link: "/RecordPublico",
      state,
    },
    {
      opcion: "Selección Argentina",
      link: "/SeleccionPublicada",
      state,
    },
  ];

  const opcMenuTemp = [
    {
      opcion: "Novedades",
      link: "/modalidad/*",
      state,
    },
    {
      opcion: "Reglamento",
      link: "/Reglamento",
      state,
    },
    {
      opcion: "Historial",
      link: "/Historial-Publico",
      state,
    },
    {
      opcion: "Resultados",
      link: "/ResultadosPublicados",
      state,
    },
    {
      opcion: "Ranking",
      link: "/Ranking",
      state,
    },
    {
      opcion: "Historial Ranking",
      link: "/HistorialRanking",
      state,
    },
    {
      opcion: "Selección Argentina",
      link: "/SeleccionPublicada",
      state,
    },
  ];

  const opcMenu2 = opcMenuTemp.filter(item => {
    if ((item.opcion === "Historial Ranking" || item.opcion === "Ranking") && state?.modalidad?.id !== 14) {
      return false;
    }
    return true;
  });

  const [seleccion, setSeleccion] = useState([]);
  const {
    data: dataSeleccion,
    isLoading: isLoadingSeleccion,
    refetch,
  } = useGetCompetidorSeleccionModalidadQuery(state?.modalidad.id);
  const [hasError, setHasError] = React.useState({
    modalidad: false,
  });

  useEffect(() => {
    refetch();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const filtrado = dataSeleccion?.listaCompetidorSeleccion;
        setSeleccion(filtrado);
      } catch (error) {
        console.error("Error al obtener los posts:", error);
      }
    };

    fetchData();
  }, [dataSeleccion]);

  return (
    <React.Fragment>
      <MenuLateral
        opcMenu={
          state?.modalidad.nombre.toLowerCase() === "cnd" ? opcMenu : opcMenu2
        }
        menuTitulo={state?.modalidad.nombre}
        typeMenu={false}
      />
      <Box
        className={`${menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
          }`}
      >
        <Grid container style={{ flexDirection: "row" }}>
          <Grid
            item
            xs={12}
            style={{ flexDirection: "column", marginTop: "0px" }}
            className="gridColumnaPXC"
          >
            {seleccion ? (
              <Box width={"100%"}>
                {seleccion?.length === 0 ? (
                  <Typography className="Titulo" style={{ margin: "20px" }}>
                    No hay Competidores en la Selección en esta modalidad.
                  </Typography>
                ) : (
                  <Box>
                    <TablaSeleccionInicio
                      seleccion={seleccion}
                      nombreModalidad={state?.modalidad.nombre}
                      idModalidad={state?.modalidad.id}
                    />
                  </Box>
                )}
              </Box>
            ) : isLoadingSeleccion ? (
              <CircularProgress style={{ marginTop: "50px" }} />
            ) : (
              <Box>
                <Typography
                  className="Titulo"
                  style={{
                    margin: "20px",
                    fontWeight: "bold",
                    marginTop: "50px",
                  }}
                >
                  No hay Competidores en la Selección en esta modalidad.
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <PieDePagina />
    </React.Fragment>
  );
}

export default SeleccionPublicada;
