import React, { useContext } from "react";
import { MenuContext } from "../../../../context/MenuContext";
import MenuLateral from "../../../../components/MenuLateral";
import { useLocation } from "react-router-dom";

function LayoutResultadosPublicados({ children, nombre }) {
  const { setMenuState } = useContext(MenuContext);
  const { state } = useLocation();

  const opcMenuTemp = [
    {
      opcion: "Novedades",
      link: "/modalidad/*",
      state,
    },
    {
      opcion: "Reglamento",
      link: "/Reglamento",
      state,
    },
    {
      opcion: "Historial",
      link: "/Historial-Publico",
      state,
    },
    {
      opcion: "Resultados",
      link: "/ResultadosPublicados",
      state,
    },
    {
      opcion: "Ranking",
      link: "/Ranking",
      state,
    },
    {
      opcion: "Historial Ranking",
      link: "/HistorialRanking",
      state,
    },
    {
      opcion: "Selección Argentina",
      link: "/SeleccionPublicada",
      state,
    },
  ];

  const opcMenu = opcMenuTemp.filter(item => {
    if ((item.opcion === "Historial Ranking" || item.opcion === "Ranking") && state?.modalidad?.id !== 14) {
      return false;
    }
    return true;
  });

  return (
    <React.Fragment>
      <MenuLateral
        setEstadoMenu={setMenuState}
        opcMenu={opcMenu}
        menuTitulo={`${nombre || ""}`}
        typeMenu={false}
      />
      {children}
    </React.Fragment>
  );
}

export default LayoutResultadosPublicados;
