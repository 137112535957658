import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from "@mui/material";
import ModalPiloto from "./ModalPiloto";

const ListaPiloto = ({ competidor, Origen }) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = React.useState(false);

  return (
    <React.Fragment>
      <Accordion
        className="Acordeon"
        expanded={isAccordionExpanded}
        onChange={(event, isExpanded) => setIsAccordionExpanded(isExpanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="AcordeonSummary"
        >
          <Typography className="Titulo" sx={{ width: "75%" }}>
            {competidor.nombre}
          </Typography>
          <Divider
            orientation="vertical"
            sx={{ width: "auto", height: "auto", m: 1 }}
          />
        </AccordionSummary>
        <AccordionDetails className="AcordeonDetail ">
          <ModalPiloto
            competidor={competidor}
            Origen={Origen}
            onClose={() => setIsAccordionExpanded(false)}
          />
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export default ListaPiloto;
