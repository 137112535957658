import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAgregarRecordMutation } from "../../../../../redux/services/record";
import { useGetCompetidorByIdQuery } from "../../../../../redux/services/competidor";
import { useGetListaCategoriasQuery } from "../../../../../redux/services/categoria";
import { useGetListaTiposRecordsQuery } from "../../../../../redux/services/tipoRecord";

const CrearRecord = () => {
  const [validationErrors, setValidationErrors] = React.useState({
    territorio: false,
    idPiloto: false,
    despegue: false,
    provincia: false,
    tipo: false,
    track: false,
    marca: false,
    idCategoria: false,
  });

  const initialState = {
    territorio: "Nacional",
    idPiloto: 0,
    provincia: "",
    tipo: "",
    idCategoria: 0,
    despegue: "",
    track: "",
    marca: "",
    fecha: dayjs().format("YYYY-MM-DD"),
    activo: true,
    estado: "Vigente",
  };

  const [record, setRecord] = React.useState(initialState);

  const [legajo, setLegajo] = React.useState(null);
  const [piloto, setPiloto] = React.useState("");

  const [hasError, setHasError] = React.useState({
    categoria: false,
  });

  const [dataCategorias, setDataCategorias] = React.useState();
  const { data: resCategorias, isLoading: isLoadingCategorias } =
    useGetListaCategoriasQuery();

  React.useEffect(() => {
    try {
      setDataCategorias(resCategorias?.listaCategorias);
    } catch (error) {
      console.log("Error al obtener los modalidades:", error);
    }

    return () => {
      setDataCategorias();
    };
  }, [resCategorias]);

  const handleChangeLicencia = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setLegajo(value);
  };

  const handleBlurLicencia = (e) => {
    e.preventDefault();
    const { value } = e.target;

    let errorMessage = "";

    if (value.trim() === "" || isNaN(value) || parseFloat(value) <= 0) {
      errorMessage = "Este campo es obligatorio";
      setValidationErrors((prevErrors) => {
        let newOBjet = {
          ...prevErrors,
        };
        newOBjet.idPiloto = true;
        return { ...newOBjet };
      });
    } else {
      setValidationErrors((prevErrors) => {
        let newOBjet = {
          ...prevErrors,
        };
        newOBjet.idPiloto = false;
        return { ...newOBjet };
      });
    }

    if (errorMessage === "") {
      setLegajo(value);
    }

    if (!dataLicencia) {
      toast.error("No existe un competidor con esa Licencia!");
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        // eslint-disable-next-line eqeqeq
        idPiloto: true,
      }));
    }
  };

  const { data: dataLicencia } = useGetCompetidorByIdQuery(legajo);

  React.useEffect(() => {
    const fetchLicencia = async () => {
      try {
        if (legajo) {
          const result = await dataLicencia;
          if (result) {
            const { idCompetidor, nombre } = result.competidorIndividual;
            setRecord((prevRecord) => {
              return {
                ...prevRecord,
                idPiloto: idCompetidor,
              };
            });
            setPiloto(nombre);
          }
        }
      } catch (error) {
        console.error("Error obteniendo datos de la licencia:", error);
        toast.error("El competidor no se encuentra cargado!");
        return;
      }
    };

    if (legajo) {
      fetchLicencia();
    }
  }, [dataLicencia]);

  const actualizarPosicion = (e) => {
    const { value } = e.target;
    setRecord((prevRecord) => {
      return {
        ...prevRecord,
        idPiloto: value,
      };
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    if (name === "provincia") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]:
          record?.territorio?.trim() === "Provincial" &&
          record?.provincia?.trim() === "",
      }));
    } else if (name === "tipo") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]:
          record?.territorio?.trim() === "Nacional" &&
          record?.tipo?.trim() === "",
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === "",
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRecord({
      ...record,
      [name]: value,
    });

    if (name === "provincia") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]:
          record?.territorio?.trim() === "Provincial" &&
          record?.provincia?.trim() === "",
      }));
    } else if (name === "tipo") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]:
          record?.territorio?.trim() === "Nacional" &&
          record?.tipo?.trim() === "",
      }));
    } else if (name === "idCategoria") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: record?.idCategoria === 0,
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === "",
      }));
    }
  };

  const handleChangeTerritorio = (event) => {
    setRecord({ ...record, territorio: event.target.value });
  };

  const handleRequired = () => {
    const errors = {
      territorio: record?.territorio?.trim() === "",
      despegue: record?.despegue?.trim() === "",
      provincia:
        record?.territorio?.trim() === "Provincial" &&
        record?.provincia?.trim() === "",
      tipo:
        record?.territorio?.trim() === "Nacional" &&
        record?.tipo?.trim() === "",
      track: record?.track?.trim() === "",
      marca: record?.marca?.trim() === "",
    };

    setValidationErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      console.log("validationErrors");
      console.log(validationErrors);
      console.log("No se puede guardar debido a errores de validación");
      return true;
    }

    return false;
  };

  const [agregarRecord, error] = useAgregarRecordMutation();

  const handlePublicar = async (e) => {
    e.preventDefault();

    if (handleRequired()) {
      toast.error("Todos los datos son obligatorios!");
      return;
    }

    guardarRegistro();

    try {
      const modId = await agregarRecord(record);
      if (modId.error) throw new Error(error);
      setRecord(initialState);
      setPiloto("");
      setLegajo("");
      toast.success("Record creado!", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success3",
      });
    } catch (error) {
      toast.error("Error creando  Record!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("Error creando  Record:", error);
    }
  };

  const guardarRegistro = () => {
    const registrosExistenteString = localStorage.getItem("registros");
    let registrosExistente = [];
    if (registrosExistenteString) {
      registrosExistente = JSON.parse(registrosExistenteString);
    }

    const nuevosRegistros = [...registrosExistente, record];

    localStorage.setItem("registros", JSON.stringify(nuevosRegistros));
  };

  const [tiposRecords, setTiposRecords] = useState([]);
  const { data: dataTiposRecords } = useGetListaTiposRecordsQuery();
  useEffect(() => {
    if (!dataTiposRecords) return;
    const tipodistancialibre = dataTiposRecords?.listaTipoRecord?.filter(
      (tipo) => tipo.id === 3
    );

    const otrostipos = dataTiposRecords?.listaTipoRecord
      ?.filter((tipo) => tipo.activo !== false)
      .filter((tipo) => tipo.id !== 3);

    const filtrado = [...tipodistancialibre, ...otrostipos];
    setTiposRecords(filtrado);

    return () => {
      setTiposRecords([]);
    };
  }, [dataTiposRecords]);

  return (
    <>
      <Grid container>
        <Grid item className="GridItemCN" style={{ marginTop: "5%" }}>
          <Typography
            className="Titulo TituloPpalCN"
            sx={{ textAlign: "center" }}
          >
            Crear Record
          </Typography>
        </Grid>

        <Divider className="DividerCN" />

        <Grid item className="GridItemCN" xs={12} md={5.5}>
          <Box
            sx={{ minWidth: "100%", maxWidth: "100%" }}
            style={{ paddingRight: "10px" }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Territorio</InputLabel>
              <Select
                sx={{}}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="territorio"
                value={record.territorio}
                label="Territorio"
                onChange={handleChangeTerritorio}
                required
              >
                <MenuItem value={"Nacional"}>Nacional</MenuItem>
                <MenuItem value={"Provincial"}>Provincial</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item className="GridItemCN" xs={12} md={5.5}>
          <Box sx={{ minWidth: "100%", maxWidth: "100%" }}>
            {record.territorio === "Provincial" ? (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Provincia</InputLabel>
                <Select
                  sx={{}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="provincia"
                  value={record.provincia}
                  label="Provincia"
                  onChange={handleChange}
                  required
                >
                  <MenuItem value={"Catamarca"}>Catamarca</MenuItem>
                  <MenuItem value={"Córdoba"}>Córdoba</MenuItem>
                  <MenuItem value={"Buenos Aires"}>Buenos Aires</MenuItem>
                  <MenuItem value={"Corrientes"}>Corrientes</MenuItem>
                  <MenuItem value={"Chaco"}>Chaco</MenuItem>
                  <MenuItem value={"Chubut"}>Chubut</MenuItem>
                  <MenuItem value={"Entre Ríos"}>Entre Ríos</MenuItem>
                  <MenuItem value={"Formosa"}>Formosa</MenuItem>
                  <MenuItem value={"Jujuy"}>Jujuy</MenuItem>
                  <MenuItem value={"La Pampa"}>La Pampa</MenuItem>
                  <MenuItem value={"La Rioja"}>La Rioja</MenuItem>
                  <MenuItem value={"Mendoza"}>Mendoza</MenuItem>
                  <MenuItem value={"Misiones"}>Misiones</MenuItem>
                  <MenuItem value={"Neuquén"}>Neuquén</MenuItem>
                  <MenuItem value={"Río Negro"}>Río Negro</MenuItem>
                  <MenuItem value={"Salta"}>Salta</MenuItem>
                  <MenuItem value={"San Juan"}>San Juan</MenuItem>
                  <MenuItem value={"San Luis"}>San Luis</MenuItem>
                  <MenuItem value={"Santa Cruz"}>Santa Cruz</MenuItem>
                  <MenuItem value={"Santa Fé"}>Santa Fé</MenuItem>
                  <MenuItem value={"Santiago del Estero"}>
                    Santiago del Estero
                  </MenuItem>
                  <MenuItem value={"Tierra del Fuego"}>
                    Tierra del Fuego
                  </MenuItem>
                  <MenuItem value={"Tucumán"}>Tucumán</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <Box sx={{ minWidth: "100%", maxWidth: "100%" }}></Box>
            )}
          </Box>
        </Grid>
        <Divider className="DividerCN" sx={{ width: "100%" }} />
        <Grid item className="GridItemCN" xs={12} md={5.5}>
          <Box
            sx={{ minWidth: "100%", maxWidth: "100%" }}
            style={{ paddingRight: "10px" }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
              <Select
                sx={{}}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="tipo"
                value={record.tipo}
                label="Tipo"
                onChange={handleChange}
                required
              >
                {tiposRecords?.length > 0 ? (
                  tiposRecords.map((item) => (
                    <MenuItem key={item.id} value={item.nombre}>
                      {item.nombre}
                    </MenuItem>
                  ))
                ) : (
                  <>
                    <MenuItem value={"Distancia Libre"}>
                      Distancia Libre
                    </MenuItem>
                    <MenuItem value={"Distancia en Ida y Vuelta"}>
                      Distancia en Ida y Vuelta
                    </MenuItem>
                    <MenuItem value={"Distancia en Triángulo Libre"}>
                      Distancia en Triángulo Libre
                    </MenuItem>
                    <MenuItem value={"Distancia en Triángulo FAI"}>
                      Distancia en Triángulo FAI
                    </MenuItem>
                    <MenuItem value={"Velocidad sobre Triángulo FAI 25km"}>
                      Velocidad sobre Triángulo FAI 25km
                    </MenuItem>
                    <MenuItem value={"Velocidad sobre Triángulo FAI 50km"}>
                      Velocidad sobre Triángulo FAI 50km
                    </MenuItem>
                    <MenuItem value={"Velocidad sobre Triángulo FAI 100km"}>
                      Velocidad sobre Triángulo FAI 100km
                    </MenuItem>
                    <MenuItem value={"Velocidad sobre Triángulo FAI 200km"}>
                      Velocidad sobre Triángulo FAI 200km
                    </MenuItem>
                    <MenuItem value={"Velocidad en Ida y Vuelta de 50km"}>
                      Velocidad en Ida y Vuelta de 50km
                    </MenuItem>
                    <MenuItem value={"Velocidad en Ida y Vuelta de 100km"}>
                      Velocidad en Ida y Vuelta de 100km
                    </MenuItem>
                    <MenuItem value={"Velocidad en Ida y Vuelta de 200km"}>
                      Velocidad en Ida y Vuelta de 200km
                    </MenuItem>
                    <MenuItem value={"Velocidad en Ida y Vuelta de 300km"}>
                      Velocidad en Ida y Vuelta de 300km
                    </MenuItem>
                  </>
                )}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item className="GridItemCN" xs={12} md={5.5}>
          <Box sx={{ minWidth: "100%", maxWidth: "100%" }}>
            {resCategorias ? (
              <FormControl
                fullWidth
                sx={{ textAlign: "center" }}
                error={hasError.categoria}
              >
                <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                <Select
                  sx={{}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="idCategoria"
                  value={record.idCategoria}
                  label="Categoría"
                  onChange={handleChange}
                  required
                >
                  {dataCategorias?.map((categoria) => (
                    <MenuItem key={categoria.id} value={categoria.id}>
                      {categoria.nombre}
                    </MenuItem>
                  ))}
                </Select>
                {hasError.categoria && (
                  <FormHelperText
                    sx={{ color: "#D32F2F", textAlign: "center" }}
                  >
                    Campo requerido!
                  </FormHelperText>
                )}
              </FormControl>
            ) : isLoadingCategorias ? (
              <Grid container justifyContent="center" alignItems="center">
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ width: "50%", height: "50%", textAlign: "center" }}
                >
                  <CircularProgress style={{ width: "30px", height: "30px" }} />
                  <Typography
                    style={{
                      color: "rgb(0, 150, 215)",
                      fontSize: "15px",
                      marginTop: "10px",
                    }}
                  >
                    Cargando Categoría
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Typography className="Titulo">
                {" No hay Categoría para mostrar."}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item className="GridItemCN" xs={12} md={5.5}>
          <Box
            sx={{ minWidth: "100%", maxWidth: "100%" }}
            style={{ paddingRight: "10px" }}
          >
            <TextField
              sx={{}}
              label="Licencia"
              name="licencia"
              value={legajo}
              onBlur={(e) => handleBlurLicencia(e)}
              onChange={(e) => handleChangeLicencia(e)}
              required
              fullWidth
              error={validationErrors.idPiloto}
              helperText={
                validationErrors.idPiloto && "Este campo es requerido"
              }
            />
          </Box>
        </Grid>
        <Grid item className="GridItemCN" xs={12} md={5.5}>
          <Box sx={{ minWidth: "100%", maxWidth: "100%" }}>
            <TextField
              sx={{}}
              label="Nombre y Apellido"
              name="nombre"
              value={piloto}
              onChange={(e) => actualizarPosicion(e)}
              required
              fullWidth
              disabled
            />
          </Box>
        </Grid>

        <Grid item className="GridItemCN" xs={12} md={5.5}>
          <Box
            sx={{ minWidth: "100%", maxWidth: "100%" }}
            style={{ paddingRight: "10px" }}
          >
            <TextField
              sx={{}}
              label="Track"
              name="track"
              value={record.track}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              fullWidth
              error={validationErrors.track}
              helperText={validationErrors.track && "Este campo es requerido"}
            />
          </Box>
        </Grid>
        <Grid item className="GridItemCN" xs={12} md={5.5}>
          <Box sx={{ minWidth: "100%", maxWidth: "100%" }}>
            <TextField
              sx={{}}
              label="Ciudad/Despegue"
              name="despegue"
              value={record.despegue}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              fullWidth
              error={validationErrors.despegue}
              helperText={
                validationErrors.despegue && "Este campo es requerido"
              }
            />
          </Box>
        </Grid>

        <Divider className="DividerCN" />
        <Grid item className="GridItemCN" xs={12} md={5.5}>
          <Box
            sx={{ minWidth: "100%", maxWidth: "100%" }}
            style={{ paddingRight: "10px" }}
          >
            <TextField
              sx={{}}
              label="Marca"
              name="marca"
              value={record.marca}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              fullWidth
              error={validationErrors.marca}
              helperText={validationErrors.marca && "Este campo es requerido"}
            />
          </Box>
        </Grid>
        <Grid item className="GridItemCN" xs={12} md={5.5}>
          <Box sx={{ minWidth: "100%", maxWidth: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} firstDayOfWeek={0}>
              <DatePicker
                value={
                  dayjs(record?.fecha, { format: "YYYY-MM-DD" }).isValid()
                    ? dayjs(record?.fecha, { format: "YYYY-MM-DD" })
                    : dayjs()
                }
                label="Fecha de Vuelo"
                name="fecha"
                disableTextEditor
                inputFormat="DD/MM/YYYY"
                format="DD/MM/YYYY"
                onChange={(newValue) => {
                  const fecha = dayjs(newValue).format("YYYY-MM-DD");
                  try {
                    setRecord((prevNewData) => ({
                      ...prevNewData,
                      fecha: fecha,
                    }));
                  } catch (error) {
                    console.log(error);
                  }
                }}
                sx={{ width: "100%", marginRight: "2%" }}
              />
            </LocalizationProvider>
          </Box>
        </Grid>
        <Divider className="DividerCN" />
        <Grid
          item
          className="GridBotonesCN"
          style={{ justifyContent: "space-between", marginBottom: "5%" }}
        >
          <Box sx={{ minWidth: "48%", maxWidth: "48%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Estado</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="estado"
                value={record.estado ? record.estado : " "}
                label="Estado"
                onChange={handleChange}
                required
              >
                <MenuItem value={" "}>--Seleccione Estado--</MenuItem>
                <MenuItem value={"Vigente"}>Vigente</MenuItem>
                <MenuItem value={"Superado"}>Superado</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Button className="BotonBody BotonCN" onClick={handlePublicar}>
            Guardar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CrearRecord;
