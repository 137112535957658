import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import useConfirmationModal from "../../../../../hooks/useConfirmationModal ";
import {
  useBorrarReglamentoByIdMutation,
  useGetReglamentoByIdQuery,
} from "../../../../../redux/services/reglamento";

const TablaReglamentosAdmin = ({ reglamentos, nombreModalidad }) => {
  const { openConfirm, ModalConfirmacion } = useConfirmationModal();

  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "https://localhost:5000";

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(reglamentos?.length / pageSize);
  const paginatedReglamentos = reglamentos?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const [borrarReglamentoById, error] = useBorrarReglamentoByIdMutation();
  const [idReglamentoABorrar, setidReglamentoABorrar] = useState();

  const { data } = useGetReglamentoByIdQuery(idReglamentoABorrar, {
    skip: idReglamentoABorrar ? false : true,
  });

  const borrarReglamento = async (idReglamento) => {
    try {
      await borrarReglamentoById(idReglamento);
      toast.success("¡Reglamento eliminado con exito!", {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "success2",
      });
    } catch (error) {
      console.log("errorsdfsdfs", error);
      console.log(data);

      toast.error("Error eliminando Reglamento!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDelete = async (idReglamento) => {
    setidReglamentoABorrar(idReglamento);
    openConfirm(
      "Confirmación de Borrado",
      "¿Eliminar este reglamento?",
      "Eliminar",
      () => borrarReglamento(idReglamento)
    );
  };

  return (
    <React.Fragment>
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="Titulo" align="left" style={{ width: "10%" }}>#</TableCell>
                  <TableCell className="Titulo" align="left" style={{ width: "55%" }}>Reglamento</TableCell>
                  <TableCell className="Titulo" align="center" style={{ width: "20%" }}>Descargar Documento</TableCell>
                  <TableCell className="Titulo" align="left" style={{ width: "15%" }}>Eliminar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedReglamentos?.map((reglamento, indice) => (
                  <TableRow key={reglamento.id}>
                    <TableCell align="left" style={{ width: "10%" }}>
                      <Typography style={{ fontWeight: "bold", textAlign: "left" }}>
                        {indice + 1 + (currentPage - 1) * pageSize}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" style={{ width: "55%" }}>
                      <Typography style={{ fontWeight: "bold", textAlign: "left" }}>
                        {reglamento?.nombre}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" style={{ width: "30%" }}>
                      <IconButton
                        component="a"
                        href={`${API_BASE_URL}/images/${reglamento?.ruta}`}
                        download={`${reglamento?.nombrePdf}`}
                        target="_blank"
                        alt={"descargar pdf"}
                        style={{ width: "auto" }}
                      >
                        <GetAppIcon color="primary" />
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(reglamento.id)}
                        color="primary"
                      >
                        <DeleteIcon sx={{ fontSize: "1.5rem" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Divider sx={{ width: "100%", m: 2 }} />
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            {"<<"}
          </Button>
          <Typography variant="body1" style={{ margin: "5px 10px 0px 10px" }}>
            Pag.{currentPage} / {totalPages}
          </Typography>
          <Button
            variant="outlined"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            {">>"}
          </Button>
        </Box>
      </Grid>
    </Box>
    <ModalConfirmacion />
  </React.Fragment>
  );
};

export default TablaReglamentosAdmin;
