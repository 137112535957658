import React from 'react';
import { Link } from 'react-router-dom';
import './Styles/categoriaCard.css';
import noimage from '../static/img/descarga.png';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setModalidad } from '../redux/features/modalidadSlice';

function ModalidadCard({ modalidad, color, linkTo = '*' }) {
  const dispatch = useDispatch();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://localhost:5000';

  const handleClick = () => {
    dispatch(setModalidad(modalidad));
  };
  
  return (
    <React.Fragment>
      <Card className="CartaNovedad" component={Link} to={linkTo} onClick={handleClick} >
        <Box sx={{ height: 'auto', minWidth: 300, width: '100%', display: 'flex', justifyContent: 'center' }}>
          <CardMedia
            sx={{ backgroundImage: modalidad?.imagen ? `url(${API_BASE_URL}/images/${modalidad?.imagen})` : '#FFF' }}
            component="img"
            src={modalidad?.imagen ? `${API_BASE_URL}/images/${modalidad?.imagen}` : noimage}
            title={modalidad?.nombre}
            className='imagenCN'
          />
        </Box>
        <CardContent sx={{ height: '100%', width: '100%' }}>
          <Typography className='tituloCN' variant="h7" component='h4' textAlign="center">
            {modalidad.nombre}
          </Typography>
        </CardContent>

      </Card>



    </React.Fragment >
  );
}

export default ModalidadCard