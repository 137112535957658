
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, Grid, IconButton, Paper, Typography } from '@mui/material/';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dropzone = ({ className, setBase64, base64 }) => {

    const [files, setFiles] = useState([]);

    useEffect(() => {
        setFiles(base64)
    }, [base64])

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles?.length) {

            const existingFileNames = new Set(files.map(file => file.name));
            const newFiles = acceptedFiles.filter(file => !existingFileNames.has(file.name));
            const maxFilesAllowed = 5;
            const remainingSlots = maxFilesAllowed - files.length;

            if ((files.length + newFiles.length) > maxFilesAllowed) {
                toast.info('Solo puedes agregar hasta 5 imágenes.', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

            const filesToAdd = newFiles.slice(0, remainingSlots);
            const duplicateFiles = acceptedFiles.filter(file => existingFileNames.has(file.name));

            if (duplicateFiles.length > 0) {
                toast.info('Una o más imágenes ya existen.', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

            setFiles(previousFiles => [
                ...previousFiles,
                ...filesToAdd.map(file =>
                    Object.assign(file, { preview: URL.createObjectURL(file) })
                )
            ])
        }
    }, [files])

    const { getRootProps, getInputProps, } = useDropzone({
        onDrop,
        accept: {
            'image/*': []
        },
    })

    const removeFile = (name) => {
        setFiles((prevFiles) => {
            const updatedFiles = prevFiles.filter((file) => file.name !== name);
            updatedFiles.forEach((file) => URL.revokeObjectURL(file.preview));
            return updatedFiles;
        });
    };

    useEffect(() => {
        const handleSubmit = async () => {

            if (!files?.length) return;

            try {
                setBase64(files);
            } catch (error) {
                console.error('Error converting files to base64:', error);
            }
        };

        handleSubmit()
    }, [files])



    return (
        <form>

            <Paper elevation={3} sx={{ width: '100%', height: 'auto', mx: 'auto', display: 'flex', justifyContent: 'center', alignTracks: 'center', flexDirection: 'column', }}>
                <div
                    {...getRootProps({
                        className: className
                    })}
                    style={{ border: '1px solid #c5c5c5', backgroundColor: '#F5F5F5' }}
                >
                    <input
                        {...getInputProps()}
                    />
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignTracks: 'center',
                    }}>
                        <Button variant='outlined' sx={{ my: 3, mx: 'auto', p: 2, maxWidth: '50%', minWidth: '50%', }}> Agregar Imagenes</Button>

                    </Box>

                    <Grid item sx={{ minHeight: 10, maxHeight: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', my: 2, }}>
                        {(!files?.length)
                            &&
                            <Typography
                                className='subtituloContenido'
                                textAlign={'center'}
                                style={{ fontSize: 24, textWrap: 'wrap' }}>Agrega aquí hasta 5 imágenes</Typography>}
                    </Grid>
                </div >

                <>
                    <Grid container style={{ marginTop: 6, gap: 2, minHeight: 100, marginBottom: 20, maxHeight: '100%', }} >
                        {files.map(file => (
                            <Grid item key={file.name} xs={6} md={2} sx={{ mx: 'auto', position: 'relative' }} >
                                <img
                                    src={file.preview}
                                    alt={file.name}
                                    width={100}
                                    height={100}
                                    position={'absolute'}
                                    onLoad={() => {
                                        URL.revokeObjectURL(file.preview)
                                    }}

                                />
                                <IconButton
                                    type='button'
                                    onClick={() => removeFile(file.name)}
                                    sx={{ mt: '-10%', ml: '-10%', position: 'absolute' }}
                                >
                                    <CancelIcon color="error" fontSize="small" />

                                </IconButton>
                                <Typography
                                    className='subtituloContenido'
                                    style={{ textAlign: 'start', fontSize: 12 }}>
                                    {file.name}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid >
                </>
            </Paper>
        </form >
    )
}
export default Dropzone
