import { Box, Button, Divider, Grid, Modal, TextField } from '@mui/material';
import React from 'react';
import { toast } from 'react-toastify';
import { useModificarAjusteMutation } from '../../../../../redux/services/ajustes';

const ModalAjuste = ({ ModalState, CloseModal, Tipo, CurrentId, Origen = 'readonly' }) => {

    const [newData, setNewData] = React.useState(Tipo);
    const formRef = React.useRef();

    const [validationErrors, setValidationErrors] = React.useState({
        valor: false,
    });

    const [modificarAjuste] = useModificarAjusteMutation();

    const handleEdit = async () => {
        if (validationErrors.valor) { toast.error("valor es campo obligatorio!"); return; }
        try {
            await modificarAjuste(newData);
            toast.success('Parametro actualizado con éxito!', {
                position: toast.POSITION.TOP_CENTER,
                toastId: 'success1',
            });
            CloseModal();
        } catch (error) {
            toast.error('Error Actualizando Parametro!', {
                position: toast.POSITION.TOP_CENTER
            });
            console.log(error)
        }

    }

    const handleCancelar = () => {
        setNewData(Tipo);
        CloseModal();
    }


    const handleBlur = (e) => {
        const { name, value } = e.target;

        if (name === 'valor')
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                [name]: value == 0 || isNaN(value),
            }));
        else
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                [name]: value.trim() === '',
            }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewData({
            ...newData,
            [name]: value,
        });

        if (name === 'valor')
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                [name]: value == 0 || isNaN(value),
            }));
        else
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                [name]: value.trim() === '',
            }));
    };

    const handleCloseModal = () => {
        CloseModal()
    }

    return (
        <React.Fragment>
            <Modal key={Tipo.id} open={ModalState} onClose={handleCloseModal} className="ModalC" >
                <Box className="ContenedorMC" style={{ width: '80%' }}>
                    <Box className="ContSecundarioMC" >
                        <form ref={formRef}>
                            <Grid container sx={{ justifyContent: 'space-between' }}>
                                <Grid item xs={5} md={9} style={{ marginBottom: '15px' }} >
                                    <TextField
                                        fullWidth
                                        required
                                        label="Nombre"
                                        aria-label="Nombre"
                                        className="Nombre"
                                        defaultValue={Tipo.variable}
                                        value={newData.variable}
                                        name='variable'
                                        disabled
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        required
                                        type='number'
                                        label='Modificador'
                                        aria-label="modificador"
                                        className="Subtitulo"
                                        style={{ whiteSpace: 'pre', }}
                                        defaultValue={Tipo.valor}
                                        value={newData.valor}
                                        name='valor'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={validationErrors.valor}
                                        helperText={validationErrors.valor && 'Este campo es requerido'}
                                    />
                                </Grid>
                                <Divider className='DividerMC' />
                                <Grid container sx={{ justifyContent: 'end' }}>
                                    <Button onClick={handleEdit}
                                        className='BotonBody'>Guardar</Button>
                                    <Button onClick={handleCancelar} className='BotonBody' >Cancelar</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Box>
            </Modal >
        </React.Fragment >
    );
}

export default ModalAjuste