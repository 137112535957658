import React, { useContext, useState } from "react";
import { MenuContext } from "../../../../context/MenuContext";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import CrearHistorial from "./componentes/CrearHistorial";
import ListaHistorial from "./componentes/ListaHistorial";
import { useGetListaHistorialQuery } from "../../../../redux/services/historial";

const Historial = () => {
  const { menuState } = useContext(MenuContext);
  const [historial, setHistorial] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const { data: dataHistorial, isLoading: isLoadingHistorial } =
    useGetListaHistorialQuery();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const filtrado = dataHistorial?.listaHistorial;
        setHistorial(filtrado);
      } catch (error) {
        console.error("Error al obtener los posts:", error);
      }
    };

    fetchData();
  }, [dataHistorial]);
  const totalPages = Math.ceil(historial?.length / pageSize);

  const paginatedHistorial = historial?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <>
      <Box
        className={`${
          menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
        }`}
      >
        <Grid container style={{ flexDirection: "row" }}>
          <Grid
            item
            xs={12}
            style={{ flexDirection: "column" }}
            className="gridColumnaPXC"
          >
            {historial ? (
              <Box className="boxCalendarioPXC">
                <Typography className="Titulo" sx={{ marginBottom: "15px" }}>
                  Historial:
                </Typography>

                {paginatedHistorial?.length === 0 ? (
                  <Typography
                    className="Titulo"
                    style={{ fontSize: 14, alignSelf: "center" }}
                  >
                    No hay Historial para mostrar.
                  </Typography>
                ) : (
                  paginatedHistorial.map((historial) => (
                    <ListaHistorial
                      key={historial.id}
                      historial={historial}
                      Origen={"auth"}
                    />
                  ))
                )}
              </Box>
            ) : isLoadingHistorial ? (
              <CircularProgress />
            ) : (
              <Typography className="Titulo">
                {" No hay Historial para mostrar."}
              </Typography>
            )}
            {!isNaN(totalPages) && paginatedHistorial?.length > 0 && (
              <Box mt={2} display="flex" justifyContent="center">
                <Button
                  variant="outlined"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  {"<<"}
                </Button>
                <Typography
                  variant="body1"
                  style={{ margin: "5px 10px 0px 10px" }}
                >
                  Pag.{currentPage} / {totalPages}
                </Typography>
                <Button
                  variant="outlined"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  {">>"}
                </Button>
              </Box>
            )}
            <Divider sx={{ width: "100%", m: 2 }} />

            <Box style={{ width: "100%" }} className="">
              <CrearHistorial />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Historial;
