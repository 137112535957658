import React, { useState } from "react";
import './Styles/Calendario.css';
import {
  Box,
  Grid,
  Paper,
  Typography,
  styled
} from "@mui/material";
import { useGetModalidadByIdQuery } from "../redux/services/modalidad";
import ModalCompetencia from "./ModalCompetencia";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function CalendarioCompetencias({ competencia, Origen }) {

  const [modalState, setModalState] = useState(false);
  const [currentId, setCurrentId] = useState('');

  const handleCloseModal = () => {
    setModalState(false);
  }

  const { data: dataModalidad, } = useGetModalidadByIdQuery(competencia.idModalidadFk);
  const [modalidad, setModalidad] = useState('');

  React.useEffect(() => {
    const fetchModalidad = async () => {
      try {
        const result = await dataModalidad;

        if (result && result.modalidadIndividual) {
          setModalidad(result.modalidadIndividual.nombre);
        }
      } catch (error) {
        console.error('Error al obtener la modalidad:', error);
      }
    };

    fetchModalidad();
  }, [dataModalidad]);

  return (
    <React.Fragment>
      <Grid container onClick={() => { setCurrentId(competencia.id); setModalState(!modalState) }} style={{ heigth: 150 }}  >
        <Grid item
          xs={12}
          sx={{ m: 1 }}
          className=""
          key={competencia.id}
        >
          <Item
            sx={{ p: 1, maxWidth: '100%', display: 'flex', flexDirection: 'row' }}
            elevation={3}>
            <Box className="Texto truncate-text"
              sx={{ maxWidth: "100%", p: 0.5 }}>
              <Typography
                className="TituloPXC"
                style={{
                  textAlign: 'left',
                  margin: "10px", fontSize: 15, overflow: 'hidden',
                  textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: "100%"
                }}>
                Competencia: <b>{competencia.titulo}</b>
              </Typography>
              <Typography
                className="TextoPXC"
                sx={{
                  textAlign: 'left', margin: "10px", overflow: 'hidden',
                  textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: "100%"
                }}
              >
                Modalidad: <b>  {modalidad} </b>
              </Typography>
            </Box>
            <Box className="Texto truncate-text " sx={{ textAlign: 'center', maxWidth: "20%" }}>
              <Typography className="SubtituloPXC textoPequeñoNI">
                Inicio:
              </Typography>
              <Typography
                className="TextoPXC textoMuyPequeñoNI"
                sx={{ maxWidth: "100%" }}
              >
                {competencia.fechaInicio}
              </Typography>

            </Box>
          </Item>

          <ModalCompetencia
            ModalState={modalState}
            CloseModal={handleCloseModal}
            Competencia={competencia}
            CurrentId={currentId}
            Origen={Origen}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CalendarioCompetencias;
