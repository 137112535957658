import React from "react";
import "../../../../../components/Styles/Calendario.css";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

function CompetenciasListado({ competencia, Origen }) {
  const navigate = useNavigate();

  const handleSubirResultado = () => {
    navigate("/SubirResultados", { state: competencia });
  };

  return (
    <React.Fragment>
      <Paper className="Acordeon" sx={{ mb: 2, p: 1 }}>
        <Grid container rowSpacing={2} Spacing={2}>
          <Grid item xs={12} md={8}>
            <Box className="AcordeonSummary">
              <Typography className="Titulo" sx={{ width: "100%" }}>
                {competencia.titulo}
              </Typography>
              <Typography
                className="Subtitulo textoPequeñoCal"
                sx={{ textAlign: "left" }}
              >
                Fecha de Finalización:{" "}
                {dayjs(competencia.fechaFin).format("DD-MM-YYYY")}
              </Typography>
            </Box>
          </Grid>

          <Box flexGrow={1} />
          <Grid
            item
            xs={12}
            md={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              disabled={competencia.tieneHtml === false}
              onClick={() => {
                navigate("/VerResultados", { state: competencia });
              }}
              className="BotonBody"
              style={{ padding: 5 }}
            >
              VER{" "}
            </Button>
            <Button
              disabled={competencia.tieneHtml === true}
              onClick={handleSubirResultado}
              className="BotonBody"
              style={{ padding: 5 }}
            >
              SUBIR{" "}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default CompetenciasListado;
