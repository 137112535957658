import { Box, Grid, Link } from '@mui/material'
import React from 'react'
import BarraNavegacion from '../../components/BarraNavegacion'
import InicioSesion from '../../components/InicioSesion'

const Login = () => {
    const isMobile = window.innerWidth <= 768;

    return (
        <>
            <BarraNavegacion />
            <Grid sx={{ width: '100vw', height: '100vh', margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ width: isMobile ? '100vw' : '50vw', mt: !isMobile ? '8rem' : '5rem', pb: '1rem' }}>
                    <Box className="ContenidoPpalCN" sx={{}}>
                        <InicioSesion />
                    </Box>
                    <Box sx={{ width: isMobile ? '100vw' : '50vw', display: 'flex', justifyContent: 'center' }}>
                        <Link sx={{ fontWeight: 'bold' }} target='_blank' underline="none" color="primary" href='https://www.favl.com.ar/' >https://www.favl.com.ar/</Link>
                    </Box>
                </Box>
            </Grid >
        </>
    )
}

export default Login