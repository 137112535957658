import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useGetListaRecordsQuery,
  useTraerRecordByFiltroMutation,
} from "../../../../../redux/services/record";
import { useGetListaCategoriasQuery } from "../../../../../redux/services/categoria";
import { LoadingButton } from "@mui/lab";
import { useGetListaTiposRecordsQuery } from "../../../../../redux/services/tipoRecord";

const FiltrosRecordPublico = ({ setDataRecords, setIsLoading }) => {
  const initialState = {
    tipo: " ",
    territorio: " ",
    estado: " ",
    idCategoria: 0,
  };

  const [validationErrors, setValidationErrors] = React.useState({
    tipo: false,
    territorio: false,
    anio: false,
    idCategoria: false,
  });

  const [recordBuscado, setRecordBuscado] = useState(initialState);
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name !== "anio" && name !== "idCategoria") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === "",
      }));
    }

    setRecordBuscado({
      ...recordBuscado,
      [name]: value,
    });
  };

  const [traerRecordByFiltro, { isLoading: isLoadingRecordsFiltrados }] =
    useTraerRecordByFiltroMutation();

  const { data: resRecords, isLoading: isLoadingRecords } =
    useGetListaRecordsQuery();

  useEffect(() => {
    setIsLoading(isLoadingRecords);
  }, [isLoadingRecords]);

  useEffect(() => {
    setIsLoading(isLoadingRecordsFiltrados);
  }, [isLoadingRecordsFiltrados]);

  const handleBuscar = async (e) => {
    e.preventDefault();
    try {
      if (
        (recordBuscado?.anio === 0 || recordBuscado?.anio === null) &&
        (recordBuscado?.idCategoria === 0 ||
          recordBuscado?.idCategoria === null) &&
        (recordBuscado?.territorio === " " ||
          recordBuscado?.territorio === null) &&
        (recordBuscado?.tipo === " " || recordBuscado?.tipo === null)
      ) {
        setDataRecords(resRecords?.listaRecords);
      } else {
        const dataRecordBuscados = await traerRecordByFiltro(recordBuscado);
        if (dataRecordBuscados.error)
          throw new Error("Error al buscar el record");
        setDataRecords(dataRecordBuscados.data?.listaRecords);
      }
    } catch (error) {
      toast.error("Error Buscando Record!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("Error Buscando Record:", error);
    }
  };

  const [dataCategorias, setDataCategorias] = React.useState();
  const { data: resCategorias, isLoading: isLoadingCategorias } =
    useGetListaCategoriasQuery();

  React.useEffect(() => {
    try {
      setDataCategorias(resCategorias?.listaCategorias);
    } catch (error) {
      console.log("Error al obtener los modalidades:", error);
    }
    return () => {
      setDataCategorias();
    };
  }, [resCategorias]);

  useEffect(() => {
    setIsLoading(isLoadingRecords);
  }, [isLoadingRecords]);

  useEffect(() => {
    setIsLoading(isLoadingRecordsFiltrados);
  }, [isLoadingRecordsFiltrados]);

  const [tiposRecords, setTiposRecords] = useState();
  const { data: dataTiposRecords } = useGetListaTiposRecordsQuery();
  useEffect(() => {
    if (!dataTiposRecords) return;
    const tipodistancialibre = dataTiposRecords?.listaTipoRecord?.filter(
      (tipo) => tipo.id === 3
    );

    const otrostipos = dataTiposRecords?.listaTipoRecord
      ?.filter((tipo) => tipo.activo !== false)
      .filter((tipo) => tipo.id !== 3);

    const filtrado = [...tipodistancialibre, ...otrostipos];
    setTiposRecords(filtrado);

    return () => {
      setTiposRecords([]);
    };
  }, [dataTiposRecords]);

  return (
    <>
      <Grid container sx={{ marginBottom: "-5%" }}>
        <Grid item className="GridItemCN" style={{ marginTop: "5%" }}>
          <Typography
            className="Titulo TituloPpalCN"
            sx={{ textAlign: "center" }}
          >
            Buscar
          </Typography>
        </Grid>

        <Divider className="DividerCN" style={{ width: "100%" }} />
        <Grid item className="GridItemCN" xs={12} md={3} lg={3}>
          <Box sx={{ minWidth: "100%", maxWidth: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Territorio</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="territorio"
                value={recordBuscado.territorio ? recordBuscado.territorio : ""}
                label="Territorio"
                onChange={handleChange}
                required
              >
                <MenuItem value={" "}>--Seleccione Territorio--</MenuItem>
                <MenuItem value={"Nacional"}>Nacional</MenuItem>
                <MenuItem value={"Catamarca"}>Catamarca</MenuItem>
                <MenuItem value={"Córdoba"}>Córdoba</MenuItem>
                <MenuItem value={"Buenos Aires"}>Buenos Aires</MenuItem>
                <MenuItem value={"Corrientes"}>Corrientes</MenuItem>
                <MenuItem value={"Chaco"}>Chaco</MenuItem>
                <MenuItem value={"Chubut"}>Chubut</MenuItem>
                <MenuItem value={"Entre Ríos"}>Entre Ríos</MenuItem>
                <MenuItem value={"Formosa"}>Formosa</MenuItem>
                <MenuItem value={"Jujuy"}>Jujuy</MenuItem>
                <MenuItem value={"La Pampa"}>La Pampa</MenuItem>
                <MenuItem value={"La Rioja"}>La Rioja</MenuItem>
                <MenuItem value={"Mendoza"}>Mendoza</MenuItem>
                <MenuItem value={"Misiones"}>Misiones</MenuItem>
                <MenuItem value={"Neuquén"}>Neuquén</MenuItem>
                <MenuItem value={"Río Negro"}>Río Negro</MenuItem>
                <MenuItem value={"Salta"}>Salta</MenuItem>
                <MenuItem value={"San Juan"}>San Juan</MenuItem>
                <MenuItem value={"San Luis"}>San Luis</MenuItem>
                <MenuItem value={"Santa Cruz"}>Santa Cruz</MenuItem>
                <MenuItem value={"Santa Fé"}>Santa Fé</MenuItem>
                <MenuItem value={"Santiago del Estero"}>
                  Santiago del Estero
                </MenuItem>
                <MenuItem value={"Tierra del Fuego"}>Tierra del Fuego</MenuItem>
                <MenuItem value={"Tucumán"}>Tucumán</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item className="GridItemCN" xs={12} md={3} lg={3}>
          <Box sx={{ minWidth: "100%", maxWidth: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="tipo"
                value={recordBuscado.tipo ? recordBuscado.tipo : " "}
                label="Tipo"
                onChange={handleChange}
                required
              >
                <MenuItem value={" "}>--Seleccione Tipo--</MenuItem>
                {tiposRecords?.length > 0 ? (
                  tiposRecords?.map((item) => (
                    <MenuItem key={item.id} value={item.nombre.trim()}>
                      {item.nombre}
                    </MenuItem>
                  ))
                ) : (
                  <>
                    <MenuItem value={"Distancia Libre"}>
                      Distancia Libre
                    </MenuItem>
                    <MenuItem value={"Distancia en Ida y Vuelta"}>
                      Distancia en Ida y Vuelta
                    </MenuItem>
                    <MenuItem value={"Distancia en Triángulo Libre"}>
                      Distancia en Triángulo Libre
                    </MenuItem>
                    <MenuItem value={"Distancia en Triángulo FAI"}>
                      Distancia en Triángulo FAI
                    </MenuItem>
                    <MenuItem value={"Velocidad sobre Triángulo FAI 25km"}>
                      Velocidad sobre Triángulo FAI 25km
                    </MenuItem>
                    <MenuItem value={"Velocidad sobre Triángulo FAI 50km"}>
                      Velocidad sobre Triángulo FAI 50km
                    </MenuItem>
                    <MenuItem value={"Velocidad sobre Triángulo FAI 100km"}>
                      Velocidad sobre Triángulo FAI 100km
                    </MenuItem>
                    <MenuItem value={"Velocidad sobre Triángulo FAI 200km"}>
                      Velocidad sobre Triángulo FAI 200km
                    </MenuItem>
                    <MenuItem value={"Velocidad en Ida y Vuelta de 50km"}>
                      Velocidad en Ida y Vuelta de 50km
                    </MenuItem>
                    <MenuItem value={"Velocidad en Ida y Vuelta de 100km"}>
                      Velocidad en Ida y Vuelta de 100km
                    </MenuItem>
                    <MenuItem value={"Velocidad en Ida y Vuelta de 200km"}>
                      Velocidad en Ida y Vuelta de 200km
                    </MenuItem>
                    <MenuItem value={"Velocidad en Ida y Vuelta de 300km"}>
                      Velocidad en Ida y Vuelta de 300km
                    </MenuItem>
                  </>
                )}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {resCategorias ? (
          <Grid item className="GridItemCN" xs={12} md={3} lg={3}>
            <Box sx={{ minWidth: "100%", maxWidth: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="idCategoria"
                  value={recordBuscado.idCategoria || 0}
                  label="Categoría"
                  onChange={handleChange}
                  required
                >
                  {dataCategorias &&
                    [
                      { id: 0, nombre: "--seleccione Categoría---" },
                      ...dataCategorias,
                    ]?.map((categoria) => (
                      <MenuItem key={categoria.id} value={categoria.id}>
                        {categoria.nombre}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        ) : isLoadingCategorias ? (
          <Grid item className="GridItemCN" xs={12} md={3} lg={3}>
            <Box sx={{ minWidth: "100%", maxWidth: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Categoría</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="idCategoria"
                  value={recordBuscado.idCategoria || 0}
                  label="Categoría"
                  onChange={handleChange}
                  required
                >
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        width: "50%",
                        height: "50%",
                        textAlign: "center",
                      }}
                    >
                      <CircularProgress
                        style={{ width: "30px", height: "30px" }}
                      />
                      <Typography
                        style={{
                          color: "rgb(0, 150, 215)",
                          fontSize: "15px",
                          marginTop: "10px",
                        }}
                      >
                        Cargando Categoría
                      </Typography>
                    </Grid>
                  </Grid>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        ) : (
          <Typography className="Titulo">
            {" No hay Categoría para mostrar."}
          </Typography>
        )}
        <Grid item className="GridItemCN" xs={12} md={2.5}>
          <Box sx={{ minWidth: "100%", maxWidth: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Estado</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="estado"
                value={recordBuscado.estado ? recordBuscado.estado : " "}
                label="Estado"
                onChange={handleChange}
                required
              >
                <MenuItem value={" "}>--Seleccione Estado--</MenuItem>
                <MenuItem value={"Vigente"}>Vigente</MenuItem>
                <MenuItem value={"Superado"}>Superado</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Divider className="DividerCN" style={{ width: "100%" }} />
        <Grid item className="GridBotonesCN">
          <LoadingButton
            loading={isLoadingRecordsFiltrados}
            className="BotonBody BotonCN"
            onClick={handleBuscar}
          >
            Buscar
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};

export default FiltrosRecordPublico;
