import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";

const TablaRankingAdmin = ({ ranking, nombreModalidad }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const { state } = useLocation();

  const totalPages = Math.ceil(ranking?.length / pageSize);

  const paginatedRanking = ranking?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <React.Fragment>
      <Box style={{ marginTop: "5%" }}>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableCell className="Titulo" colSpan={5} align="center">
                    <Typography
                      variant="h5"
                      sx={{
                        my: 2,
                        fontWeight: "bold",
                        border: "2px dotted grey",
                      }}
                    >
                      Ranking de {nombreModalidad}{" "}
                    </Typography>
                  </TableCell>
                </TableHead>
                <TableBody>
                  <>
                    <TableRow>
                      <TableCell align="center" style={{ width: "auto" }}>
                        <Typography variant="subtitle1">
                          <span className="Titulo" style={{ display: "block" }}>
                            Posición:{" "}
                          </span>
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{ width: "auto" }}>
                        <Typography variant="subtitle1">
                          <span className="Titulo" style={{ display: "block" }}>
                            Licencia:{" "}
                          </span>
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{ width: "auto" }}>
                        <Typography variant="body1" className="Titulo">
                          Nombre:
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{ width: "auto" }}>
                        <Typography variant="body1" className="Titulo">
                          Puntuación:
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{ width: "auto" }}>
                        <Typography variant="body1" className="Titulo">
                          <i class="fa fa-info" aria-hidden="true"></i>Info:
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {paginatedRanking?.map((posicion, indice) => (
                      <TableRow key={indice}>
                        <TableCell align="center" style={{ width: "auto" }}>
                          <Typography variant="subtitle1">
                            # {indice + (currentPage - 1) * 10 + 1}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ width: "auto" }}>
                          <Typography variant="subtitle1">
                            {posicion.id}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ width: "auto" }}>
                          <Typography
                            style={{ fontWeight: "bold", textAlign: "left" }}
                          >
                            {posicion.nombre}{" "}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ width: "auto" }}>
                          <Typography
                            target="_blank"
                            sx={{
                              mx: 1,
                              textAlign: "center",
                              color: "black",
                              textDecoration: "none",
                              fontWeight: "bold",
                            }}
                          >
                            {posicion.puntuacion}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ width: "auto" }}>
                          <Typography
                            component={Link}
                            to={"/MangasDetalleAdmin"}
                            state={{ ...state, posicion }}
                            variant="body1"
                            className="Titulo"
                          >
                            <InfoIcon color="primary" />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Divider sx={{ width: "100%", m: 2 }} />
          <Box mt={2} display="flex" justifyContent="center">
            <Button
              variant="outlined"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              {"<<"}
            </Button>
            <Typography variant="body1" style={{ margin: "5px 10px 0px 10px" }}>
              Pag.{currentPage} / {totalPages}
            </Typography>
            <Button
              variant="outlined"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              {">>"}
            </Button>
          </Box>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default TablaRankingAdmin;
