import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CrearPiloto from "./componentes/CrearPiloto";
import ListaPilotos from "./componentes/ListaPilotos";
import { MenuContext } from "../../../../context/MenuContext";
import {
  useGetCompetidorByNameQuery,
  useGetListaCompetidoresQuery,
} from "../../../../redux/services/competidor";

const Pilotos = () => {
  const [competidoresOriginales, setCompetidoresOriginales] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const { menuState } = useContext(MenuContext);
  const [nombreBuscado, setNombreBuscado] = useState("");
  const [displayedPilotos, setDisplayedPilotos] = useState([]);
  const { data: dataCompetidores, isLoading: isLoadingCompetidores } =
    useGetListaCompetidoresQuery(null, { skip: nombreBuscado !== "" });
  const { data: competidoresByName, isLoading: isLoadingCompetidoresByName } =
    useGetCompetidorByNameQuery(nombreBuscado, { skip: nombreBuscado === "" });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filtrado = dataCompetidores?.listaCompetidor?.filter(
          (competidor) => competidor.activo !== false
        );
        setCompetidoresOriginales(filtrado);
        setDisplayedPilotos(filtrado);
      } catch (error) {
        console.error("Error al obtener los posts:", error);
      }
    };
    fetchData();
  }, [dataCompetidores]);

  useEffect(() => {
    if (nombreBuscado !== "" && competidoresByName) {
      setDisplayedPilotos(competidoresByName.listaCompetidor);
    } else {
      setDisplayedPilotos(competidoresOriginales);
    }
  }, [nombreBuscado, competidoresByName, competidoresOriginales]);

  const handleInputChange = (e) => {
    setNombreBuscado(e.target.value);
  };

  const totalPages = Math.ceil(displayedPilotos?.length / pageSize);
  const paginatedPilotos = displayedPilotos?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const isMobile = window.innerWidth <= 768;
  return (
    <React.Fragment>
      <Box>
        <Box
          className={`${
            menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
          }`}
        >
          <Grid
            item
            xs={3}
            className="GridItemCN"
            style={{ marginTop: isMobile ? "16%" : "6%", marginBottom: "-2%" }}
          >
            <TextField
              sx={{ mx: 1 }}
              label="Buscar Nombre"
              name="nombre"
              value={nombreBuscado}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid container style={{ flexDirection: "row" }}>
            <Grid
              item
              xs={12}
              style={{ flexDirection: "column" }}
              className="gridColumnaPXC"
            >
              {isLoadingCompetidores || isLoadingCompetidoresByName ? (
                <CircularProgress />
              ) : paginatedPilotos && paginatedPilotos.length > 0 ? (
                <Box className="boxCalendarioPXC">
                  <Typography className="Titulo" sx={{ marginBottom: "15px" }}>
                    Pilotos:
                  </Typography>
                  {paginatedPilotos.map((competidor) => (
                    <ListaPilotos
                      key={competidor.id}
                      competidor={competidor}
                      Origen={"auth"}
                    />
                  ))}
                </Box>
              ) : (
                <Typography className="Titulo">
                  No hay pilotos para mostrar.
                </Typography>
              )}
              {!isNaN(totalPages) && paginatedPilotos?.length > 0 && (
                <Box mt={2} display="flex" justifyContent="center">
                  <Button
                    variant="outlined"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    {"<<"}
                  </Button>
                  <Typography
                    variant="body1"
                    style={{ margin: "5px 10px 0px 10px" }}
                  >
                    Pag.{currentPage} /{totalPages}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    {">>"}
                  </Button>
                </Box>
              )}
              <Divider sx={{ width: "100%", m: 2 }} />
              <Box style={{ width: "100%" }} className="">
                <CrearPiloto sinmenu={true} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Pilotos;
