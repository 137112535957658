import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import useConfirmationModal from "../../../../../hooks/useConfirmationModal ";

const ListaReglamentosPublico = ({ reglamentos, nombreModalidad }) => {
  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "https://localhost:5000";
  const { openConfirm, ModalConfirmacion } = useConfirmationModal();
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(reglamentos?.length / pageSize);
  const paginatedReglamentos = reglamentos?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <React.Fragment>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableCell className="Titulo" colSpan={5} align="center">
                    <Typography variant="h5" sx={{ my: 2, fontWeight: "bold" }}>
                      {" "}
                    </Typography>
                  </TableCell>
                </TableHead>
                <TableBody>
                  <>
                    <TableRow>
                      <TableCell
                        className="Titulo"
                        align="left"
                        style={{ width: "10%" }}
                      >
                        #
                      </TableCell>
                      <TableCell
                        className="Titulo"
                        align="left"
                        style={{ width: "60%" }}
                      >
                        Reglamento
                      </TableCell>
                      <TableCell
                        className="Titulo"
                        align="center"
                        style={{ width: "25%" }}
                      >
                        Descargar Documento
                      </TableCell>
                      <TableCell
                        className="Titulo"
                        align="left"
                        style={{ width: "5%" }}
                      ></TableCell>
                    </TableRow>
                    {paginatedReglamentos?.map((reglamento, indice) => (
                      <TableRow key={reglamento.id}>
                        <TableCell align="left" style={{ width: "10%" }}>
                          <Typography
                            style={{ fontWeight: "bold", textAlign: "left" }}
                          >
                            {indice + 1 + (currentPage - 1) * pageSize}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" style={{ width: "40%" }}>
                          <Typography
                            style={{ fontWeight: "bold", textAlign: "left" }}
                          >
                            {reglamento?.nombre}{" "}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" style={{ width: "20%" }}>
                          {reglamento ? (
                            <IconButton
                              component="a"
                              href={`${API_BASE_URL}/images/${reglamento?.ruta}`}
                              download={`${reglamento?.nombrePdf}`}
                              target="_blank"
                            >
                              <GetAppIcon color="primary" />
                            </IconButton>
                          ) : (
                            <Typography style={{ marginLeft: "10px" }}>
                              <FileDownloadOffIcon color="primary" />
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Divider sx={{ width: "100%", m: 2 }} />
          <Box mt={2} display="flex" justifyContent="center">
            <Button
              variant="outlined"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              {"<<"}
            </Button>
            <Typography variant="body1" style={{ margin: "5px 10px 0px 10px" }}>
              Pag.{currentPage} / {totalPages}
            </Typography>
            <Button
              variant="outlined"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              {">>"}
            </Button>
          </Box>
        </Grid>
      </Box>
      <ModalConfirmacion />
    </React.Fragment>
  );
};

export default ListaReglamentosPublico;
